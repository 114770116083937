import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import ConfirmationModal from 'components/ConfirmationModal';
import CreateAgentForm from 'components/CreateAgentForm/CreateAgentForm.component';
import TableNavigation from 'components/TableNavigation';
import Tippy from 'components/Tippy';
import enums from 'enums';
import useFilterParams from 'hooks/useFilterParams';
import React, { useEffect, useMemo, useRef } from 'react';
import { Button, Dropdown, Input, Table } from 'semantic-ui-react';
import { Agent } from 'types/Users';
import './Agents.styles.scss';
import {
  getAgentStatus,
  handleAgentActivation,
  statusFilterOptions,
} from './utils/index';
import bus, { EventName } from 'modules/bus';
import { BUS_EVENTS } from 'modules/bus/busEvents';
import useElementScrollTo from 'hooks/useElementScrollTo';
import { apiAgents } from 'api';

type AgentStatus = typeof enums.AGENT_STATUS[keyof typeof enums.AGENT_STATUS];

const TABLE_HEADER = 'Agents List';
const Agents: React.FC = () => {
  const rolesParams = useMemo(() => ({ $nin: ['Admin'] }), []);

  const {
    skip,
    searchValue,
    loading,
    pagination,
    items: agents,
    setItems: setAgents,
    setStatus,
    setSearchValue,
  } = useFilterParams({
    fallbackRoute: '/agents',
    requestFunc: apiAgents.loadAgents,
    statusMapper: (status: AgentStatus) =>
      !status ? {} : { isActive: status === 'Active' },
    rolesParams,
  });

  useEffect(() => {
    const agentStatusChanged = (args: any) => {
      const { payload } = args;

      setAgents((oldVal) =>
        oldVal.map((agent) =>
          agent.id === payload.agentId
            ? { ...agent, isActive: payload.isActive }
            : agent,
        ),
      );
    };

    bus.addEventListener(
      BUS_EVENTS.AGENT_STATUS_CHANGED as EventName,
      agentStatusChanged,
    );

    return () => {
      bus.removeEventListener(
        BUS_EVENTS.AGENT_STATUS_CHANGED as EventName,
        agentStatusChanged,
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAgentCreation = (agent: Agent) => {
    setAgents((oldvalue) => [agent, ...oldvalue]);
  };
  const tableClassNames = classNames('agents__table', {
    'agents__table--loading': loading,
  });

  const headerRef = useRef();
  useElementScrollTo(headerRef, skip);

  return (
    <div ref={headerRef} className="agents">
      <div className="agents__top">
        <Input
          label="Search agents"
          icon="search"
          value={searchValue}
          onChange={({ target: { value } }) => setSearchValue(value)}
        />
        <div className="agents__top__right-menu">
          <Dropdown
            placeholder="Select status filter"
            selection
            clearable
            selectOnBlur={false}
            options={statusFilterOptions}
            float="right"
            onChange={(_, data) => setStatus(data.value as string[])}
          />
          <CreateAgentForm onAgentCreate={handleAgentCreation} />
        </div>
      </div>
      <h2>{TABLE_HEADER}</h2>
      <Table celled className={tableClassNames}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Username</Table.HeaderCell>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Email</Table.HeaderCell>
            <Table.HeaderCell>Departments</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.HeaderCell>Average rating</Table.HeaderCell>
            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {agents.map(
            ({
              username,
              firstName,
              lastName,
              email,
              departments,
              isActive,
              _id,
              agent_review,
            }) => {
              return (
                <Table.Row key={email + Math.random()}>
                  <Table.Cell>{username ?? 'user'}</Table.Cell>
                  <Table.Cell>
                    <NavLink to={`/agents/${_id}`}>
                      <span>{`${firstName} ${lastName}`}</span>
                    </NavLink>
                  </Table.Cell>
                  <Table.Cell>{email}</Table.Cell>
                  <Table.Cell>{departments.join(', ')}</Table.Cell>
                  <Table.Cell>
                    <label
                      className={`${
                        enums.AGENT_STATUS_COLORS[getAgentStatus(isActive)]
                      } status-label`}
                    >
                      {getAgentStatus(isActive)}
                    </label>
                  </Table.Cell>
                  <Table.Cell
                    positive={agent_review?.[0]?.avgMark >= 4}
                    negative={agent_review?.[0]?.avgMark <= 4}
                  >
                    {agent_review?.[0]?.avgMark.toFixed(2)}
                  </Table.Cell>
                  <Table.Cell>
                    <div className="button-group">
                      <Tippy title="Agent details">
                        <NavLink to={`/agents/${_id}`}>
                          <Button icon="edit" />
                        </NavLink>
                      </Tippy>
                      <Tippy
                        title={isActive ? 'Deactivate agent' : 'Activate agent'}
                      >
                        <ConfirmationModal
                          header={
                            isActive
                              ? enums.ACTION_MESSAGES.CONFIRM_MODAL.ACTIVATION
                                  .ACTIVE.HEADER
                              : enums.ACTION_MESSAGES.CONFIRM_MODAL.ACTIVATION
                                  .INACTIVE.HEADER
                          }
                          message={
                            isActive
                              ? enums.ACTION_MESSAGES.CONFIRM_MODAL.ACTIVATION
                                  .ACTIVE.MESSAGE
                              : enums.ACTION_MESSAGES.CONFIRM_MODAL.ACTIVATION
                                  .INACTIVE.MESSAGE
                          }
                          action={() => handleAgentActivation(_id, !isActive)}
                          messageHeader={'Agent: ' + username}
                          trigger={
                            <Button icon={isActive ? 'user close' : 'user'} />
                          }
                        />
                      </Tippy>
                    </div>
                  </Table.Cell>
                </Table.Row>
              );
            },
          )}
        </Table.Body>
      </Table>
      <TableNavigation pagination={pagination} />
    </div>
  );
};

export default Agents;
