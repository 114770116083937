import React, { useState } from 'react';
import { Button } from 'semantic-ui-react';

const Wizard = (props: any) => {
  const {
    children,
    onSubmit,
    loading,
    submitText = 'Submit',
    hasErrors,
    isSoleForm = false,
  } = props;

  const [currentStep, setCurrentStep] = useState<number>(0);

  const isLastStep = () => {
    return currentStep === React.Children.count(children) - 1;
  };

  const getActivePage = () => {
    const activeStep = React.Children.toArray(children)[currentStep];
    return activeStep;
  };

  const handleNext = () => {
    setCurrentStep((oldVal) => Math.min(oldVal + 1, children.length - 1));
  };

  const handlePrevious = () => {
    setCurrentStep((oldVal) => Math.max(oldVal - 1, 0));
  };

  const handleSubmit = (values: any) => {
    isLastStep() ? onSubmit(values) : handleNext();
  };

  return (
    <div>
      {getActivePage()}
      <div className="modal-actions-like-container">
        {currentStep > 0 && (
          <Button basic type="button" onClick={handlePrevious}>
            Previous
          </Button>
        )}
        {isLastStep() || isSoleForm ? (
          <Button
            key="wizard-next-submite"
            primary
            type="submit"
            disabled={loading || hasErrors}
          >
            {submitText}
          </Button>
        ) : (
          <Button
            key="wizard-next-button"
            primary
            type="button"
            onClick={handleSubmit}
            disabled={loading || hasErrors}
          >
            Continue
          </Button>
        )}
      </div>
    </div>
  );
};

export default Wizard;
