import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { Modal, Header, Button } from 'semantic-ui-react';
import classNames from 'classnames';
import { Form, Field } from 'react-final-form';
import { FormSelect } from 'components/FinalFormComponents';
import { Agent, Course, CourseStatus, Driver } from 'types/Users';
import {
  mockCoursesIfEmptyList,
  prepareAgentsForSelect,
} from 'components/OrientationTable/utilis';
import { getCoursesWithGivenStatus } from 'components/FinishCoursesModal/utilits';

type StartCoursesAdminModalProps = {
  selectedDriver: Driver;
  setSelectedDriver?: React.Dispatch<React.SetStateAction<Driver>>;
  coursesStatus: CourseStatus;
  header: string;
  agents: Agent[];
  action: (driver: Driver, selectedCourses: Course[]) => void;
  course?: Course;
  setCourse?: React.Dispatch<React.SetStateAction<Course>>;
};

type StartCoursesFormValues = {
  Eld?: string;
  Fleet?: string;
  Accounting?: string;
  Safety?: string;
  Recruiting?: string;
  'Driver Education'?: string;
};

const StartCoursesAdminModal: React.FC<StartCoursesAdminModalProps> = ({
  selectedDriver,
  setSelectedDriver,
  coursesStatus,
  header,
  action,
  agents,
  course,
  setCourse,
}) => {
  const [loading, setLoading] = useState<boolean>(false);

  const getCourses = useCallback(() => {
    if (selectedDriver !== null) {
      return getCoursesWithGivenStatus(
        mockCoursesIfEmptyList(selectedDriver.courses),
        coursesStatus,
      );
    }
    return [];
  }, [coursesStatus, selectedDriver]);

  const courses = useMemo(() => (course ? [course] : getCourses()), [
    course,
    getCourses,
  ]);

  const prepareCourses = useCallback(
    (values: StartCoursesFormValues) => {
      return courses.map((course) => {
        if (Object.prototype.hasOwnProperty.call(values, course.courseType)) {
          course.instructorId = values[course.courseType];
          return course;
        }
        return null;
      });
    },
    [courses],
  );

  const onFormSubmit = useCallback(
    async (values: StartCoursesFormValues) => {
      try {
        setLoading(true);
        await action(selectedDriver, prepareCourses(values));
      } catch (err) {
        console.error(err);
      } finally {
        setSelectedDriver(null);
        setCourse(null);
        setLoading(false);
      }
    },
    [action, prepareCourses, selectedDriver, setCourse, setSelectedDriver],
  );

  const formClassNames = useMemo(() => classNames('ui', 'form', { loading }), [
    loading,
  ]);

  useEffect(() => {
    return () => setCourse(null);
  }, [setCourse]);

  return (
    <Modal
      closeIcon
      onClose={() => setSelectedDriver(null)}
      open={Boolean(selectedDriver)}
    >
      <Header>{header}</Header>
      <Modal.Content>
        <Form
          onSubmit={onFormSubmit}
          render={({ handleSubmit, submitting, dirty }) => (
            <form onSubmit={handleSubmit} className={formClassNames}>
              {courses.map((course) => {
                const options = prepareAgentsForSelect(
                  agents,
                  course.courseType,
                );

                return options.length ? (
                  <Field
                    key={course.courseType}
                    placeholder={course.courseType}
                    options={options}
                    component={FormSelect}
                    label={course.courseType}
                    name={course.courseType}
                    clearable
                  />
                ) : (
                  <div>
                    {`No agents available for ${course.courseType} department`}
                  </div>
                );
              })}
              <div className="form-actions">
                <Button
                  type="submit"
                  disabled={submitting || !dirty}
                  content="Submit"
                  primary
                />
              </div>
            </form>
          )}
        />
      </Modal.Content>
    </Modal>
  );
};
export default StartCoursesAdminModal;
