import { Agent, Course, Department, Driver } from 'types/Users';

export const mockCoursesIfEmptyList = (courses: Course[]) => {
  return courses?.length > 0
    ? courses
    : ([
        { status: 'Free', instructorId: null, courseType: 'Eld' },
        { status: 'Free', instructorId: null, courseType: 'Fleet' },
        { status: 'Free', instructorId: null, courseType: 'Accounting' },
        { status: 'Free', instructorId: null, courseType: 'Safety' },
      ] as Course[]);
};

export const updateDriver = (driver: Driver) => (drivers: Driver[]) => {
  const returnDrivers = drivers.map((oldDriver) =>
    oldDriver.id === driver.id ? driver : oldDriver,
  );
  return returnDrivers;
};

export const prepareCoursesForUpdate = (
  driverCourses: Course[],
  selectedCourses: Course[],
): Course[] => {
  return mockCoursesIfEmptyList(driverCourses).map((course) => {
    const selectedCourse = selectedCourses?.find(
      (selectedCourse) => selectedCourse?.courseType === course.courseType,
    );
    if (selectedCourse) {
      if (course.status === 'Free')
        return {
          ...course,
          status: 'On-going',
          instructorId: selectedCourse.instructorId,
        };
      else if (course.status === 'On-going')
        return {
          ...course,
          status: 'Finished',
          instructorId: selectedCourse.instructorId,
        };
    }
    return course;
  });
};

export const filterDriverWithId = (drivers: Driver[], driverId: string) =>
  drivers.find((driver) => driver._id === driverId);

export const isUserAnInstructorOfAnyGivenCourse = (
  currentUserId: string,
  courses: Course[],
) => courses.some((course) => course.instructorId === currentUserId);

const filterAgentsBasedOnDepartment = (
  agents: Agent[],
  department: Department,
) => {
  return agents.filter((agent) => agent.departments?.includes(department));
};

export const prepareAgentsForSelect = (
  agents: Agent[],
  department: Department,
) => {
  return filterAgentsBasedOnDepartment(agents, department).map(
    (agent, index) => {
      return {
        key: agent._id + index,
        value: agent._id,
        text: `${agent.firstName} ${agent.lastName}`,
      };
    },
  );
};
