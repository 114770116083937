import React from 'react';
import './Label.styles.scss';
import classNames from 'classnames';

type LabelProps = {
  label: string;
  required?: boolean;
  error?: string;
  className?: string;
};

const Label: React.FC<LabelProps> = ({ required, label, className, error }) => {
  const labelContainerClassNames = classNames('label-container', className);
  const labelClassNames = classNames('label-container__label', {
    'label-container__label-error': error,
  });
  return (
    <div className={labelContainerClassNames}>
      <label className={labelClassNames}>{label}</label>
      {required && <span className="label-container__required-star">*</span>}
    </div>
  );
};
export default Label;
