type optionType = {
  value: string;
  key: string;
  text: string;
};
//limit values:
const limitValues = [5, 10, 25, 50];
//limit default value:
export const DEFAULT_LIMIT_VALUE = limitValues[1];

const createOptions = () => {
  const optionsArray: optionType[] = [];

  limitValues.forEach((el) => {
    optionsArray.push({
      value: el.toString(),
      key: el.toString(),
      text: el.toString(),
    });
  });

  return optionsArray;
};

export const limitOptions = createOptions();
