import React, { useState } from 'react';
import { Field, useForm } from 'react-final-form';
import { FormInput } from 'components/FinalFormComponents';
import './DriverRoadTest.styles.scss';
import { questions, undefinedValidation } from './utils';

const DriversRoadTest: React.FC<any> = (props) => {
  const [isOtherChecked, setIsOtherChecked] = useState<boolean>(false);

  const { batch, change } = useForm();

  const selectAll = (isChecked: boolean) => {
    batch(() => {
      questions.forEach((question) => change(question.name, isChecked));
    });
  };

  const handleOtherChecked = () => {
    if (isOtherChecked) {
      batch(() => {
        change('other');
      });
    }
    setIsOtherChecked((prev) => !prev);
  };

  return (
    <>
      <p className="info">
        This driver has less then two-year experience so it is expected the he
        needs to go through driver road test examination. Please fill in this
        two-step form in accordance with his results before sending him the PDF
        for signing.
      </p>
      <div className="checkbox select-all">
        <input
          type="checkbox"
          name="selectll"
          id="drt-select-all"
          onChange={(event) => selectAll(event.target.checked)}
        />
        <label htmlFor="drt-select-all">Select all</label>
      </div>
      {questions.map((question) => (
        <div key={question.name} className="checkbox">
          <Field
            name={question.name}
            component="input"
            initialValue={false}
            type="checkbox"
            id={question.name}
          />
          <label htmlFor={question.name}>{question.label}</label>
        </div>
      ))}
      <div className="checkbox-other">
        <div className="checkbox">
          <input
            type="checkbox"
            name="other"
            id="other"
            checked={isOtherChecked}
            onChange={handleOtherChecked}
          />
          <label htmlFor="other">Other</label>
        </div>
        {isOtherChecked && (
          <Field
            component={FormInput}
            required
            name="other"
            validate={undefinedValidation}
            className="checkbox-other__input"
          />
        )}
      </div>
      <Field
        component={FormInput}
        required
        label="Type of equipment used in giving the test"
        name="typeOfEquipment"
        validate={(value) => !value}
      />
      <div className="title-for-textarea">Remarks</div>
      <Field name="remarks" component="textarea" />
    </>
  );
};

export default DriversRoadTest;
