import React from 'react';
import './Test2.styles.scss';

type Test2Props = {};

const Test2: React.FC<Test2Props> = (props) => {
  return <div>hello from Test2!</div>;
};

export default Test2;
