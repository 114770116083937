const APPLICANT_STATUS = {
  New: 'New',
  Pending: 'Pending',
  'Changes requested': 'Changes requested',
  Submitted: 'Submitted',
  'Declined to sign': 'Declined to sign',
} as const;

const AGENT_STATUS = {
  Active: 'Active',
  Inactive: 'Inactive',
} as const;

const DRIVER_STATUS = {
  Verified: 'Verified',
  Hired: 'Hired',
  Terminated: 'Terminated',
  Replicated: 'Replicated',
  Rejected: 'Rejected',
  'Hired/Checked': 'Hired/Checked',
  'Sent to sign': 'Sent to sign',
  Signed: 'Signed',
};

const EMPLOYMENT_HISTORY_DOCUMENT_STATUS = {
  'Not requested': 'Not requested',
  'Sent to driver': 'Sent to driver',
  'Driver signed': 'Driver signed',
  'Both parties signed': 'Both parties signed',
  Expired: 'Expired',
};

const APPLICANT_STATUS_COLORS: Record<keyof typeof APPLICANT_STATUS, string> = {
  New: 'blue',
  Pending: 'yellow',
  'Changes requested': 'orange',
  Submitted: 'olive',
  'Declined to sign': 'red',
};

const DRIVER_STATUS_COLORS: typeof DRIVER_STATUS = {
  Verified: 'blue',
  Hired: 'green',
  Terminated: 'red',
  Replicated: 'yellow',
  Rejected: 'red',
  'Hired/Checked': 'green',
  'Sent to sign': 'red',
  Signed: 'blue',
};

const MAPPED_DRIVER_STATUS = {
  Hire: 'For Company Use',
  Terminate: 'Termination of Employment',
  'Hire-Rejected': 'For Company Use',
};

const DRIVER_HISTORY_STATUS_COLORS: Record<
  keyof typeof APPLICANT_STATUS,
  string
> &
  typeof DRIVER_STATUS = {
  ...APPLICANT_STATUS_COLORS,
  ...DRIVER_STATUS_COLORS,
};

const AGENT_STATUS_COLORS: Record<keyof typeof AGENT_STATUS, string> = {
  Active: 'green',
  Inactive: 'red',
};

const EMPLOYMENT_HISTORY_DOCUMENT_STATUS_COLORS = {
  'Not requested': 'blue',
  'Sent to driver': 'yellow',
  'Driver signed': 'orange',
  'Both parties signed': 'olive',
  Expired: 'red',
};

const ACTION_MESSAGES = {
  SUBMITED: 'Submitted',

  ERROR_MESSAGE: {
    DRIVER_UPDATE: "Error occured. Driver wasn't updated.",
    INSTRUCTOR_UPDATE: "Error occured. Instructor wasn't updated.",
    AGENT_UPDATE: "Error occured. Agent wasn't updated.",
    EDIT_HIRE: "Error occured. Request for editing company info wasn't sent.",
    SEND_TO_SIGN: 'Error occured. A PDF is not sent for signing',
    COPY: 'Magic link is not reachable at the moment',
    CREATE_AGENT: 'Error. Create agent request failed.',
    MAGIC_LINK_FORM_SUBMIT: 'Error occured while submiting',
    REVIEW_REQUEST: 'Error occured while sending review request',
    MOVE_TO_DRIVERS: "Error occured, applicant wasn't moved to drivers",
  },

  SUCCESS_MESSAGE: {
    DRIVER_UPDATE: 'Driver is successfully updated.',
    INTRUCTOR_UPDATE: 'Instructor is successfuly updated.',
    EDIT_HIRE: 'Successfully edited company info.',
    COPY: 'Magic link is copied',
    CREATE_AGENT: 'Successfully created agent.',
    AGENT_UPDATE: 'Successfully updated agent.',
    MAGIC_LINK_FORM_SUBMIT: 'Form successfully submited',
    REVIEW_REQUEST: 'Review request is successfully sent',
    SENT_TO_SIGN:
      "Application and 'Lease agreement between company and driver' are successfully sent for signing.",
  },

  CONFIRM_MODAL: {
    MOVE_TO_DRIVERS: {
      HEADER: 'Move to drivers',
      MESSAGE:
        'Are you sure you want to move this applicant to drivers section?',
    },
    SEND_TO_SIGN: {
      HEADER: 'Send to sign confirmation',
      MESSAGE:
        'Are you sure you want to send an Application PDF for signing to this applicant?',
    },
    ACTIVATION: {
      ACTIVE: {
        HEADER: 'Deactivate user',
        MESSAGE: 'Are you sure you want to make this agent status inactive?',
      },
      INACTIVE: {
        HEADER: 'Activate user',
        MESSAGE: 'Are you sure you want to make this agent status active?',
      },
    },
    DELETION: {
      HEADER_APPLICANT: 'Deleting applicant',
      HEADER_DRIVER: 'Deleting driver',
    },
  },
};

const SOCKET_EVENTS = {
  APPLICANT_STATUS_CHANGED: 'driver-status-changed',
  DRIVER_STATUS_CHANGED: 'driver-status-changed',
  AGENT_STATUS_CHANGED: 'agent-status-changed',
  PDF_SIGNED: 'pdf-signed',
  PDF_CREATION_ERROR: 'pdf-error',
  DRIVER_SUBMITED: 'driver-submited',
  EH_STATUS_CHANGED: 'employment-history-status-changed',
  NOTIFICATION_CREATED: 'notification-created',
  NOTIFICATION_READ: 'notification-read',
  DRIVER_DRIVING_CHANGED: 'driver-driving',
  DRIVER_ROLE_CHANGED: 'driver-role-changed',
  INSTRUCTORS_REVIEWED: 'instructors-reviewed',
  COURSE_STATUS_CHANGED: 'course-status-changed',
};

export default {
  DRIVER_STATUS_COLORS,
  APPLICANT_STATUS_COLORS,
  ACTION_MESSAGES,
  APPLICANT_STATUS,
  SOCKET_EVENTS,
  AGENT_STATUS,
  AGENT_STATUS_COLORS,
  DRIVER_STATUS,
  EMPLOYMENT_HISTORY_DOCUMENT_STATUS,
  EMPLOYMENT_HISTORY_DOCUMENT_STATUS_COLORS,
  DRIVER_HISTORY_STATUS_COLORS,
  MAPPED_DRIVER_STATUS,
};
