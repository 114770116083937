import React, { useState } from 'react';
import { Button, Dimmer, Header, Loader, Modal } from 'semantic-ui-react';
import { Form as FinalForm, Field } from 'react-final-form';
import MyDropZoneField from 'components/MyDropZoneField';
import validateService from 'services/validateService';

export interface UploadDocumentModalProps {
  header?: string;
  fileType?: string;
  onDocumentUpload?: (...args: any) => void;
  modalTrigger: React.ReactNode;
  instructions?: string;
  submitButtonText?: string;
  initialValues?: any;
  maxFiles?: number;
  accept?: string | string[];
  className?: string;
  multiple?: boolean;
}

const UploadDocumentModal: React.FC<UploadDocumentModalProps> = (
  props: UploadDocumentModalProps,
) => {
  const {
    header = 'Upload Modal',
    modalTrigger,
    instructions = 'Drag & Drop files',
    submitButtonText = 'Submit',
    onDocumentUpload,
    initialValues,
    maxFiles = 1,
    accept,
    className,
    multiple,
  } = props;
  const [open, setOpen] = useState<boolean>(false);

  const overrideHandleSubmit = async (val: any) => {
    await onDocumentUpload(val);
    setOpen(false);
  };

  return (
    <FinalForm
      onSubmit={overrideHandleSubmit}
      initialValues={{
        files: [],
        ...initialValues,
      }}
      render={({ submitting, handleSubmit, dirty, valid }) => (
        <form onSubmit={handleSubmit}>
          <Modal
            className={className}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            trigger={modalTrigger}
            closeIcon
          >
            <Header>{header}</Header>
            <Modal.Content>
              {submitting && (
                <Dimmer inverted active>
                  <Loader>Sending request...</Loader>
                </Dimmer>
              )}

              <Field
                name="files"
                validate={validateService.required}
                component={MyDropZoneField}
                instructions={instructions}
                maxFiles={maxFiles}
                accept={accept}
                multiple={multiple}
              />
            </Modal.Content>
            <Modal.Actions>
              <Button color="black" onClick={() => setOpen(false)}>
                Cancel
              </Button>
              <Button
                disabled={!valid}
                content={submitButtonText}
                labelPosition="right"
                icon="upload"
                type="button"
                onClick={(e) => handleSubmit()}
                primary
              />
            </Modal.Actions>
          </Modal>
        </form>
      )}
    />
  );
};

export default UploadDocumentModal;
