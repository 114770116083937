export const DOCU_SIGN_CONFIRMATION_HEADER = {
  message: 'Document signing ',
  editMessage: '',
};

export const DOCU_SIGN_CONFIRMATION_MESSAGE_HEADER = {
  message: 'Sign Safety Approval Document for ',
  editMessage: '',
};

export const DOCU_SIGN_CONFIMATION_MESSAGE = {
  message:
    'As an agent it is required from you to sign the Safety Approval document before sending driver on the road. Please proceed.',
  editMessage: '',
};

export const DOCU_SIGN_CONFIMATION_ACTION_MESSAGE = {
  succes: {
    message: 'Succesfully sent request for signing document.',
    editMessage: '',
  },
  error: {
    message: "Error occured. Request for document sign wasn't sent.",
    editMessage: '',
  },
};
