import React, { useState, useRef, useCallback } from 'react';
import { Button, Modal, ModalContent, Radio } from 'semantic-ui-react';
import { Form, Field, FormSpy } from 'react-final-form';
import { FormInput, Label } from '../FinalFormComponents';
import ValidateService, {
  ZIP_CODE_LENGTH,
} from '../../services/validateService';
import ConfirmationModal from 'components/ConfirmationModal';
import { toast } from 'react-toastify';
import { apiCompanies } from 'api';
import zipcodes from 'zipcodes';
import validateService from '../../services/validateService';
import MyDropZoneField from 'components/MyDropZoneField';
import { Company } from 'types/Users';

import './CreateCompanyModal.styles.scss';

type CreateCompanyModalProps = {
  onCreateCompany: any;
  isEditModal?: boolean;
  openModal: boolean;
  selectedCompany: Company;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedCompany: React.Dispatch<React.SetStateAction<Company>>;
};

const CreateCompanyModal: React.FC<CreateCompanyModalProps> = ({
  onCreateCompany,
  isEditModal = false,
  openModal = false,
  selectedCompany,
  setSelectedCompany,
  setOpenModal,
}) => {
  const [toggleDropZone, setToggleDropZone] = useState<boolean>(false);

  const initialValuesRef = useRef({ ...selectedCompany, files: [] });

  const onFormSubmit = useCallback(
    async (values: any) => {
      const { files } = values;
      try {
        const { data } = isEditModal
          ? await apiCompanies.editCompany(values, selectedCompany.id)
          : await apiCompanies.createCompany(values);

        const {
          company: { id, name },
        } = data;

        let tmpPhotoUrl;

        if (files[0]) {
          const {
            data: {
              company: { photoUrl },
            },
          } = await apiCompanies.uploadCompanyPhoto(id, files[0]);
          tmpPhotoUrl = photoUrl;
        }

        if (data && files[0])
          onCreateCompany(
            {
              company: {
                ...data.company,
                photoUrl: tmpPhotoUrl,
              },
            },
            isEditModal,
          );
        else onCreateCompany(data, isEditModal);

        toast.success(
          `Company "${name}" is successfully ${
            isEditModal ? 'updated' : 'created'
          }.`,
        );

        setSelectedCompany(null);
        setOpenModal(false);
      } catch (err: any) {
        toast.error(err?.response?.data.message);
      }
    },
    [
      isEditModal,
      onCreateCompany,
      selectedCompany?.id,
      setOpenModal,
      setSelectedCompany,
    ],
  );

  const handleCompanyPhotoDeletion = useCallback(async () => {
    try {
      const {
        data: { company: companyWithoutImage },
      } = await apiCompanies.deleteCompanyPhoto(selectedCompany.id);

      onCreateCompany({ company: companyWithoutImage }, isEditModal);

      setSelectedCompany((old) => ({ ...old, photoUrl: null }));

      toast.success('Company photo is successfully deleted');
    } catch (err) {
      console.error(err);
    }
  }, [isEditModal, onCreateCompany, selectedCompany?.id, setSelectedCompany]);

  const handleModalClose = useCallback(() => {
    setToggleDropZone(false);
    setSelectedCompany(null);
    setOpenModal(false);
  }, [setOpenModal, setSelectedCompany]);

  return (
    <Modal
      onClose={handleModalClose}
      open={openModal}
      className="modal"
      closeIcon
    >
      <Modal.Header>
        {isEditModal ? 'Company Editing' : 'Company Creating'}
      </Modal.Header>
      <ModalContent scrolling>
        <Form
          onSubmit={onFormSubmit}
          initialValues={initialValuesRef.current}
          render={({
            handleSubmit,
            submitting,
            hasValidationErrors,
            values,
            pristine,
          }) => (
            <form onSubmit={handleSubmit} className="ui form">
              <Field
                name="name"
                label="Company Name (unique)"
                required
                validate={ValidateService.required}
                component={FormInput}
              />
              <Field
                name="email"
                label="Email (unique)"
                component={FormInput}
                required
                validate={validateService.composeValidators(
                  validateService.required,
                  validateService.mailValidation,
                )}
              />
              <Field
                name="firstName"
                label="Owner First Name"
                component={FormInput}
                required
                validate={validateService.required}
              />
              <Field
                name="lastName"
                label="Owner Last Name"
                component={FormInput}
                required
                validate={validateService.required}
              />
              <Field
                name="phone"
                label="Phone"
                type="number"
                component={FormInput}
              />
              <Field
                name="fax"
                label="Fax"
                type="number"
                component={FormInput}
              />
              <Field
                name="companyAddress.zip"
                label="ZIP"
                component={FormInput}
                type="number"
                validate={validateService.zipValidation}
              />
              <Field
                name="companyAddress.city"
                label="City"
                component={FormInput}
                readOnly
              />
              <Field
                name="companyAddress.state"
                label="State"
                component={FormInput}
                readOnly
              />

              <Field
                name="companyAddress.address"
                label="Address"
                component={FormInput}
                required
                validate={ValidateService.required}
              />
              <div className="company-image-container">
                <div className="company-image-container__photo-upload">
                  <Label label="Upload company photo" />
                  <Radio
                    toggle
                    onChange={() => {
                      setToggleDropZone((odlVal) => !odlVal);
                    }}
                  />
                </div>
                {toggleDropZone && (
                  <Field
                    name="files"
                    label="Image"
                    component={MyDropZoneField}
                    accept={['.jpg', '.png', '.jpeg']}
                    instructions={`Drop your image or click here to browse. \n ('.jpg','.png','.jpeg')`}
                    maxFiles={1}
                  />
                )}
                {selectedCompany?.photoUrl && (
                  <div className="company-image-container__photo">
                    <h3>Company photo </h3>
                    <img src={selectedCompany.photoUrl} alt="Company logo" />
                    <ConfirmationModal
                      header="Company photo deletion"
                      message="Are you sure you want to delete company photo permanently?"
                      action={() => handleCompanyPhotoDeletion()}
                      color="red"
                      buttonContent="Delete"
                      buttonIcon="trash"
                      trigger={
                        <Button
                          type="button"
                          content="Delete photo"
                          color="red"
                        />
                      }
                      isDeleteModal
                    />
                  </div>
                )}
              </div>
              <FormSpy
                subscription={{ values: true }}
                render={(renderProps) => {
                  const { form, values } = renderProps;

                  if (values.companyAddress) {
                    if (
                      values.companyAddress.zip &&
                      values.companyAddress['zip'].length === ZIP_CODE_LENGTH
                    ) {
                      const zipMap = zipcodes.lookup(
                        values.companyAddress['zip'],
                      );
                      if (zipMap) {
                        form.change('companyAddress.state', zipMap.state);
                        form.change('companyAddress.city', zipMap.city);
                      }
                    } else {
                      form.change('companyAddress.state', '');
                      form.change('companyAddress.city', '');
                    }
                  }

                  return <></>;
                }}
              />

              <div className="modal-actions-like-container">
                <Button
                  color="black"
                  content="Cancel"
                  type="button"
                  onClick={() => handleModalClose()}
                />
                <ConfirmationModal
                  className="centered-modal"
                  message={
                    isEditModal
                      ? 'Are you sure you want to make changes for this company?'
                      : 'Are you sure you want to create new company?'
                  }
                  action={() => onFormSubmit(values)}
                  trigger={
                    <Button
                      type="button"
                      disabled={pristine || submitting || hasValidationErrors}
                      content={isEditModal ? 'Submit' : 'Create company'}
                      primary
                    />
                  }
                />
              </div>
            </form>
          )}
        />
      </ModalContent>
    </Modal>
  );
};

export default CreateCompanyModal;
