import React, { useEffect, useState } from 'react';
import './NavigationMenu.styles.scss';
import { Icon, Menu } from 'semantic-ui-react';
import { SemanticICONS } from 'semantic-ui-react/dist/commonjs/generic';
import { HeaderOptions, NavigationOptions } from '../../pages/Home/utils';
import NavigationSubMenu from './components/NavigationSubMenu';
import classNames from 'classnames';
import { Arrow } from './svgs';
import { useFetchWithLimit } from 'hooks/useFetchWithLimit';
import { apiCompanies } from 'api';

export type OptionType = {
  header: NavigationOptions | HeaderOptions;
  options?: string[];
  icon?: SemanticICONS;
};

type NavigationMenuProps = {
  options: Record<NavigationOptions, OptionType>;
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setIsDarkTheme: React.Dispatch<React.SetStateAction<boolean>>;
  isDarkTheme: boolean;
};

const NavigationMenu: React.FC<NavigationMenuProps> = ({
  options,
  visible,
  setVisible,
  setIsDarkTheme,
  isDarkTheme,
}) => {
  const [selected, setSelected] = useState<NavigationOptions>();
  const [selectedVisible, setSelectedVisible] = useState<boolean>(false);

  const { items: companies } = useFetchWithLimit({
    limit: 20,
    shouldExecuteCall: true,
    request: apiCompanies.getCompanies,
  });

  useEffect(() => {
    if (!visible) {
      setSelectedVisible(false);
    }
  }, [visible]);

  const navigationSubMenuClassNames = classNames(
    'navigation-menu__container__sub-menu',
    {
      'navigation-menu__container__sub-menu--visible': selectedVisible,
    },
  );
  const navigationMenuClassNames = classNames('navigation-menu', {
    'navigation-menu--visible': visible,
  });
  const navigationMenuContainerClassNames = classNames(
    'navigation-menu__container',
  );
  const closingContainerClassNames = classNames(
    'navigation-menu__closing-container',
    { 'navigation-menu__closing-container--rotated': visible },
  );

  return (
    <div className={navigationMenuClassNames}>
      <div
        className={closingContainerClassNames}
        onClick={() => setVisible((oldValue) => !oldValue)}
      >
        <Arrow />
      </div>
      <div className={navigationMenuContainerClassNames}>
        <div className="navigation-menu__container__items">
          {Object.entries(options).map(([key, value]) => (
            <Menu.Item
              key={key + value}
              as="button"
              onClick={() => {
                if (value.header === selected) {
                  setSelectedVisible((oldVal) => !oldVal);
                } else {
                  setSelectedVisible(true);
                }
                setSelected(value.header as NavigationOptions);
              }}
              className={classNames('navigation-menu__container__items__item', {
                'navigation-menu__container__items__item--selected':
                  selected === value.header && selectedVisible,
              })}
            >
              <div className="navigation-menu__container__items__item__icon">
                {value.icon ? (
                  <Icon name={value.icon} />
                ) : (
                  value.header[0].toUpperCase()
                )}
              </div>
              {value.header}
            </Menu.Item>
          ))}
        </div>
        {/* <div className="navigation-menu__container__bottom-container">
          <div
            className="navigation-menu__container__bottom-container__item"
            onClick={() => setIsDarkTheme((oldVal) => !oldVal)}
          >
            {isDarkTheme ? <Sun /> : <Moon />}
          </div>
        </div> */}
        <div className={navigationSubMenuClassNames}>
          {selected && (
            <NavigationSubMenu
              companies={companies}
              setVisibility={setSelectedVisible}
              header={options[selected].header}
              options={options[selected].options}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default NavigationMenu;
