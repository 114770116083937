import React from 'react';
import './Test1.styles.scss';

type Test1Props = {};

const Test1: React.FC<Test1Props> = (props) => {
  return <div>hello from Test1!</div>;
};

export default Test1;
