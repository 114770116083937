import { OptionType } from '../../../components/NavigationMenu/NavigationMenu.component';

export type HeaderOptions = 'Users';
export type NavigationOptions = 'Companies';

export const headerOptions: Record<HeaderOptions, OptionType> = {
  Users: {
    header: 'Users',
    icon: 'user',
    options: ['Applicants', 'Drivers', 'Agents', 'Orientation'],
  },
};

export const navigationOptions: Record<NavigationOptions, OptionType> = {
  Companies: {
    header: 'Companies',
    icon: 'building',
  },
};
