export const BUS_EVENTS = {
  AGENT_STATUS_CHANGED: 'agent-status-changed',
  EH_STATUS_CHANGED: 'employment-history-status-changed',
  DRIVER_STATUS_CHANGED: 'driver-status-changed',
  PDF_SIGNED: 'pdf-signed',
  PDF_CREATION_ERROR: 'pdf-error',
  NOTIFICATION_CREATED: 'notification-created',
  NOTIFICATION_READ: 'notification-read',
  DRIVER_DRIVING_CHANGED: 'driver-driving',
  INSTRUCTORS_REVIEWED: 'instructors-reviewed',
  COURSE_STATUS_CHANGED: 'course-status-changed',
};
