import React, { useMemo } from 'react';
import { Redirect, RouteComponentProps, RouteProps } from 'react-router-dom';
import authService from 'services/authService';
import credentialsService from 'services/credentialsService';
import { Role } from 'models/User';

export type EnhancedRouteProps = {
  restriction?: 'private' | 'public' | 'none';
  authorizedRoles?: Array<Role>;
  componentProps?: Record<string, any>;
} & RouteProps<string> &
  RouteComponentProps;

const EnhancedRoute: React.FC<EnhancedRouteProps> = (props) => {
  const {
    restriction = 'private',
    component: Component,
    componentProps,
    authorizedRoles,
    ...rest
  } = props;

  const isLoggedIn = useMemo(
    () => (credentialsService.token ? true : false),
    [],
  );
  const user = useMemo(() => credentialsService.user, []);

  const roleRestricted = useMemo(
    () => restriction === 'private' || authorizedRoles,
    [authorizedRoles, restriction],
  );

  const finalRoute = useMemo(() => {
    if (roleRestricted && !isLoggedIn) {
      return (
        <Redirect
          to={{ pathname: '/login', state: { from: props.location } }}
        />
      );
    }

    if (restriction === 'none')
      return <Component {...rest} {...componentProps} />;

    if (restriction === 'public' && isLoggedIn) {
      return <Redirect to="/" />;
    }

    if (
      authorizedRoles &&
      !authService.checkRolesForUser(user, authorizedRoles)
    ) {
      credentialsService.removeAuthBody();
    }

    return <Component {...rest} {...componentProps} />;
  }, [
    roleRestricted,
    isLoggedIn,
    restriction,
    authorizedRoles,
    user,
    Component,
    rest,
    componentProps,
    props.location,
  ]);

  return finalRoute;
};

export default EnhancedRoute;
