import React from 'react';
import { useState, memo, useCallback, useMemo } from 'react';
import {
  Button,
  Dimmer,
  Header,
  Loader,
  Modal,
  Table,
} from 'semantic-ui-react';

import useFilesHistory from 'hooks/useFilesHistory';
import './SafetyDocumentsHistoryModal.styles.scss';
import TableDocumentsHeader from 'components/TableDocumentsHeader';
import Tippy from 'components/Tippy';
import ConfirmationModal from 'components/ConfirmationModal';
import { apiDrivers } from 'api';
import { toast } from 'react-toastify';
import { FileFromDB } from 'hooks/useFiles';
import { useSelector } from 'react-redux';
import { State } from 'store/reducers';
import { formatedDate } from 'services/dateService';

const mappedApplicationType = {
  old: 'Old contract template',
  75: '75%',
  80: '80%',
};

type SafetyDocumentsHistoryModalProps = {
  fuelCardNumberHistory?: any[];
  fileType?: string;
  fileId?: string;
  driverId: string;
  groupId?: string;
  open: boolean;
  setOpen: () => void;
  setPermanentDeletedFile?: React.Dispatch<React.SetStateAction<FileFromDB>>;
};

const SafetyDocumentsHistoryModal: React.FC<SafetyDocumentsHistoryModalProps> = ({
  driverId,
  fileType,
  fileId,
  groupId,
  fuelCardNumberHistory,
  open,
  setOpen,
  setPermanentDeletedFile,
}) => {
  const [loading, setLoading] = useState<boolean>(false);

  const { roles } = useSelector((state: State) => state.currentUser);

  const isAdmin = useMemo(() => roles.includes('Admin'), [roles]);

  const { files, setFiles } = useFilesHistory(
    driverId,
    fileType,
    fileId,
    groupId,
    setLoading,
  );
  const header = fuelCardNumberHistory
    ? 'Fuel Card Number History'
    : `Safety Document "${fileType}" History`;

  const handlePermanentFileDeletion = useCallback(
    async (idFile: string) => {
      try {
        const {
          data: { file },
        } = await apiDrivers.permanentDeleteFile(idFile, driverId);

        setFiles((oldFiles) => oldFiles.filter((f) => f._id !== file._id));

        setPermanentDeletedFile({
          ...file,
          isDeleted: true,
        });

        if (files.length === 1) setOpen();

        toast.success(`File "${fileType}" is permanently deleted.`);
      } catch (err) {
        console.error(err);
      }
    },
    [
      driverId,
      fileType,
      files.length,
      setFiles,
      setOpen,
      setPermanentDeletedFile,
    ],
  );
  const adminOpportunities = useMemo(() => {
    const headerArray = ['Upload Date'];

    if (fileType === 'Lease agreement between company and driver')
      headerArray.push('Type');

    if (!fuelCardNumberHistory) {
      headerArray.push('Content');

      if (isAdmin) {
        headerArray.push('Uploaded/Replaced by', 'Action');
      }
    } else {
      headerArray.push('Value');
    }

    return headerArray;
  }, [fileType, fuelCardNumberHistory, isAdmin]);

  return (
    <Modal onClose={() => setOpen()} open={open} closeIcon>
      <Header>{header}</Header>
      <Modal.Content>
        {loading && (
          <Dimmer inverted active>
            <Loader />
          </Dimmer>
        )}
        <div className="dropzone-modal-content">
          <Table celled>
            <TableDocumentsHeader headerCells={adminOpportunities} />
            <Table.Body>
              {!fuelCardNumberHistory
                ? files.map(
                    (
                      { createdAt, fileUrl, _id, agent, applicationType },
                      index,
                    ) => (
                      <Table.Row key={index}>
                        <Table.Cell>{formatedDate(createdAt)}</Table.Cell>
                        {fileType ===
                          'Lease agreement between company and driver' && (
                          <Table.Cell>
                            {mappedApplicationType[applicationType] ||
                              'Old contract template'}
                          </Table.Cell>
                        )}
                        <Table.Cell>
                          <a
                            className="preview"
                            href={fileUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Preview
                          </a>
                        </Table.Cell>
                        {isAdmin && (
                          <React.Fragment>
                            <Table.Cell>{agent?.fullName || ''}</Table.Cell>
                            <Table.Cell>
                              <Tippy title="Permanent file deletion">
                                <ConfirmationModal
                                  header="Deleting document"
                                  message="Are you sure you want to delete this document permanently?"
                                  action={() =>
                                    handlePermanentFileDeletion(_id)
                                  }
                                  trigger={<Button color="red" icon="delete" />}
                                  color="red"
                                  buttonContent="Delete"
                                  buttonIcon="trash"
                                  isDeleteModal
                                />
                              </Tippy>
                            </Table.Cell>
                          </React.Fragment>
                        )}
                      </Table.Row>
                    ),
                  )
                : fuelCardNumberHistory.map(({ createdAt, value }, index) => (
                    <Table.Row key={index}>
                      <Table.Cell>{formatedDate(createdAt)}</Table.Cell>
                      <Table.Cell>{value}</Table.Cell>
                    </Table.Row>
                  ))}
            </Table.Body>
          </Table>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button color="black" onClick={() => setOpen()}>
          Cancel
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default memo(SafetyDocumentsHistoryModal);
