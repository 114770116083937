import { DriverDocumentsType, SignedHireAndTerminateFiles } from 'types/Users';

export const prepareNotAvailableDocument = (
  documentType: DriverDocumentsType,
  url: string,
): SignedHireAndTerminateFiles => ({
  documentType,
  agent: {
    fullName: 'Not available',
    email: 'Not available',
    firstName: 'Not available',
    _id: 'Not available',
    isActive: false,
    lastName: 'Not available',
    password: 'Not available',
    username: 'Not available',
    agent_review: null,
  },
  dateSigned: new Date(2021, 8, 1),
  url,
});
