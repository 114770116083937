import React, { useState, useMemo, useCallback } from 'react';
import { FormInput, Label } from 'components/FinalFormComponents';
import { Field, Form, FormSpy } from 'react-final-form';
import { Button, Modal } from 'semantic-ui-react';
import validateService from 'services/validateService';
import { Driver, EquipmentIdentification } from 'types/Users';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import './DriverRoleModal.styles.scss';
import { restrictKeyBoardTasters } from 'components/DOTNumberModal/utils';
import FormRadioGroup from 'components/FinalFormComponents/FormRadioGroup';
import { contractTemplate, federalTaxes } from './config';
import Wizard from 'components/Wizard/Wizard.component';
import DriversRoadTest from 'DriversRoadTest';
import CertificationOfRoadTest from 'components/CertificationOfRoadTest';
import { apiDrivers } from 'api';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import enums from 'enums';

type DriverRoleModalProps = {
  trigger?: React.ReactNode;
  driver?: Driver;
};

export type RoleValuesType = {
  dateTo?: Date;
  dateFrom?: Date;
  contractFrom?: Date;
  contractTo?: Date;
  dollarsPerMile?: number | string;
  dayOne?: string;
  dayTwo?: number;
  dayThree?: number;
  isOwnIFTA?: string | boolean;
  equipmentIdentification?: EquipmentIdentification[];
  city?: string;
  state?: string;
  zip?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  businessName?: string;
  other?: string;
  federalTax?: string;
  employerId?: string;
  listAccountNumbers?: string;
  requesterInfo?: string;
  exemption?: string;
  payeeCode?: string;
  applicationType?: string;
};
const { ACTION_MESSAGES } = enums;

const DriverRoleModal: React.FC<DriverRoleModalProps> = (props) => {
  const { trigger, driver } = props;

  const [open, setOpen] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<RoleValuesType>();
  const [loading, setLoading] = useState(false);

  const formClassNames = useMemo(() => classNames('ui', 'form', { loading }), [
    loading,
  ]);

  const onFormSubmit = useCallback(async () => {
    try {
      setLoading(true);
      const { state, city, federalTax, dateFrom, dateTo, ...rest } = formValues;

      const mappedFederalTax = federalTaxes.reduce(
        (acc, tax) => ({
          ...acc,
          [tax.radioValue]: tax.radioValue === federalTax,
        }),
        {},
      );
      await apiDrivers.sendToSign(driver.id, window.location.href, {
        ...rest,
        isOwnIFTA: formValues.isOwnIFTA === 'isOwnIFTAFile',
        ...mappedFederalTax,
        leaseStartDate: dateFrom,
        leaseEndDate: dateTo,
      });

      toast.success(ACTION_MESSAGES.SUCCESS_MESSAGE.SENT_TO_SIGN);

      setOpen(false);
    } catch (err) {
      console.error(err);
      toast.error(ACTION_MESSAGES.ERROR_MESSAGE.SEND_TO_SIGN);
    } finally {
      setLoading(false);
    }
  }, [driver.id, formValues]);

  // intialValue of FieldArray(final-form) need to be extracted in constant or in this case to be memoized,
  // if this is not a case, setting intialValue in FieldArray props will cause infinite render.
  const equipmentIdentificationInitialValue = useMemo(() => [{}], []);

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={trigger}
      closeIcon
      className="driver-role-modal"
    >
      <Modal.Header>
        {`LEASE AGREEMENT BETWEEN "${driver.companyInfo.name}" AND INDEPENDENT CONTRACTOR (OWNER/OPERATOR)`}
      </Modal.Header>
      <Modal.Content className="driver-role-modal__content">
        <Form
          onSubmit={onFormSubmit}
          mutators={{
            ...arrayMutators,
          }}
          render={({
            handleSubmit,
            submitting,
            hasValidationErrors,
            form: {
              mutators: { push },
            },
            form,
          }) => (
            <form onSubmit={handleSubmit} className={formClassNames}>
              <Wizard
                submitText="Send to sign"
                loading={submitting}
                hasErrors={hasValidationErrors}
                isSoleForm={Boolean(!driver.hasLessThan2YearsOfExperience)}
              >
                <div className="driver-role-modal--owner-operator">
                  <React.Fragment>
                    <Field
                      name="dateFrom"
                      label="Contract from:"
                      required
                      type="date"
                      className="driver-role-modal--owner-operator__personal-info"
                      component={FormInput}
                      validate={validateService.beforeFieldValidation}
                    />
                    <Field
                      name="dateTo"
                      label="Contract to:"
                      type="date"
                      className="driver-role-modal--owner-operator__personal-info"
                      component={FormInput}
                      required
                      validate={validateService.afterFieldValidation}
                    />
                  </React.Fragment>
                  <Field
                    name="applicationType"
                    title="Select contract template"
                    titleClassName="driver-role-modal--owner-operator__label"
                    buttonsClassName="driver-role-modal--owner-operator__radio-group"
                    component={FormRadioGroup}
                    radioButtons={contractTemplate}
                  />
                  <Field
                    name="isOwnIFTA"
                    label="I CHOOSE TO FILE MY OWN IFTA AND TO FILE MY OWN MOTOR FUEL USE TAX RETURNS"
                    value="isOwnIFTAFile"
                    className="driver-role-modal--owner-operator__ifta"
                    required
                    type="radio"
                    component={FormInput}
                    validate={validateService.required}
                  />
                  <Field
                    name="isOwnIFTA"
                    label="I CHOOSE TO USE CARRIER’S IFTA AND CARRIER TO FILE MY MOTOR FUEL USE TAX RETURNS"
                    value="isOwnIFTACarrier"
                    className="driver-role-modal--owner-operator__ifta"
                    required
                    type="radio"
                    component={FormInput}
                    validate={validateService.required}
                  />
                  <>
                    <Label
                      label="W9 form"
                      className="driver-role-modal--owner-operator__title"
                    />
                    <Field
                      name="businessName"
                      label="Business name/disregarded entity name"
                      className="driver-role-modal--owner-operator__personal-info"
                      component={FormInput}
                    />
                    <Field
                      name="federalTax"
                      title="Select appropriate box for federal tax classification"
                      titleClassName="driver-role-modal--owner-operator__label"
                      buttonsClassName="driver-role-modal--owner-operator__radio-group"
                      component={FormRadioGroup}
                      radioButtons={federalTaxes}
                    />
                    {formValues?.federalTax === 'limitedLiability' && (
                      <Field
                        name="limitedLiabilityLetter"
                        label="Enter the tax classification (C=C corporation, S=S corporation, P=Partnership)"
                        className="driver-role-modal--owner-operator__personal-info"
                        required
                        component={FormInput}
                        validate={validateService.composeValidators(
                          validateService.required,
                          validateService.length1AndExactLetter([
                            'C',
                            'S',
                            'P',
                          ]),
                        )}
                      />
                    )}
                    {formValues?.federalTax === 'other' && (
                      <Field
                        name="otherInstructions"
                        label="Instructions"
                        className="driver-role-modal--owner-operator__personal-info"
                        required
                        component={FormInput}
                        validate={validateService.required}
                      />
                    )}
                    <div className="driver-role-modal--owner-operator__personal-info">
                      <span className="driver-role-modal--owner-operator__bold">
                        Note:
                      </span>
                      <Label
                        label="Check the appropriate box in the line above for the tax classification of the single-member owner.  Do not check 
                      LLC if the LLC is classified as a single-member LLC that is disregarded from the owner unless the owner of the LLC is 
                      another LLC that is not disregarded from the owner for U.S. federal tax purposes. Otherwise, a single-member LLC that 
                      is disregarded from the owner should check the appropriate box for the tax classification of its owner."
                      />
                    </div>
                    <Label
                      className="driver-role-modal--owner-operator__label"
                      label="Exemptions (codes apply only to certain entities, not individuals)"
                    />
                    <Field
                      name="payeeCode"
                      label="Exempt payee code (if any)"
                      className="driver-role-modal--owner-operator__personal-info"
                      component={FormInput}
                    />
                    <Field
                      name="exemption"
                      label="Exemption from FATCA reporting code (if any)"
                      className="driver-role-modal--owner-operator__personal-info"
                      component={FormInput}
                    />
                    <Field
                      name="listAccountNumbers"
                      label="List account number(s) here (optional)"
                      className="driver-role-modal--owner-operator__personal-info"
                      component={FormInput}
                    />
                    <Field
                      name="requesterInfo"
                      label="Requester’s name and address (optional)"
                      className="driver-role-modal--owner-operator__personal-info"
                      component={FormInput}
                    />
                    <Field
                      name="employerId"
                      label="Employer identification number (optional)"
                      className="driver-role-modal--owner-operator__personal-info"
                      component={FormInput}
                      validate={validateService.employerIdValidation}
                    />
                  </>
                  <FieldArray
                    name="equipmentIdentification"
                    initialValue={equipmentIdentificationInitialValue}
                  >
                    {({ fields }) =>
                      fields.map((name, index) => (
                        <div
                          className="driver-role-modal--equipment-identification"
                          key={name}
                        >
                          {index > 0 && (
                            <Button
                              className="equipment-identification__remove-equipment"
                              icon="minus"
                              labelPosition="right"
                              floated="right"
                              content="Remove"
                              color="red"
                              size="mini"
                              type="button"
                              onClick={() => fields.remove(index)}
                            />
                          )}
                          <Field
                            name={`${name}.trailer`}
                            label="Unit number"
                            required
                            component={FormInput}
                            validate={validateService.required}
                          />

                          {formValues?.applicationType === 'old' ? (
                            <Field
                              name={`${name}.year`}
                              label="Make/Year"
                              required
                              component={FormInput}
                              validate={validateService.required}
                            />
                          ) : (
                            <>
                              <Field
                                name={`${name}.make`}
                                label="Make"
                                required
                                component={FormInput}
                                validate={validateService.required}
                              />
                              <Field
                                name={`${name}.year`}
                                label="Year"
                                required
                                type="number"
                                component={FormInput}
                                onKeyDown={restrictKeyBoardTasters}
                                validate={validateService.required}
                              />
                            </>
                          )}
                          <Field
                            name={`${name}.model`}
                            label="Model"
                            required
                            component={FormInput}
                            validate={validateService.required}
                          />
                          <Field
                            name={`${name}.vinNumber`}
                            label="Vin Number"
                            required
                            component={FormInput}
                            validate={validateService.required}
                          />
                          <Field
                            name={`${name}.license`}
                            label="License"
                            required
                            component={FormInput}
                            validate={validateService.required}
                          />
                          <Field
                            name={`${name}.licenseState`}
                            label="License State"
                            required
                            component={FormInput}
                            validate={validateService.required}
                          />
                        </div>
                      ))
                    }
                  </FieldArray>
                  <Button
                    className="equipment-identification__add-equipment"
                    icon="add circle"
                    labelPosition="right"
                    floated="right"
                    content="Add"
                    color="green"
                    size="mini"
                    type="button"
                    disabled={
                      form.getFieldState('equipmentIdentification') &&
                      form.getFieldState('equipmentIdentification').length === 2
                    }
                    onClick={() => push('equipmentIdentification', undefined)}
                  />
                </div>

                {driver.hasLessThan2YearsOfExperience && <DriversRoadTest />}
                {driver.hasLessThan2YearsOfExperience && (
                  <CertificationOfRoadTest />
                )}
              </Wizard>
              <FormSpy
                subscription={{ values: true, dirty: true }}
                onChange={(renderProps) => {
                  const { values, dirty } = renderProps;

                  if (values.federalTax !== 'limitedLiability')
                    form.change('limitedLiabilityLetter', undefined);
                  if (values.federalTax !== 'other')
                    form.change('otherInstructions', undefined);

                  if (dirty) setFormValues(values);
                }}
              />
            </form>
          )}
        />
      </Modal.Content>
    </Modal>
  );
};

export default DriverRoleModal;
