import { emailValidation } from 'components/ProfileUpdateModal/utils';
import { departments } from 'constants/agents';
import { Schema } from 'pages/DriverDetails/utils';
import validateService from 'services/validateService';
import { Agent } from 'types/Users';

export const agentSchema = (agent: Agent) =>
  ({
    username: {
      label: 'Username',
      type: 'preview',
    },
    firstName: {
      label: 'First name',
      type: 'text',
      validate: (value) => !validateService.required(value),
      errorMessage: 'First name cannot be empty.',
    },
    lastName: {
      label: 'Last name',
      type: 'text',
      validate: (value) => !validateService.required(value),
      errorMessage: 'Last name cannot be empty.',
    },
    email: {
      type: 'text',
      label: 'Email',
      validate: (value: string) =>
        !validateService.required(value) && !emailValidation(value),
      errorMessage: 'Invalid email address.',
    },

    roles: {
      label: 'Role',
      type: 'preview',
    },
    title: {
      label: 'Title',
      type: 'preview',
    },
    departments: {
      label: 'Departments',
      type: 'select',
      multi: true,
      validate: (value: string[]) => !!value.length,
      errorMessage: 'Please assign a departmant.',
      options: departments.map((department) => ({
        text: department,
        value: department,
        key: department,
        selected: agent.departments.includes(department),
      })),
    },
    isVerified: {
      label: 'Verified',
      type: 'preview',
    },
    id: {
      label: 'Id',
      type: 'preview',
    },
    createdAt: {
      label: 'Account creation date',
      type: 'preview',
    },
    updatedAt: {
      label: 'Account updated date',
      type: 'preview',
    },
  } as Schema);
