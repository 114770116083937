import React from 'react';
import { Tooltip, Trigger } from 'react-tippy';

type PositionType = 'top' | 'bottom' | 'left' | 'right';

type TippyProps = {
  title: string;
  position?: PositionType;
  trigger?: Trigger;
  duration?: number;
};

const Tippy: React.FC<TippyProps> = ({
  children,
  title,
  position = 'top',
  trigger = 'mouseenter',
  duration = 0,
}) => {
  return (
    <Tooltip
      title={title}
      position={position}
      trigger={trigger}
      duration={duration}
      arrow
    >
      {children}
    </Tooltip>
  );
};
export default Tippy;
