import classNames from 'classnames';
import HireConfirmationModal from 'components/HireConfirmationModal';
import TableNavigation from 'components/TableNavigation';
import TerminateConfirmationModal from 'components/TerminateConfirmationModal';
import Tippy from 'components/Tippy';
import useFilterParams from 'hooks/useFilterParams';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Dropdown, Input, Table, Icon, Radio } from 'semantic-ui-react';
import { SemanticCOLORS } from 'semantic-ui-react/dist/commonjs/generic';
import enums from '../../enums';
import './Drivers.styles.scss';
import { defaultFilterValues, statusFilterOptions } from './utils';
import bus, { EventName } from 'modules/bus';
import { BUS_EVENTS } from 'modules/bus/busEvents';
import useElementScrollTo from 'hooks/useElementScrollTo';
import { apiAgents, apiDrivers } from 'api';
import { State } from 'store/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import ConfirmationModal from 'components/ConfirmationModal';
import { DELETED_DRIVERS, UNCHECKED_DRIVERS } from 'store/actions/actionTypes';
import { formatedDate } from 'services/dateService';
import useEffectSkipFirst from 'hooks/useEffectSkipFirst';
import { useFetchWithLimit } from 'hooks/useFetchWithLimit';
import RequestEditModal from 'components/RequestEditModal';
import { Driver } from 'types/Users';

const TABLE_HEADER = 'Drivers list';

const { DRIVER_STATUS_COLORS, DRIVER_STATUS, ACTION_MESSAGES } = enums;

const Drivers: React.FC = (props) => {
  const [sortByDateColumn, setSortByDateColumn] = useState<{
    direction: 'ascending' | 'descending';
    name: string;
  }>({ direction: 'ascending', name: '' });

  const dispatch = useDispatch();

  const isUncheckedDrivers = useSelector(
    (state: State) => state.isUncheckedDrivers,
  );
  const [
    isEnalbedUncheckedDrivers,
    setIsEnabledUncheckedDrivers,
  ] = useState<boolean>(isUncheckedDrivers);

  const {
    skip,
    pagination,
    searchValue,
    isEnabledDeletedDrivers,
    items: drivers,
    setItems: setDrivers,
    loading,
    setEmployedById,
    setIsEnabledDeletedDrivers,
    setStatus,
    setSearchValue,
    setSort,
    setAdditionalFilters,
  } = useFilterParams({
    fallbackRoute: '/drivers',
    requestFunc: apiDrivers.loadDrivers,
    defaultStatusFilter: defaultFilterValues,
    additionalFiltersInitialValues: {
      statusHistory: isEnalbedUncheckedDrivers
        ? {
            $elemMatch: { status: { $ne: 'Hired/Checked' } },
          }
        : undefined,
    },
  });

  const headerRef = useRef();
  useElementScrollTo(headerRef, skip);

  const { roles } = useSelector((state: State) => state.currentUser);

  const isAdmin = useMemo(() => roles.includes('Admin'), [roles]);

  const tableClassNames = classNames('drivers__table', {
    'drivers__table--loading': loading,
  });

  const rootClassNames = classNames('drivers', {
    'drivers--admin': isAdmin,
  });

  const { items: agents } = useFetchWithLimit({
    shouldExecuteCall: isAdmin,
    request: apiAgents.loadAgents,
    additionalParams: { isActive: true },
  });

  const transformAgentsToOptions = useCallback(() => {
    return Object.entries(agents).map((agent) => ({
      key: agent[1]['_id'],
      value: agent[1]['_id'],
      text: `${agent[1]['firstName']} ${agent[1]['lastName']}`,
    }));
  }, [agents]);

  const handleDriverDeletion = useCallback(
    async (id: string, applicantName: string) => {
      try {
        isEnabledDeletedDrivers
          ? await apiDrivers.permanentDeleteDriver(id)
          : await apiDrivers.softDeleteDriver(id);

        setDrivers((oldVal) => oldVal.filter((driver) => driver.id !== id));
        toast.success(`${applicantName} is successfully deleted.`);
      } catch (err) {
        console.error(err);
      }
    },
    [isEnabledDeletedDrivers, setDrivers],
  );

  const handleSortDirection = useCallback(
    (columnName: string) => {
      setSort(
        sortByDateColumn.direction === 'ascending'
          ? `-${columnName}`
          : `${columnName}`,
      );

      setSortByDateColumn({
        direction:
          sortByDateColumn?.direction === 'ascending'
            ? 'descending'
            : 'ascending',
        name: columnName,
      });
    },
    [setSort, sortByDateColumn.direction],
  );

  useEffect(() => {
    const driverIsDrivingChangedListener = (args: any) => {
      const { payload } = args;
      setDrivers((oldVal) =>
        oldVal.map((driver) =>
          driver.id === payload.driverId
            ? { ...driver, isDriving: payload.isDriving }
            : driver,
        ),
      );
    };

    const driverStatusChanged = async (args: any) => {
      const { payload } = args;

      let verifiedDriver: Driver = null;

      if (payload.status === 'Verified') {
        ({
          data: { driver: verifiedDriver },
        } = await apiDrivers.getDriver(payload.driverId));
      }

      setDrivers((old) => {
        if (verifiedDriver) return [verifiedDriver, ...old];

        return old.reduce((acc, driver) => {
          if (driver._id === payload.driverId) {
            if (payload.status === 'Changes requested') {
              return acc;
            }
            return [
              ...acc,
              {
                ...driver,
                ...payload,
              },
            ];
          }
          return [...acc, driver];
        }, []);
      });
    };

    bus.addEventListener(
      BUS_EVENTS.DRIVER_STATUS_CHANGED as EventName,
      driverStatusChanged,
    );

    bus.addEventListener(
      BUS_EVENTS.DRIVER_DRIVING_CHANGED as EventName,
      driverIsDrivingChangedListener,
    );

    return () => {
      bus.removeEventListener(
        BUS_EVENTS.DRIVER_STATUS_CHANGED as EventName,
        driverStatusChanged,
      );
      bus.removeEventListener(
        BUS_EVENTS.DRIVER_DRIVING_CHANGED as EventName,
        driverIsDrivingChangedListener,
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffectSkipFirst(() => {
    setAdditionalFilters((old) => ({
      ...old,
      statusHistory: isEnalbedUncheckedDrivers
        ? {
            $elemMatch: { status: { $ne: 'Hired/Checked' } },
          }
        : undefined,
    }));
    dispatch({
      type: UNCHECKED_DRIVERS,
      payload: isEnalbedUncheckedDrivers,
    });
  }, [dispatch, isEnalbedUncheckedDrivers, setAdditionalFilters]);

  return (
    <div ref={headerRef} className={rootClassNames}>
      <div className="drivers__top">
        <Input
          label="Search drivers"
          icon="search"
          value={searchValue}
          onChange={({ target: { value } }) => setSearchValue(value)}
        />
        {isAdmin && (
          <Radio
            toggle
            label="Deleted drivers"
            checked={isEnabledDeletedDrivers}
            onChange={() => {
              setIsEnabledDeletedDrivers((oldVal) => !oldVal);
              dispatch({
                type: DELETED_DRIVERS,
                payload: !isEnabledDeletedDrivers,
              });
            }}
          />
        )}
        <Radio
          toggle
          label="Unchecked drivers"
          checked={isEnalbedUncheckedDrivers}
          onChange={() => {
            setIsEnabledUncheckedDrivers((oldVal) => !oldVal);
          }}
        />
        <div className="drivers__top__right-menu">
          {isAdmin && (
            <Dropdown
              placeholder="Select or search agent"
              fluid
              search
              selection
              clearable
              scrolling
              selectOnBlur={false}
              options={agents ? transformAgentsToOptions() : null}
              onChange={(_, option) => {
                setEmployedById(option.value.toString());
              }}
            />
          )}
          <Dropdown
            placeholder="Select status filters"
            multiple
            selection
            clearable
            selectOnBlur={false}
            options={statusFilterOptions}
            float="right"
            onChange={(_, data) => {
              const newStatusData = data.value as string[];

              setStatus(
                newStatusData.length ? newStatusData : defaultFilterValues,
              );
            }}
          />
        </div>
      </div>
      <h2>{TABLE_HEADER}</h2>
      <div className="table-wrapper">
        <Table sortable celled className={tableClassNames}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Email</Table.HeaderCell>
              <Table.HeaderCell
                sorted={
                  sortByDateColumn.name === 'createdAt'
                    ? sortByDateColumn.direction
                    : null
                }
                onClick={() => handleSortDirection('createdAt')}
              >
                Created At
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={
                  sortByDateColumn.name === 'signDate'
                    ? sortByDateColumn.direction
                    : null
                }
                onClick={() => handleSortDirection('signDate')}
              >
                Signed At
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={
                  sortByDateColumn.name === 'employedDate'
                    ? sortByDateColumn.direction
                    : null
                }
                onClick={() => handleSortDirection('employedDate')}
              >
                Hired At
              </Table.HeaderCell>
              <Table.HeaderCell>Id</Table.HeaderCell>
              <Table.HeaderCell>Company</Table.HeaderCell>
              <Table.HeaderCell>Application Creator</Table.HeaderCell>
              {isAdmin && <Table.HeaderCell>Employer</Table.HeaderCell>}
              <Table.HeaderCell>Status</Table.HeaderCell>
              <Table.HeaderCell>Driving</Table.HeaderCell>
              <Table.HeaderCell>Actions</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {drivers.map(
              ({
                applicantName,
                email,
                companyInfo,
                id,
                status,
                signedApplicationUrl,
                applicationCreator,
                isDriving,
                employedBy,
                createdAt,
                signDate,
                employedDate,
                statusHistory,
              }) => {
                return (
                  <Table.Row key={email + Math.random()}>
                    <Table.Cell>
                      {!statusHistory.some(
                        ({ status }) =>
                          status === DRIVER_STATUS['Hired/Checked'],
                      ) && (
                        <Tippy title="The documents have not yet been verified">
                          <NavLink to={`/drivers/${id}/files`}>
                            <Icon name="exclamation triangle" color="yellow" />
                          </NavLink>
                        </Tippy>
                      )}
                      <span>{applicantName ? applicantName : 'user'}</span>
                    </Table.Cell>
                    <Table.Cell>{email}</Table.Cell>
                    <Table.Cell>{formatedDate(createdAt)} </Table.Cell>
                    <Table.Cell>{formatedDate(signDate, false)} </Table.Cell>
                    <Table.Cell>{formatedDate(employedDate, false)}</Table.Cell>
                    <Table.Cell>{id}</Table.Cell>
                    <Table.Cell>{companyInfo?.displayName}</Table.Cell>
                    <Table.Cell>
                      {isAdmin ? (
                        <NavLink to={`/agents/${applicationCreator?.id}`}>
                          {applicationCreator?.fullName}
                        </NavLink>
                      ) : (
                        applicationCreator?.fullName
                      )}
                    </Table.Cell>

                    {isAdmin && (
                      <Table.Cell>
                        <NavLink to={`/agents/${employedBy?.id}`}>
                          {employedBy?.fullName}
                        </NavLink>
                      </Table.Cell>
                    )}
                    <Table.Cell>
                      <Tippy title="Status history">
                        <NavLink
                          to={`/drivers/${id}/status-history`}
                          className={`${DRIVER_STATUS_COLORS[status]} status-label`}
                        >
                          {DRIVER_STATUS[status]}
                        </NavLink>
                      </Tippy>
                    </Table.Cell>
                    <Table.Cell className="is-driving">
                      <div
                        className={`is-driving__icon is-driving__icon-${
                          isDriving ? 'check' : 'x'
                        }`}
                      >
                        {isDriving ? (
                          <Icon name="checkmark" />
                        ) : (
                          <Icon name="close" />
                        )}
                      </div>
                    </Table.Cell>
                    <Table.Cell>
                      <div className="button-group">
                        <Tippy title="Driver details">
                          <NavLink to={`/drivers/${id}`}>
                            <Button icon="edit" />
                          </NavLink>
                        </Tippy>
                        <Tippy title="Request form edit">
                          <RequestEditModal
                            header={`Request changes from ${applicantName}`}
                            applicantsId={id}
                            shouldOpenConfirmationModal
                            trigger={<Button icon="undo" />}
                            apiRequest={apiDrivers.requestChangesFromDriver}
                          />
                        </Tippy>
                        <Tippy title="Employment history">
                          <NavLink to={`/employment-history/${id}`}>
                            <Button icon="send" />
                          </NavLink>
                        </Tippy>
                        {/* <Tippy title="Open pdf">
                          <Button
                            icon="file pdf"
                            as="a"
                            disabled={!signedApplicationUrl}
                            href={signedApplicationUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                          />
                        </Tippy> */}
                        <Tippy title="Safety documents">
                          <NavLink to={`/drivers/${id}/files`}>
                            <Button icon="file archive outline" />
                          </NavLink>
                        </Tippy>
                        <Tippy title="For-Company-Use Document.">
                          <HireConfirmationModal
                            driverId={id}
                            driverName={applicantName}
                            status={DRIVER_STATUS[status]}
                            trigger={
                              <Button
                                icon="file"
                                color={
                                  DRIVER_STATUS_COLORS.Hired as SemanticCOLORS
                                }
                                disabled={isEnabledDeletedDrivers}
                              />
                            }
                          />
                        </Tippy>
                        <Tippy title="Termination-of-Employment Document.">
                          <TerminateConfirmationModal
                            driverId={id}
                            driverName={applicantName}
                            status={DRIVER_STATUS[status]}
                            trigger={
                              <Button
                                icon="file"
                                color={
                                  DRIVER_STATUS_COLORS.Terminated as SemanticCOLORS
                                }
                                disabled={
                                  isEnabledDeletedDrivers ||
                                  DRIVER_STATUS[status] ===
                                    DRIVER_STATUS.Verified
                                }
                              />
                            }
                          />
                        </Tippy>
                        {isAdmin && (
                          <Tippy
                            title={
                              isEnabledDeletedDrivers
                                ? 'Permanent driver deletion'
                                : 'Delete driver'
                            }
                          >
                            <ConfirmationModal
                              header={
                                ACTION_MESSAGES.CONFIRM_MODAL.DELETION
                                  .HEADER_DRIVER
                              }
                              message={
                                isEnabledDeletedDrivers
                                  ? `Are you sure you want to delete driver "${applicantName}" permanently?`
                                  : `Are you sure you want to delete driver "${applicantName}"?`
                              }
                              action={() =>
                                handleDriverDeletion(id, applicantName)
                              }
                              trigger={
                                <Button
                                  color="red"
                                  icon={
                                    isEnabledDeletedDrivers
                                      ? 'close'
                                      : 'trash alternate'
                                  }
                                />
                              }
                              color="red"
                              buttonContent="Delete"
                              buttonIcon="trash"
                              isDeleteModal
                            />
                          </Tippy>
                        )}
                      </div>
                    </Table.Cell>
                  </Table.Row>
                );
              },
            )}
          </Table.Body>
        </Table>
        <TableNavigation pagination={pagination} />
      </div>
    </div>
  );
};
export default Drivers;
