import React, { useState } from 'react';
import { Radio } from 'semantic-ui-react';

type RadioType = {
  label: string;
  radioValue: string;
  driverRole?: string | number;
};

export type RadioGroupProps = {
  radioButtons: RadioType[];
  className?: string;
  title?: string;
  titleClassName?: string;
  buttonsClassName?: string;
  handleOnChange: (
    e: React.FormEvent<HTMLInputElement>,
    value: string | number,
  ) => void;
};

const RadioGroup: React.FC<RadioGroupProps> = (props) => {
  const {
    radioButtons,
    handleOnChange,
    className,
    title,
    titleClassName,
    buttonsClassName,
  } = props;

  const [activeValue, setActiveValue] = useState<string | number | undefined>();

  return (
    <div className={className}>
      {title && <p className={titleClassName}>{title}</p>}
      <div className={buttonsClassName}>
        {radioButtons.map(({ label, radioValue, driverRole }) => (
          <Radio
            key={radioValue}
            label={label}
            value={radioValue}
            checked={
              driverRole
                ? driverRole === radioValue
                : radioValue === activeValue
            }
            onChange={(e, { value }) => {
              setActiveValue(value);
              handleOnChange(e, value);
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default RadioGroup;
