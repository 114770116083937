import React from 'react';
import Agents from 'pages/Agents/Agents.page';
import redirect from 'router/modules/redirect';
import { EnhancedRouteProps } from 'router/routes/Enhanced/Enhanced.route';
import '../../index.scss';
import Applicants from '../Applicants';
import DriverDetails from '../DriverDetails';
import Drivers from '../Drivers';
import Activity from 'pages/Activity';
import EmploymentHistory from 'pages/EmploymentHistory';
import SafetyDocuments from 'pages/SafetyDocuments/SafetyDocuments.page';
import AgentsDetails from 'pages/AgentDetails';
import DriverStatusHistory from 'pages/DriverStatusHistory';
import Orientation from 'pages/Orientation';

const routes: Partial<EnhancedRouteProps>[] = [
  {
    path: '/applicants',
    component: Applicants,
    exact: true,
  },
  {
    path: '/agents',
    component: Agents,
    exact: true,
  },
  {
    path: '/drivers/:id',
    component: DriverDetails,
  },
  {
    path: '/applicants/:id',
    component: DriverDetails,
  },
  {
    path: '/drivers',
    component: Drivers,
    exact: true,
  },
  {
    path: '/drivers/:id/status-history',
    component: DriverStatusHistory,
  },
  {
    path: '/activity-page',
    component: Activity,
  },
  {
    path: '/employment-history/:id',
    component: EmploymentHistory,
  },
  {
    path: '/',
    component: redirect('/applicants'),
  },
  {
    path: '/drivers/:id/files',
    component: SafetyDocuments,
  },
  {
    path: '/agents/:id/',
    component: AgentsDetails,
  },
  {
    path: '/orientation',
    component: Orientation,
  },
  {
    path: '*',
    component: () => (
      <div className="not-found">
        <div className="not-found__card">
          <h1>Page does not exist</h1>
        </div>
      </div>
    ),
  },
];

export default routes;
