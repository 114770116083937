import React from 'react';
import ConfirmationLinkModal from 'components/ConfirmationLinkModal';
import {
  DOCU_SIGN_CONFIMATION_ACTION_MESSAGE,
  DOCU_SIGN_CONFIMATION_MESSAGE,
  DOCU_SIGN_CONFIRMATION_HEADER,
  DOCU_SIGN_CONFIRMATION_MESSAGE_HEADER,
} from './utils';
import enums from 'enums';
import { apiDrivers } from 'api';
const { DRIVER_STATUS } = enums;

type DocuSignModalProps = {
  headerMessage?: string;
  url?: string;
  trigger?: React.ReactNode;
  header?: string;
  driverId: string;
  driverName: string;
  status: typeof DRIVER_STATUS[keyof typeof DRIVER_STATUS];
};

const DocuSignModal: React.FC<DocuSignModalProps> = (
  props: DocuSignModalProps,
) => {
  const handleAction = () =>
    apiDrivers.signDocument(props.driverId, window.location.href);

  return (
    <ConfirmationLinkModal
      {...props}
      header={DOCU_SIGN_CONFIRMATION_HEADER}
      messageHeader={DOCU_SIGN_CONFIRMATION_MESSAGE_HEADER}
      message={DOCU_SIGN_CONFIMATION_MESSAGE}
      actionMessage={DOCU_SIGN_CONFIMATION_ACTION_MESSAGE}
      action={handleAction}
      actionStatus={null}
      isDocuSignModal
    />
  );
};

export default DocuSignModal;
