import React from 'react';
import { Modal, Header, Button } from 'semantic-ui-react';
import { Form, Field } from 'react-final-form';
import { FormInput } from 'components/FinalFormComponents';
import ConfirmationModal from 'components/ConfirmationModal';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { apiAuth } from 'api';

const PasswordUpdateModal = (props: any) => {
  const { open, onClose, handleToggleModal } = props;

  const formClassNames = classNames('ui', 'form');

  const requiredValidation = (value: string) => {
    if (!value) {
      return 'Required';
    }
  };

  const onFormSubmit = async (values: any) => {
    const { oldPassword, newPassword } = values;

    const preparedData = { oldPassword, newPassword };

    try {
      await apiAuth.updatePassword(preparedData);
      toast.success('You have successfully updated password.');
      onClose();
    } catch (error) {
      toast.error('Wrong password. Please try again.');
      console.error(error);
    }
  };

  return (
    <Modal closeIcon onClose={onClose} open={open}>
      <Header>Change password</Header>

      <Modal.Content>
        <Form
          onSubmit={(values) => onFormSubmit(values)}
          validate={(values) => {
            const errors: any = {};

            if (values.newPassword !== values.confirmNewPassword) {
              errors.passwords = 'Match passwords.';
            }

            return errors;
          }}
          render={({
            handleSubmit,
            submitting,
            hasValidationErrors,
            values,
          }) => (
            <form onSubmit={handleSubmit} className={formClassNames}>
              <Field
                component={FormInput}
                label="Old password"
                name="oldPassword"
                type="password"
                required
                validate={requiredValidation}
              />

              <Field
                component={FormInput}
                label="New password"
                name="newPassword"
                type="password"
                required
                validate={requiredValidation}
              />

              <Field
                component={FormInput}
                label="Confirm new password"
                name="confirmNewPassword"
                type="password"
                required
                validate={requiredValidation}
              />

              <div className="form-actions">
                <Button
                  type="button"
                  content="Update account details"
                  onClick={handleToggleModal}
                  as="a"
                  basic
                  className="link-button"
                />
                <ConfirmationModal
                  message="Are you sure you want to update your password?"
                  action={() => onFormSubmit(values)}
                  trigger={
                    <Button
                      type="button"
                      disabled={submitting || hasValidationErrors}
                      content="Update password"
                      primary
                    />
                  }
                />
              </div>
            </form>
          )}
        />
      </Modal.Content>
    </Modal>
  );
};

export default PasswordUpdateModal;
