import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Field, Form, FormSpy } from 'react-final-form';
import { Modal, Header, Button, Icon } from 'semantic-ui-react';
import classNames from 'classnames';

import { FormSelect } from 'components/FinalFormComponents';
import FormRadioGroup from 'components/FinalFormComponents/FormRadioGroup';
import { Agent, Department, Driver } from 'types/Users';
import validateService from 'services/validateService';
import { prepareAgentsForSelect } from 'components/OrientationTable/utilis';

import './ChangeInstructorModal.styles.scss';

type ChangeInstructorModalProps = {
  selectedDriver: Driver;
  setSelectedDriver?: React.Dispatch<React.SetStateAction<Driver>>;
  header?: string;
  agents: Agent[];
  action: (
    driverId: string,
    selectedAgentId: string,
    courseType: string,
  ) => void;
};

type ChangeInstructorFormValues = {
  courseType: Department;
  instructorId: string;
};

type SelectAgentsOptions = {
  key: string;
  value: string;
  text: string;
};

const ChangeInstructorModal: React.FC<ChangeInstructorModalProps> = ({
  header,
  selectedDriver,
  setSelectedDriver,
  agents,
  action,
}) => {
  const [options, setOptions] = useState<
    Record<Department, SelectAgentsOptions[]>
  >();
  const [loading, setLoading] = useState(false);

  const handleOnSubmit = useCallback(
    async (values: ChangeInstructorFormValues) => {
      try {
        setLoading(true);
        await action(selectedDriver.id, values.instructorId, values.courseType);
      } catch (err) {
        console.error(err);
        setLoading(false);
      } finally {
        setSelectedDriver(null);
      }
    },
    [action, selectedDriver.id, setSelectedDriver],
  );

  const formClassNames = useMemo(() => classNames('ui', 'form', { loading }), [
    loading,
  ]);

  const radioButtonsOptions = useMemo(
    () =>
      selectedDriver.courses
        .filter((c) => c.instructorId && c.status === 'On-going')
        .map((course) => ({
          label: course.courseType,
          radioValue: course.courseType,
        })),
    [selectedDriver.courses],
  );

  const optionsForSelectedCourse = useMemo(
    () => (values: ChangeInstructorFormValues) =>
      options[values.courseType].filter(
        (agentOption) =>
          agentOption.value !==
          selectedDriver.courses.find(
            (course) => course.courseType === values.courseType,
          ).instructorId,
      ),
    [options, selectedDriver.courses],
  );

  useEffect(() => {
    setOptions(() => ({
      Eld: prepareAgentsForSelect(agents, 'Eld'),
      Fleet: prepareAgentsForSelect(agents, 'Fleet'),
      Accounting: prepareAgentsForSelect(agents, 'Accounting'),
      Safety: prepareAgentsForSelect(agents, 'Safety'),
      Recruiting: prepareAgentsForSelect(agents, 'Recruiting'),
      'Driver Education': prepareAgentsForSelect(agents, 'Driver Education'),
    }));
  }, [agents]);

  return (
    <Modal
      closeIcon
      onClose={() => setSelectedDriver(null)}
      open={Boolean(selectedDriver)}
    >
      <Header>{header}</Header>
      <Modal.Content>
        <Form
          onSubmit={handleOnSubmit}
          render={({
            handleSubmit,
            submitting,
            dirty,
            values,
            hasValidationErrors,
            form,
          }) => {
            return (
              <form className={formClassNames} onSubmit={handleSubmit}>
                <Field
                  name="courseType"
                  label="Select the course for which you want to change the instructor:"
                  titleClassName="change-instructor-modal__course__label"
                  buttonsClassName={classNames(
                    'change-instructor-modal__course__radio-group',
                    {
                      'change-instructor-modal__course__radio-group--less-than-three':
                        FormRadioGroup.length < 3,
                    },
                  )}
                  component={FormRadioGroup}
                  radioButtons={radioButtonsOptions}
                />

                {values.courseType && (
                  <div className="change-instructor-modal__content">
                    <span>
                      {
                        selectedDriver.courses.find(
                          (course) => course.courseType === values.courseType,
                        ).instructor.fullName
                      }
                    </span>
                    <Icon
                      name="exchange"
                      disabled={true}
                      size="large"
                      className="change-instructor-modal__content__replace-icon"
                    />
                    {options?.[values.courseType].length > 1 ? (
                      <>
                        <Field
                          placeholder="Select instructor"
                          options={optionsForSelectedCourse(values)}
                          component={FormSelect}
                          validate={validateService.required}
                          name="instructorId"
                          clearable
                          className="change-instructor-modal__content__select"
                        />
                        <FormSpy
                          subscription={{ values: true }}
                          onChange={(props) => {
                            const {
                              values: { courseType },
                            } = props;
                            if (values.courseType !== courseType) {
                              form.resetFieldState('instructorId');
                              form.change('instructorId', undefined);
                            }
                          }}
                        />
                      </>
                    ) : (
                      <span>{`There is no agent for the ${values.courseType} department who can replace the current one.`}</span>
                    )}
                  </div>
                )}
                {values.courseType && (
                  <div className="form-actions">
                    <Button
                      color="black"
                      content="Cancel"
                      onClick={() => setSelectedDriver(null)}
                    />
                    <Button
                      type="submit"
                      disabled={
                        submitting ||
                        !dirty ||
                        hasValidationErrors ||
                        Boolean(!values.instructorId)
                      }
                      content="Submit"
                      primary
                    />
                  </div>
                )}
              </form>
            );
          }}
        ></Form>
      </Modal.Content>
    </Modal>
  );
};

export default ChangeInstructorModal;
