import classNames from 'classnames';
import React, { useCallback } from 'react';
import { FieldRenderProps } from 'react-final-form';

import './FormCheckbox.styles.scss';

type Option = {
  label: string;
  value: string;
};

type FormCheckboxProps = {
  option: Option;
  className?: string;
} & FieldRenderProps<string, HTMLElement>;

const FormCheckbox: React.FC<FormCheckboxProps> = ({
  option,
  input: { onChange, value, ...restInput },
  className,
  ...rest
}) => {
  const checkboxClassNames = classNames(className, 'form-checkbox');

  const handleChange = useCallback(() => {
    value ? onChange(undefined) : onChange(option.value);
  }, [onChange, option.value, value]);
  return (
    <div className={checkboxClassNames}>
      <input
        {...rest}
        {...restInput}
        type="checkbox"
        className={`${checkboxClassNames}__input`}
        onChange={handleChange}
      />
      <div className={`${checkboxClassNames}__label`}>{option.label}</div>
    </div>
  );
};
export default FormCheckbox;
