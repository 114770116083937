import enums from 'enums';
import env from 'env';
import bus, { EventName } from 'modules/bus';
import { BUS_EVENTS } from 'modules/bus/busEvents';
import {
  AgentStatusChangedPayload,
  DocuSignChangedPayload,
  DriverStatusChangedPayload,
  EmploymentHistoryStatusChangedPayload,
  NotificationReadPayload,
} from 'modules/bus/busPayloadTypes';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { io, Socket } from 'socket.io-client';
import { State } from 'store/reducers';
import useAuthToken from './useAuthToken';

const useSockets = () => {
  const [socket, setSocket] = useState<Socket>(null);

  const { roles, departments } = useSelector(
    (state: State) => state.currentUser,
  );

  const isAdmin = useMemo(() => roles.includes('Admin'), [roles]);

  const safetyDepartment = useMemo(() => departments.includes('Safety'), [
    departments,
  ]);

  const token = useAuthToken();

  useEffect(() => {
    if (!token) {
      return;
    }
    setSocket(io(env.SERVER_ENDPOINT));
  }, [token]);

  useEffect(() => {
    if (!socket) {
      return;
    }

    const establishConnection = () => {
      socket.emit('auth', { token });
    };

    socket.on('connect', () => {
      if (socket.connected) {
        establishConnection();
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [token, socket]);

  // agent-sockets
  useEffect(() => {
    if (!socket) {
      return;
    }

    const agentStatusChangedListener = (args: any) => {
      toast.info(`${args.text}`);

      bus.broadcastEvent(
        BUS_EVENTS.AGENT_STATUS_CHANGED as EventName,
        args.content as AgentStatusChangedPayload,
      );
    };

    const setupListeners = () => {
      socket.on(
        enums.SOCKET_EVENTS.AGENT_STATUS_CHANGED,
        agentStatusChangedListener,
      );
    };

    const cleanupListeners = () => {
      socket.off(
        enums.SOCKET_EVENTS.AGENT_STATUS_CHANGED,
        agentStatusChangedListener,
      );
    };

    isAdmin && setupListeners();

    return () => {
      isAdmin && cleanupListeners();
    };
  }, [isAdmin, socket]);

  // driver-sockets
  useEffect(() => {
    if (!socket) {
      return;
    }
    const driverIsDrivingChangedListener = (args: any) => {
      toast.info(`${args.text}`);
      bus.broadcastEvent(
        BUS_EVENTS.DRIVER_DRIVING_CHANGED as EventName,
        args.content as DocuSignChangedPayload,
      );
    };

    const driverStatusChangedListener = (args: any) => {
      toast.info(`${args.text}`);

      bus.broadcastEvent(
        BUS_EVENTS.DRIVER_STATUS_CHANGED as EventName,
        args.content as DriverStatusChangedPayload,
      );
    };

    const setupListeners = () => {
      socket.on(
        enums.SOCKET_EVENTS.DRIVER_STATUS_CHANGED,
        driverStatusChangedListener,
      );
      socket.on(
        enums.SOCKET_EVENTS.DRIVER_DRIVING_CHANGED,
        driverIsDrivingChangedListener,
      );
    };

    const cleanupListeners = () => {
      socket.off(
        enums.SOCKET_EVENTS.DRIVER_STATUS_CHANGED,
        driverStatusChangedListener,
      );
      socket.off(
        enums.SOCKET_EVENTS.DRIVER_DRIVING_CHANGED,
        driverIsDrivingChangedListener,
      );
    };

    (isAdmin || safetyDepartment) && setupListeners();

    return () => {
      (isAdmin || safetyDepartment) && cleanupListeners();
    };
  }, [isAdmin, safetyDepartment, socket]);

  // eh-sockets
  useEffect(() => {
    if (!socket) {
      return;
    }

    const employmentHistoryStatusChangedListener = (args: any) => {
      toast.info(`${args.text}`);

      bus.broadcastEvent(
        BUS_EVENTS.EH_STATUS_CHANGED as EventName,
        args.content as EmploymentHistoryStatusChangedPayload,
      );
    };

    const setupListeners = () => {
      socket.on(
        enums.SOCKET_EVENTS.EH_STATUS_CHANGED,
        employmentHistoryStatusChangedListener,
      );
    };

    const cleanupListeners = () => {
      socket.off(
        enums.SOCKET_EVENTS.EH_STATUS_CHANGED,
        employmentHistoryStatusChangedListener,
      );
    };

    (isAdmin || safetyDepartment) && setupListeners();

    return () => {
      (isAdmin || safetyDepartment) && cleanupListeners();
    };
  }, [isAdmin, safetyDepartment, socket]);

  // pdf-sockets
  useEffect(() => {
    if (!socket) {
      return;
    }

    const pdfSignedListener = (args: any) => {
      toast.info(`${args.text}`);
    };

    const pdfCreationErrorListener = (args: any) => {
      toast.info(`${args.text}`);
    };

    const setupListeners = () => {
      socket.on(enums.SOCKET_EVENTS.PDF_SIGNED, pdfSignedListener);
      socket.on(
        enums.SOCKET_EVENTS.PDF_CREATION_ERROR,
        pdfCreationErrorListener,
      );
    };

    const cleanupListeners = () => {
      socket.off(enums.SOCKET_EVENTS.PDF_SIGNED, pdfSignedListener);
      socket.off(
        enums.SOCKET_EVENTS.PDF_CREATION_ERROR,
        pdfCreationErrorListener,
      );
    };

    (isAdmin || safetyDepartment) && setupListeners();

    return () => {
      (isAdmin || safetyDepartment) && cleanupListeners();
    };
  }, [isAdmin, safetyDepartment, socket]);

  // notification-sockets
  useEffect(() => {
    if (!socket) {
      return;
    }

    const newNotificationListener = (args: any) => {
      bus.broadcastEvent(BUS_EVENTS.NOTIFICATION_CREATED as EventName, args);
    };

    const readNotificationListener = (args: any) => {
      const { id, agent, seenBy } = args;

      const payload = { id, agent, seenBy };

      bus.broadcastEvent(
        BUS_EVENTS.NOTIFICATION_READ as EventName,
        payload as NotificationReadPayload,
      );
    };

    const setupListeners = () => {
      socket.on(
        enums.SOCKET_EVENTS.NOTIFICATION_CREATED,
        newNotificationListener,
      );

      socket.on(
        enums.SOCKET_EVENTS.NOTIFICATION_READ,
        readNotificationListener,
      );
    };

    const cleanupListeners = () => {
      socket.off(
        enums.SOCKET_EVENTS.NOTIFICATION_CREATED,
        newNotificationListener,
      );

      socket.off(
        enums.SOCKET_EVENTS.NOTIFICATION_READ,
        readNotificationListener,
      );
    };

    setupListeners();

    return () => {
      cleanupListeners();
    };
  }, [socket]);

  // instructors-reviewd
  useEffect(() => {
    if (!socket) {
      return;
    }

    const instructorReviewedListener = (args: any) => {
      toast.info(`${args.text}`);
      bus.broadcastEvent(
        BUS_EVENTS.INSTRUCTORS_REVIEWED as EventName,
        args.content,
      );
    };

    const setupListeners = () => {
      socket.on(
        enums.SOCKET_EVENTS.INSTRUCTORS_REVIEWED,
        instructorReviewedListener,
      );
    };

    const cleanupListeners = () => {
      socket.off(
        enums.SOCKET_EVENTS.INSTRUCTORS_REVIEWED,
        instructorReviewedListener,
      );
    };

    isAdmin && setupListeners();

    return () => {
      cleanupListeners();
    };
  }, [isAdmin, socket]);

  // course-status-changed
  useEffect(() => {
    if (!socket) {
      return;
    }

    const courseStatusChangeListener = (args: any) => {
      toast.info(`${args.text}`);
      bus.broadcastEvent(
        BUS_EVENTS.COURSE_STATUS_CHANGED as EventName,
        args.content,
      );
    };

    const setupListeners = () => {
      socket.on(
        enums.SOCKET_EVENTS.COURSE_STATUS_CHANGED,
        courseStatusChangeListener,
      );
    };

    const cleanupListeners = () => {
      socket.off(
        enums.SOCKET_EVENTS.COURSE_STATUS_CHANGED,
        courseStatusChangeListener,
      );
    };

    setupListeners();

    return () => {
      cleanupListeners();
    };
  }, [socket]);
};

export default useSockets;
