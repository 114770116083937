import { apiDrivers } from 'api';
import React, { useCallback, useState } from 'react';
import { Form, Field } from 'react-final-form';
import { toast } from 'react-toastify';
import { Button, Dimmer, Loader, Modal, ModalContent } from 'semantic-ui-react';
import validateService from 'services/validateService';
import { Applicant } from 'types/Users';
import { FormInput } from '../FinalFormComponents';

export type EditEmailModalProps = {
  applicantId?: string;
  trigger?: React.ReactNode;
  applicant: Applicant;
  setApplicants: React.Dispatch<React.SetStateAction<Applicant[]>>;
};

type Erorr = {
  email?: string;
};

const EditEmailModal: React.FC<EditEmailModalProps> = ({
  trigger,
  applicantId,
  applicant,
  setApplicants,
}) => {
  const [open, setOpen] = useState<boolean>(false);

  const onFormSubmit = useCallback(
    async ({ email }: { email: string }) => {
      try {
        const { data } = await apiDrivers.updateApplicant(
          applicantId,
          applicant.companyId,
          email,
        );

        setApplicants((oldVal: Applicant[]) =>
          oldVal.map((driver) =>
            driver?.id === data?.driver.id
              ? { ...driver, email: data?.driver.email }
              : driver,
          ),
        );
        toast.success(
          `Email of "${applicant.applicantName}" is successfully changed.`,
        );
      } catch (err) {
        if (err.response.status === 409) {
          toast.error(err?.response.data.error);
        } else {
          toast.error('Something went wrong. Please try again.');
        }
        console.error(err);
      } finally {
        setOpen(false);
      }
    },
    [applicant.applicantName, applicant.companyId, applicantId, setApplicants],
  );

  return (
    <Form
      onSubmit={onFormSubmit}
      validate={(values) => {
        const errors: Erorr = {};
        const { email } = values;
        if (email && email === applicant.email)
          errors.email =
            'You have entered an email that is the same as the current one. Please enter new one.';
        return errors;
      }}
      render={({ handleSubmit, submitting, hasValidationErrors }) => (
        <Modal
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          open={open}
          trigger={trigger}
          className="modal"
          closeIcon
        >
          <Modal.Header>Email editing</Modal.Header>
          <ModalContent>
            <form onSubmit={handleSubmit} className="ui form">
              {submitting && (
                <Dimmer inverted active>
                  <Loader />
                </Dimmer>
              )}
              <Field
                name="email"
                label="Email"
                placeholder={`Enter new email e.g. "johndoe@example.com"`}
                required
                component={FormInput}
                validate={validateService.mailValidation}
              />
              <div className="form-actions">
                <Modal.Actions>
                  <Button
                    color="black"
                    content="Cancel"
                    onClick={() => setOpen(false)}
                  />
                  <Button
                    content="Submit"
                    type="submit"
                    primary
                    disabled={hasValidationErrors}
                  />
                </Modal.Actions>
              </div>
            </form>
          </ModalContent>
        </Modal>
      )}
    />
  );
};

export default EditEmailModal;
