import Agents from '../Agents.page';
import enums from '../../../enums';
import { toast } from 'react-toastify';
import { apiAgents } from 'api';

type AgentStatusType = keyof typeof enums.AGENT_STATUS;

export const statusFilterOptions = Object.values(enums.AGENT_STATUS).map(
  (value) => {
    return { value: value, key: value, text: value };
  },
);

export const handleAgentActivation = async (id: string, value: boolean) => {
  try {
    await apiAgents.setIsActive(id, value);
  } catch (err) {
    console.error(err);
    toast.error(enums.ACTION_MESSAGES.ERROR_MESSAGE.AGENT_UPDATE);
  }
};
export const getAgentStatus = (value: boolean): AgentStatusType => {
  if (value) {
    return 'Active';
  }
  return 'Inactive';
};

export const setIsActiveFilter = (
  value: string,
  oldVal: Record<string, any>,
) => {
  switch (value) {
    case enums.AGENT_STATUS.Active:
      return { ...oldVal, skip: 0, isActive: true };
    case enums.AGENT_STATUS.Inactive:
      return { ...oldVal, skip: 0, isActive: false };
    default:
      return { ...oldVal, skip: 0, isActive: null };
  }
};

export default Agents;
