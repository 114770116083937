import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { Button, Modal, Header } from 'semantic-ui-react';

import { Course, CourseStatus, Driver } from 'types/Users';
import { Field, Form } from 'react-final-form';
import FormCheckbox from 'components/FinalFormComponents/FormCheckbox';
import { getCoursesWithGivenStatus } from 'components/FinishCoursesModal/utilits';
import classNames from 'classnames';

type CoursesModalProps = {
  selectedDriver: Partial<Driver>;
  coursesStatus: CourseStatus;
  setSelectedDriver: React.Dispatch<React.SetStateAction<Driver>>;
  header: string;
  action: (driver: Driver, selectedCourses: Course[]) => void;
};

const SendReviewMailsModal: React.FC<CoursesModalProps> = ({
  setSelectedDriver,
  selectedDriver,
  header,
  coursesStatus,
  action,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [courses, setCourses] = useState<
    Record<
      string,
      {
        label: string;
        courses: Course[];
      }
    >
  >();

  const formClassNames = useMemo(
    () => classNames('ui', 'form', { loading: loading }),
    [loading],
  );

  const onFormSubmit = useCallback(
    async (values: Record<string, Course[]>) => {
      setLoading(true);

      try {
        await action(selectedDriver as Driver, Object.values(values).flat());
        setSelectedDriver(null);
      } finally {
        setLoading(false);
      }
    },
    [action, selectedDriver, setSelectedDriver],
  );

  useEffect(() => {
    const getCourses = (): Record<
      string,
      {
        label: string;
        courses: Course[];
      }
    > => {
      if (selectedDriver !== null) {
        const notReviewedCourses = getCoursesWithGivenStatus(
          selectedDriver.courses,
          coursesStatus,
        );

        const courses = notReviewedCourses.reduce(
          (acc, course) => ({
            ...acc,
            [course.instructorId]: acc[course.instructorId]
              ? {
                  ...acc[course.instructorId],
                  label: acc[course.instructorId].label.concat(
                    `, ${course.courseType}`,
                  ),
                  courses: [...acc[course.instructorId].courses, course],
                }
              : {
                  label: `${course.instructor.fullName} (${course.courseType}`,
                  courses: [course],
                },
          }),
          {} as Record<string, { label: string; courses: Course[] }>,
        );

        return courses;
      }
      return {};
    };

    setCourses(getCourses());
  }, [coursesStatus, selectedDriver]);

  return (
    <Modal
      closeIcon
      onClose={() => setSelectedDriver(null)}
      open={selectedDriver !== null}
    >
      <Header>{header}</Header>
      <Modal.Content>
        <Form
          onSubmit={onFormSubmit}
          render={({ handleSubmit, pristine, submitting }) => (
            <form onSubmit={handleSubmit} className={formClassNames}>
              {courses &&
                Object.entries(courses)?.map(([key, value]) => {
                  return (
                    <Field
                      key={key}
                      component={FormCheckbox}
                      option={{
                        label: `${value.label})`,
                        value: value.courses,
                      }}
                      name={key}
                    />
                  );
                })}
              <div className="form-actions">
                <Button
                  type="submit"
                  disabled={submitting || pristine}
                  content="Submit"
                  primary
                />
              </div>
            </form>
          )}
        />
      </Modal.Content>
    </Modal>
  );
};

export default SendReviewMailsModal;
