import { toast } from 'react-toastify';
import enums from '../../../enums';
import { ApplicantStatusType } from 'types/Users';
import { apiCompanies, apiDrivers } from 'api';

const { ACTION_MESSAGES, APPLICANT_STATUS } = enums;
const { sendToSign, getMagicLink, moveToDrivers } = apiDrivers;
const { getCompanies } = apiCompanies;

export const defaultFilterValues = Object.values(enums.APPLICANT_STATUS);

export const statusFilterOptions = Object.values(APPLICANT_STATUS).map(
  (value) => {
    return { value: value, key: value, text: value };
  },
);
export const TABLE_HEADER = 'Applicants List';

export const copyMagicLink = async (id: string) => {
  try {
    const { data } = await getMagicLink(id);
    navigator.clipboard.writeText(data.url);
    toast.success(ACTION_MESSAGES.SUCCESS_MESSAGE.COPY);
  } catch (err) {
    toast.error(ACTION_MESSAGES.ERROR_MESSAGE.COPY);
    console.error(err);
  }
};

export const sendToSignHandle = async (
  id: string,
  redirectUrl: string,
  values = {},
) => {
  try {
    await sendToSign(id, redirectUrl, values);
  } catch (err) {
    toast.error(ACTION_MESSAGES.ERROR_MESSAGE.SEND_TO_SIGN);
    console.error(err);
  }
};

export const moveToDriversHandle = async (id: string) => {
  try {
    await moveToDrivers(id);
  } catch (err) {
    toast.error(ACTION_MESSAGES.ERROR_MESSAGE.MOVE_TO_DRIVERS);
    console.error(err);
  }
};

export const getCompanySelectOptions = async () => {
  try {
    const { data } = await getCompanies();
    return data.items.map((item: any) => {
      return {
        key: item._id,
        text: item.name,
        value: item._id,
      };
    });
  } catch (err) {
    console.error(err);
  }
};

export const isEditApplicantDisabled = (status: ApplicantStatusType) => {
  return (
    status === APPLICANT_STATUS['Pending'] || status === APPLICANT_STATUS['New']
  );
};
