export const LINK_CREATED = 'Link created';
export const LINK_CREATED_LINK = 'Link';

export const copyLink = (
  link: string,
  setCopiedToClipboard: (value: boolean) => void,
) => {
  navigator.clipboard.writeText(link);
  setCopiedToClipboard(true);
  setTimeout(() => {
    setCopiedToClipboard(false);
  }, 2500);
};
