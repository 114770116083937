import useQueryParams from 'hooks/useQueryParams';
import React, { useCallback } from 'react';
import { Dropdown, Icon, Menu } from 'semantic-ui-react';
import { v4 as uuid } from 'uuid';
import './TableNavigation.styles.scss';
import { limitOptions } from './utils';

type TableNavigationProps = {
  pagination: any;
};

const TableNavigation: React.FC<TableNavigationProps> = ({ pagination }) => {
  const { totalPages } = pagination;
  const {
    params: { page, limit },
    setQueryParam,
    setMultipleQueryParams,
  } = useQueryParams<{ limit: string; page: string }>();

  const currentLimit = limit ? parseInt(limit) : 10;
  const currentPage =
    !isNaN(Number(page)) || Number(page) === 0 ? Number(page) : 1;

  const getMenuItems = useCallback(
    (count: number, startingItem: number): JSX.Element[] => {
      const items: JSX.Element[] = [];

      for (let i = 0; i < count; i++) {
        const itemNumber = startingItem + i;

        items.push(
          <Menu.Item
            as="a"
            onClick={() => setQueryParam('page', itemNumber)}
            active={itemNumber === currentPage}
            key={itemNumber}
          >
            {itemNumber}
          </Menu.Item>,
        );
      }

      return items;
    },
    [currentPage, setQueryParam],
  );

  const dynamicMenuItemPart = useCallback(() => {
    const items: JSX.Element[] = [];
    if (totalPages <= 2) {
      return;
    }
    if (totalPages < 4) {
      items.push(...getMenuItems(1, 2));
      return items;
    }
    if (currentPage >= 3) {
      items.push(
        <Menu.Item as="a" disabled key={uuid()}>
          ...
        </Menu.Item>,
      );
    }
    if (currentPage === 1) {
      items.push(...getMenuItems(2, 2));
    } else {
      if (totalPages - currentPage >= 3) {
        items.push(...getMenuItems(2, currentPage));
      }
    }
    if (totalPages - currentPage < 3) {
      items.push(...getMenuItems(2, totalPages - 2));
    } else {
      items.push(
        <Menu.Item as="a" disabled key={uuid()}>
          ...
        </Menu.Item>,
      );
    }

    return items;
  }, [currentPage, getMenuItems, totalPages]);

  return (
    <div className="menu-container">
      <Dropdown
        placeholder="Aplicants shown"
        selection
        options={limitOptions}
        value={currentLimit.toString()}
        onChange={(_, data) =>
          setMultipleQueryParams({
            limit: data.value! as string,
            page: 1,
          })
        }
      />
      <Menu className="right-menu" position="right" pagination>
        <Menu.Item
          as="a"
          disabled={currentPage === 1}
          icon
          onClick={() => setQueryParam('page', currentPage - 1)}
        >
          <Icon name="chevron left" />
        </Menu.Item>

        <Menu.Item
          as="a"
          onClick={() => setQueryParam('page', 1)}
          active={currentPage === 1}
        >
          1
        </Menu.Item>

        {dynamicMenuItemPart()}
        {totalPages > 1 && (
          <Menu.Item
            as="a"
            onClick={() => setQueryParam('page', totalPages)}
            active={currentPage === totalPages}
          >
            {totalPages}
          </Menu.Item>
        )}

        <Menu.Item
          as="a"
          icon
          onClick={() => setQueryParam('page', currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          <Icon name="chevron right" />
        </Menu.Item>
      </Menu>
    </div>
  );
};

export default TableNavigation;
