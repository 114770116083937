import { Form } from 'semantic-ui-react';
import { FieldRenderProps } from 'react-final-form';
import React, { useMemo, useState } from 'react';
import './FormSelect.styles.scss';
import { Label } from '..';
import classNames from 'classnames';
import useEffectSkipFirst from 'hooks/useEffectSkipFirst';

export type FormSelectOption = {
  key: string;
  text: string;
  value: string;
};
type FormSelectProps = {
  label?: string;
  required?: boolean;
  options?: FormSelectOption[];
  className?: string;
  multipleSelect?: boolean;
} & FieldRenderProps<string, HTMLElement>;
const FormSelect: React.FC<FormSelectProps> = ({
  input,
  label,
  required,
  options = [],
  meta,
  className,
  multipleSelect = false,
  ...rest
}) => {
  const [selectedOption, setSelectedOption] = useState('');

  const { onChange, onBlur, onFocus } = input;
  const error = useMemo(() => (meta.touched ? meta.error : null), [meta]);
  const selectClassNames = classNames(className, 'select-container');

  useEffectSkipFirst(() => {
    if (!input.value) setSelectedOption('');
  }, [input.value]);

  return (
    <div className={selectClassNames}>
      {label && <Label label={label} error={error} required={required} />}
      <Form.Dropdown
        options={options}
        selection
        error={!!error}
        fluid
        onChange={(_, data) => {
          onChange(data.value);
          setSelectedOption(data.value as string);
        }}
        onBlur={() => onBlur()}
        onFocus={() => onFocus()}
        selectOnBlur={false}
        multiple={multipleSelect}
        {...(!multipleSelect && { value: selectedOption })}
        {...rest}
      />
    </div>
  );
};

export default FormSelect;
