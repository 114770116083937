export const TERMINATE_CONFIRMATION_HEADER = {
  message: 'Termination of Employment',
  editMessage: 'Termination of Employment',
};
export const TERMINATE_CONFIRMATION_MESSAGE_HEADER = {
  message: 'Firing ',
  editMessage: 'Edit Termination-of-Employment Document of ',
};
export const TERMINATE_CONFIRMATION_MESSAGE = {
  message:
    "Please note that filling in this document will update driver's status to Terminated. Once filled in you'll still have the option to override the data entered by filling it in as many times as you like. Only the last version of the document will be saved.",
  editMessage:
    "Please note that filling in this document will update driver's Termination-of-Employment document. Once filled in you'll still have the option to override the data entered by filling it in as many times as you like. Only the last version of the document will be saved.",
};
export const TERMINATE_CONFIRMATION_ACTION_MESSAGES = {
  succes: {
    message: 'Succesfully sent Termination-of-Employment request',
    editMessage: 'Succesfully edited  Termination-of-Employment document',
  },
  error: {
    message:
      "Error occured. Request for Termination-of-Employment wasn't sent.",
    editMessage:
      "Error occured. Request for editing Termination-of-Employment document wasn't sent.",
  },
};
