import React, { useEffect, useRef, useState } from 'react';
import './Activity.styles.scss';
import TableNavigation from 'components/TableNavigation';
import useFilterParams from 'hooks/useFilterParams';
import NotificationCard from 'components/NotificationCard';
import useElementScrollTo from 'hooks/useElementScrollTo';
import { BUS_EVENTS } from 'modules/bus/busEvents';
import bus, { EventName } from 'modules/bus';
import { Button } from 'semantic-ui-react';
import { apiNotifications } from 'api';

const Activity = () => {
  const {
    skip,
    pagination,
    items: notifications,
    setItems: setNotifications,
    loading,
    currentPage,
    fetchItems,
    setQueryParam,
  } = useFilterParams({
    fallbackRoute: '/activity-page',
    requestFunc: apiNotifications.loadNotifications,
  });

  const [numberOfNewNotification, setNumberOfNewNotifications] = useState(0);

  const titleRef = useRef(null);
  useElementScrollTo(titleRef, skip);

  useEffect(() => {
    const newNotification = (args: any) => {
      setNumberOfNewNotifications((count) => count + 1);
    };

    const notificationRead = (args: any) => {
      const { payload } = args;

      setNotifications((oldVal) =>
        oldVal.map((notification) =>
          notification.id === payload.id
            ? { ...notification, agent: payload.agent, seenBy: payload.seenBy }
            : notification,
        ),
      );
    };

    bus.addEventListener(
      BUS_EVENTS.NOTIFICATION_CREATED as EventName,
      newNotification,
    );

    bus.addEventListener(
      BUS_EVENTS.NOTIFICATION_READ as EventName,
      notificationRead,
    );

    return () => {
      bus.removeEventListener(
        BUS_EVENTS.NOTIFICATION_CREATED as EventName,
        newNotification,
      );

      bus.removeEventListener(
        BUS_EVENTS.NOTIFICATION_READ as EventName,
        notificationRead,
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSetNotifications = (newNotification: any) => {
    setNotifications((oldValue) => {
      return oldValue.map((oldVal) =>
        oldVal._id !== newNotification._id ? oldVal : newNotification,
      );
    });
  };

  const handleFetchNewNotifications = () => {
    currentPage === 1 ? fetchItems() : setQueryParam('page', 1);
    setNumberOfNewNotifications(0);
  };

  return (
    <div ref={titleRef} className="activity">
      <div className="activity__container">
        <div className="activity__container__header">
          <h2>Notifications</h2>
          {numberOfNewNotification > 0 && (
            <Button
              onClick={handleFetchNewNotifications}
              type="button"
              basic
              color="green"
            >
              Load new ({numberOfNewNotification})
            </Button>
          )}
        </div>
        <div className="activity__container__notifications">
          {!loading &&
            notifications.map((notification) => {
              return (
                <div key={notification._id}>
                  <NotificationCard
                    notification={notification}
                    handleSetNotifications={handleSetNotifications}
                  />
                </div>
              );
            })}
        </div>
        <div className="activity__container__pagination">
          <TableNavigation pagination={pagination} />
        </div>
      </div>
    </div>
  );
};

export default Activity;
