import React from 'react';

type BadgeProps = {
  className?: string;
  onClick?: any;
};
const BadgeIcon: React.FC<BadgeProps> = ({ className, onClick }) => {
  return (
    <svg
      className={className}
      onClick={onClick}
      enableBackground="new 0 0 512 512"
      height="512"
      viewBox="0 0 512 512"
      width="512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path d="m289 227.878 48.53 7.05-35.12 34.23c-3.53 3.45-5.15 8.41-4.31 13.28l8.29 48.34-43.41-22.83c-4.402-2.291-9.551-2.295-13.96 0l-43.41 22.83 8.29-48.34c.84-4.87-.78-9.83-4.31-13.28l-35.12-34.23 48.53-7.05c4.89-.71 9.11-3.78 11.3-8.21l21.7-43.97 21.7 43.97c2.19 4.43 6.41 7.5 11.3 8.21z" />
        <path d="m492 114.498c-.12-8.6-7.49-15.4-16.18-14.73-56.5 4.45-108.46-9.72-154.4-42.13-34.71-24.49-52.82-50.79-52.99-51.03-5.92-8.8-18.92-8.82-24.86 0-.71 1.04-71.71 103.85-207.39 93.16-8.62-.69-16.05 6.07-16.18 14.73-.04 2.93-.77 72.76 26.8 156.611 38.52 117.18 110.55 196.391 202.85 239.481 4.19 1.94 8.79 1.82 12.7 0 92.3-43.09 164.34-122.33 202.85-239.481 27.57-83.851 26.84-153.681 26.8-156.611zm-111.77 120.7-51.23 49.941 12.09 70.52c2.108 12.236-10.794 21.592-21.76 15.81l-63.33-33.29-63.33 33.29c-10.986 5.775-23.869-3.566-21.76-15.81l12.09-70.52-51.23-49.94c-8.881-8.664-3.985-23.806 8.31-25.59l70.8-10.28 31.67-64.16c5.492-11.135 21.401-11.149 26.9 0l31.67 64.16 70.8 10.28c12.279 1.781 17.203 16.914 8.31 25.589z" />
      </g>
    </svg>
  );
};

export default BadgeIcon;
