import React from 'react';
import OrientationTable from 'components/OrientationTable';

import './Orientation.styles.scss';

const Orientation = () => {
  return (
    <div className="orientation">
      <h2>Orientation list</h2>
      <OrientationTable />
    </div>
  );
};
export default Orientation;
