import { apiDrivers } from 'api';
import ApplicantHeader from 'components/ApplicantHeader';
import enums from 'enums';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Loader, Table, TableCell, TableHeaderCell } from 'semantic-ui-react';
import { formatedDate } from 'services/dateService';
import { Driver, DriverStatusHistoryType } from 'types/Users';

import './DriverStatusHistory.styles.scss';

const { DRIVER_HISTORY_STATUS_COLORS } = enums;

const DriverStatusHistory: React.FC = () => {
  const [driver, setDriver] = useState<Driver>();
  const [statusHistory, setStatusHistory] = useState<DriverStatusHistoryType[]>(
    [],
  );

  const { id: driverID } = useParams<{ id: string }>();

  useEffect(() => {
    const loadDriver = async () => {
      const {
        data: { driver },
      } = await apiDrivers.getDriver(driverID);

      setStatusHistory(driver.statusHistory);
      setDriver(driver);
    };

    loadDriver();
  }, [driverID]);

  if (!driver) {
    return <Loader active>Loading...</Loader>;
  }
  return (
    <div className="driver-status-history-page">
      <ApplicantHeader
        applicantName={driver.applicantName}
        status={driver.status}
      />

      {driver && (
        <Table celled>
          <Table.Header>
            <Table.Row>
              <TableHeaderCell>Creation date</TableHeaderCell>
              <TableHeaderCell>Update date</TableHeaderCell>
              <TableHeaderCell>Status</TableHeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {statusHistory.map(({ createdAt, updatedAt, status }, index) => (
              <Table.Row key={index}>
                <Table.Cell>{formatedDate(createdAt)}</Table.Cell>
                <Table.Cell>{formatedDate(updatedAt)}</Table.Cell>
                <TableCell
                  className={`${DRIVER_HISTORY_STATUS_COLORS[status]} status-label`}
                >
                  {status}
                </TableCell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      )}
    </div>
  );
};

export default DriverStatusHistory;
