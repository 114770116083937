import classNames from 'classnames';
import { MagicLinkForm } from 'components/MagicLinkForm';
import TableNavigation from 'components/TableNavigation';
import Tippy from 'components/Tippy';
import useFilterParams from 'hooks/useFilterParams';
import bus, { EventName } from 'modules/bus';
import { BUS_EVENTS } from 'modules/bus/busEvents';
import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Dropdown, Input, Radio, Table } from 'semantic-ui-react';
import ConfirmationModal from '../../components/ConfirmationModal';
import RequestEditModal from '../../components/RequestEditModal';
import enums from '../../enums';
import { Applicant, ApplicantStatusType } from '../../types/Users';
import './Applicants.styles.scss';
import {
  copyMagicLink,
  defaultFilterValues,
  isEditApplicantDisabled,
  moveToDriversHandle,
  statusFilterOptions,
  TABLE_HEADER,
} from './utils';
import useElementScrollTo from 'hooks/useElementScrollTo';
import { apiDrivers } from 'api';
import EditEmailModal from 'components/EditEmailModal';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { State } from 'store/reducers';
import { DELETED_APPLICANTS } from 'store/actions/actionTypes';
import { formatedDate } from 'services/dateService';
import drivers from 'api/drivers';

const {
  APPLICANT_STATUS_COLORS,
  ACTION_MESSAGES,
  APPLICANT_STATUS,
  DRIVER_STATUS,
} = enums;

const Applicants: React.FC = () => {
  const {
    skip,
    pagination,
    loading,
    searchValue,
    isEnabledDeletedApplicants,
    items: applicants,
    setItems: setApplicants,
    setStatus,
    setSearchValue,
    setIsEnabledDeletedApplicants,
    setSort,
  } = useFilterParams({
    fallbackRoute: '/applicants',
    requestFunc: apiDrivers.loadApplicants,
    defaultStatusFilter: defaultFilterValues,
  });
  const [sortByDateColumn, setSortByDateColumn] = useState<{
    direction: 'ascending' | 'descending';
    name: string;
  }>({ direction: 'ascending', name: '' });

  const { roles } = useSelector((state: State) => state.currentUser);

  const [openMagicLinkForm, setOpenMagicLinkForm] = useState<boolean>(false);

  const isAdmin = useMemo(() => roles.includes('Admin'), [roles]);

  const dispatch = useDispatch();

  useEffect(() => {
    const applicantStatusChanged = (args: any) => {
      const { payload } = args;

      setApplicants((oldVal) => {
        const result = oldVal.map((applicant) => {
          if (applicant.id === payload.driverId) {
            if (payload.status !== DRIVER_STATUS.Verified) {
              return { ...applicant, status: payload.status };
            } else {
              return undefined;
            }
          } else {
            return applicant;
          }
        });

        return result.filter((value) => value);
      });
    };

    bus.addEventListener(
      BUS_EVENTS.DRIVER_STATUS_CHANGED as EventName,
      applicantStatusChanged,
    );

    return () => {
      bus.removeEventListener(
        BUS_EVENTS.DRIVER_STATUS_CHANGED as EventName,
        applicantStatusChanged,
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const headerRef = useRef();
  useElementScrollTo(headerRef, skip);

  const tableClassNames = useMemo(
    () =>
      classNames('applicants__table', {
        'applicants__table--loading': loading,
      }),
    [loading],
  );

  const applicantsClassNames = useMemo(
    () =>
      classNames('applicants', {
        'applicants--admin': isAdmin,
      }),
    [isAdmin],
  );

  const isDisabledEmploymentHistory = useCallback(
    (status: ApplicantStatusType) =>
      APPLICANT_STATUS.Pending === status ||
      APPLICANT_STATUS.New === status ||
      APPLICANT_STATUS['Changes requested'] === status,
    [],
  );

  const handleApplicantCreation = (newDriver: Applicant) => {
    setApplicants((prev) => [newDriver, ...prev]);
  };

  const handleApplicantDeletion = useCallback(
    async (id: string, applicantName: string) => {
      try {
        isEnabledDeletedApplicants
          ? await apiDrivers.permanentDeleteDriver(id)
          : await apiDrivers.softDeleteDriver(id);

        setApplicants((oldVal) =>
          oldVal.filter((applicant) => applicant.id !== id),
        );
        toast.success(`"${applicantName}" is successfully deleted.`);
      } catch (err) {
        console.error(err);
      }
    },
    [isEnabledDeletedApplicants, setApplicants],
  );

  const handleSortDirection = useCallback(
    (columnName: string) => {
      setSort(
        sortByDateColumn.direction === 'ascending'
          ? `-${columnName}`
          : `${columnName}`,
      );

      setSortByDateColumn({
        direction:
          sortByDateColumn?.direction === 'ascending'
            ? 'descending'
            : 'ascending',
        name: columnName,
      });
    },
    [setSort, sortByDateColumn.direction],
  );

  return (
    <div ref={headerRef} className={applicantsClassNames}>
      <div className="applicants__top">
        <Input
          label="Search applicants"
          icon="search"
          value={searchValue}
          onChange={({ target: { value } }) => setSearchValue(value)}
        />
        {isAdmin && (
          <Radio
            toggle
            label="Deleted applicants"
            checked={isEnabledDeletedApplicants}
            onChange={() => {
              setIsEnabledDeletedApplicants((oldVal) => !oldVal);
              dispatch({
                type: DELETED_APPLICANTS,
                payload: !isEnabledDeletedApplicants,
              });
            }}
          />
        )}
        <div className="applicants__top__right-menu">
          <Dropdown
            placeholder="Select status filters"
            multiple
            selection
            options={statusFilterOptions}
            float="right"
            onChange={(_, data) => {
              const newStatusData = data.value as string[];

              setStatus(
                newStatusData.length ? newStatusData : defaultFilterValues,
              );
            }}
          />
          <Button
            content="Create magic link"
            onClick={() => setOpenMagicLinkForm(true)}
          />
          {openMagicLinkForm && (
            <MagicLinkForm
              isAdmin={isAdmin}
              onApplicantCreate={handleApplicantCreation}
              open={openMagicLinkForm}
              setOpen={setOpenMagicLinkForm}
            />
          )}
        </div>
      </div>

      <h2>{TABLE_HEADER}</h2>

      <Table sortable celled className={tableClassNames}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Email</Table.HeaderCell>
            <Table.HeaderCell
              sorted={
                sortByDateColumn.name === 'createdAt'
                  ? sortByDateColumn.direction
                  : null
              }
              onClick={() => handleSortDirection('createdAt')}
            >
              Created At
            </Table.HeaderCell>
            <Table.HeaderCell>Id</Table.HeaderCell>
            <Table.HeaderCell>Company</Table.HeaderCell>
            <Table.HeaderCell>Application Creator</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {applicants.map(
            (
              {
                applicantName,
                email,
                companyInfo,
                status,
                pdfUrl,
                signedApplicationUrl,
                applicationCreator,
                id,
                createdAt,
              },
              index,
            ) => {
              return (
                <Table.Row key={id}>
                  <Table.Cell>{applicantName || 'applicant'}</Table.Cell>
                  <Table.Cell>
                    <div className="email-cell">
                      <span>{email}</span>
                      <Tippy title="Edit email">
                        <EditEmailModal
                          applicantId={id}
                          applicant={applicants[index]}
                          setApplicants={setApplicants}
                          trigger={
                            <Button
                              type="button"
                              icon="pencil alternate"
                              disabled={isEnabledDeletedApplicants}
                            />
                          }
                        />
                      </Tippy>
                    </div>
                  </Table.Cell>
                  <Table.Cell>{formatedDate(createdAt)} </Table.Cell>
                  <Table.Cell>{id}</Table.Cell>
                  <Table.Cell>{companyInfo?.displayName}</Table.Cell>
                  <Table.Cell>
                    {isAdmin ? (
                      <NavLink to={`/agents/${applicationCreator?.id}`}>
                        {applicationCreator?.fullName}
                      </NavLink>
                    ) : (
                      applicationCreator?.fullName
                    )}
                  </Table.Cell>

                  <Table.Cell>
                    <label
                      className={`${APPLICANT_STATUS_COLORS[status]} status-label`}
                    >
                      {status}
                    </label>
                  </Table.Cell>
                  <Table.Cell>
                    <div className="button-group">
                      <Tippy title="Applicant details">
                        <NavLink
                          {...(isEditApplicantDisabled(status) && {
                            onClick: (ev) => ev.preventDefault(),
                            className: 'applicant-details-not-allowed',
                          })}
                          to={`/applicants/${id}`}
                        >
                          <Button
                            icon="edit"
                            disabled={isEditApplicantDisabled(status)}
                          />
                        </NavLink>
                      </Tippy>
                      <Tippy title="Copy magic link">
                        <Button
                          icon="copy"
                          disabled={status === APPLICANT_STATUS.Submitted}
                          onClick={() => copyMagicLink(id)}
                        />
                      </Tippy>
                      <Tippy title="Request form edit">
                        <RequestEditModal
                          header={`Request changes from ${applicantName}`}
                          applicantsId={id}
                          apiRequest={drivers.requestChanges}
                          trigger={
                            <Button
                              icon="edit"
                              disabled={
                                isEnabledDeletedApplicants ||
                                ![
                                  'Sent to sign',
                                  'Submitted',
                                  'Declined to sign',
                                ].includes(status)
                              }
                            />
                          }
                        />
                      </Tippy>
                      <Tippy title="Employment history">
                        <NavLink
                          {...(isDisabledEmploymentHistory(status) && {
                            onClick: (ev) => ev.preventDefault(),
                            className: 'applicant-details-not-allowed',
                          })}
                          to={`/employment-history/${id}`}
                        >
                          <Button
                            icon="send"
                            disabled={isDisabledEmploymentHistory(status)}
                          />
                        </NavLink>
                      </Tippy>
                      <Tippy title="Open pdf">
                        <Button
                          icon="file pdf"
                          as="a"
                          disabled={Boolean(!pdfUrl)}
                          href={pdfUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        />
                      </Tippy>
                      <Tippy title="Move to drivers">
                        <ConfirmationModal
                          header={
                            ACTION_MESSAGES.CONFIRM_MODAL.MOVE_TO_DRIVERS.HEADER
                          }
                          message={
                            ACTION_MESSAGES.CONFIRM_MODAL.MOVE_TO_DRIVERS
                              .MESSAGE
                          }
                          action={() => moveToDriversHandle(id)}
                          messageHeader={'Applicant: ' + applicantName}
                          trigger={
                            <Button
                              icon="send"
                              disabled={status !== APPLICANT_STATUS.Submitted}
                            />
                          }
                        />
                      </Tippy>
                      {isAdmin && (
                        <Tippy
                          title={
                            isEnabledDeletedApplicants
                              ? 'Permanent applicant deletion'
                              : 'Delete applicant'
                          }
                        >
                          <ConfirmationModal
                            header={
                              ACTION_MESSAGES.CONFIRM_MODAL.DELETION
                                .HEADER_APPLICANT
                            }
                            message={
                              isEnabledDeletedApplicants
                                ? `Are you sure you want to delete applicant "${applicantName}" permanently?`
                                : `Are you sure you want to delete applicant "${applicantName}"?`
                            }
                            action={() =>
                              handleApplicantDeletion(id, applicantName)
                            }
                            color="red"
                            buttonContent="Delete"
                            buttonIcon="trash"
                            trigger={
                              <Button
                                color="red"
                                icon={
                                  isEnabledDeletedApplicants
                                    ? 'close'
                                    : 'trash alternate'
                                }
                              />
                            }
                            isDeleteModal
                          />
                        </Tippy>
                      )}
                    </div>
                  </Table.Cell>
                </Table.Row>
              );
            },
          )}
        </Table.Body>
      </Table>
      <TableNavigation pagination={pagination} />
    </div>
  );
};
export default Applicants;
