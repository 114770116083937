import { UNCHECKED_DRIVERS } from 'store/actions/actionTypes';

const uncheckedDriverReducer = (state = false, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case UNCHECKED_DRIVERS:
      return payload;
    default:
      return state;
  }
};

export default uncheckedDriverReducer;
