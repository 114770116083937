import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router';
import { Applicant, Driver } from 'types/Users';
import './EmploymentHistory.styles.scss';
import { Loader } from 'semantic-ui-react';
import EmploymentHistoryTable from 'components/EmploymentHistoryTable';
import ApplicantHeader from 'components/ApplicantHeader';
import { apiDrivers } from 'api';
import enums from 'enums';

const { APPLICANT_STATUS } = enums;

const EmploymentHistory: React.FC = (props: any) => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  const [driver, setDriver] = useState<Driver | Applicant>();

  useEffect(() => {
    const init = async () => {
      const {
        data: { driver },
      } = await apiDrivers.getDriver(id);
      setDriver(driver);
    };

    init();
  }, [id]);

  if (!driver) {
    return <Loader active />;
  }
  if (
    APPLICANT_STATUS.Pending === driver.status ||
    APPLICANT_STATUS.New === driver.status ||
    APPLICANT_STATUS['Changes requested'] === driver.status
  ) {
    history.push('/');
  }

  return (
    <div className="eh-page">
      <h2>Employment History</h2>

      <ApplicantHeader
        applicantName={driver.applicantName}
        status={driver.status}
        className="eh-page__header"
        id={id}
      />

      <EmploymentHistoryTable driver={driver} />
    </div>
  );
};

export default EmploymentHistory;
