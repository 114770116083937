import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Button,
  Dimmer,
  Header,
  Icon,
  Loader,
  Segment,
} from 'semantic-ui-react';
import { Form, Field } from 'react-final-form';
import { FormInput } from '../../components/FinalFormComponents';
import { toast } from 'react-toastify';

import './ResetPassword.styles.scss';
import validateService from 'services/validateService';
import useQueryParams from 'hooks/useQueryParams';
import history from 'common/history';
import { apiAuth } from 'api';

const ResetPassword: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const {
    params: { code: token },
  } = useQueryParams<{
    code: string;
  }>();

  const onFormSubmit = useCallback(
    async ({ password }: { password: string }) => {
      try {
        setLoading(true);
        await apiAuth.resetPassword(password, token);
        toast.success('Your password has been reset successfully.');
      } catch (err) {
        console.error(err);
        toast.error('Something went wrong. Please contact administrator.');
      } finally {
        setTimeout(() => {
          setLoading(false);
          history.push('/login');
        }, 2000);
      }
    },
    [token],
  );

  useEffect(() => {
    if (!token) history.push('/login');
  }, [token]);

  const validations = useMemo(
    () => ({
      password: validateService.passwordValidation,
      repPassword: validateService.matchPasswordValidation,
    }),
    [],
  );

  return (
    <div className="super-ego-holding-container">
      <Segment padded="very" raised className="section-container">
        <Header as="h1" textAlign="center">
          Reset your password
        </Header>
        {loading && (
          <Dimmer inverted active>
            <Loader>
              Resetting your password. Redirecting to login page...
            </Loader>
          </Dimmer>
        )}
        <Form
          onSubmit={onFormSubmit}
          render={({ handleSubmit, hasValidationErrors, submitSucceeded }) => (
            <form onSubmit={handleSubmit} className="form-container">
              <Field
                name="password"
                type="password"
                label="Password"
                placeholder="New Password"
                tooltip="Minimum 8 characters. Combination of uppercase and lowercase letters. At least one number."
                tippyIcon={
                  <Icon className="field-info-icon" name="question circle" />
                }
                required
                component={FormInput}
                validate={validations.password}
                autoFocus
              />
              <Field
                name="confirmPassword"
                type="password"
                label="Confirm Password"
                placeholder="Confirm password"
                required
                component={FormInput}
                validate={validations.repPassword}
              />
              <Button
                className="submit-button"
                color="blue"
                disabled={hasValidationErrors || submitSucceeded}
                fluid
                size="large"
              >
                Submit
              </Button>
            </form>
          )}
        />
      </Segment>
    </div>
  );
};

export default ResetPassword;
