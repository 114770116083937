import Tippy from 'components/Tippy';
import React, { useState } from 'react';
import { Button, Table } from 'semantic-ui-react';
import '../../pages/SafetyDocuments/SafetyDocuments.styles.scss';
import ConfirmationModal from 'components/ConfirmationModal';
import UploadDocumentModal from 'components/UploadDocumentModal/UploadDocumentModal.component';
import SafetyDocumentsHistoryModal from 'components/SafetyDocumentsHistoryModal/SafetyDocumentsHistoryModal.component';
import { FileFromDB } from 'hooks/useFiles';
import { Driver } from 'types/Users';
import DriverRoleModal from 'components/DriverRoleModal';

type TableDocumentsRowProps = {
  fileId: string;
  fileType: string;
  disable: boolean;
  fileUrl: string;
  driverId: string;
  isDeleted: boolean;
  groupId: string;
  isDeletedDriver?: boolean;
  driver?: Driver;
  setPermanentDeletedFile: React.Dispatch<React.SetStateAction<FileFromDB>>;
  handleSoftDeleteDocument: (fileId: string, fileType: string) => Promise<void>;
  handleReplaceDocument: (values: any) => Promise<void>;
  handleDocumentUpload: (values: any) => Promise<void>;
};

const TableDocumentsRow: React.FC<TableDocumentsRowProps> = (
  props: TableDocumentsRowProps,
) => {
  const {
    fileId,
    fileType,
    disable,
    fileUrl,
    driverId,
    isDeleted,
    groupId,
    isDeletedDriver,
    driver,
    setPermanentDeletedFile,
    handleSoftDeleteDocument,
    handleReplaceDocument,
    handleDocumentUpload,
  } = props;

  const [
    openSafetyDocumentHistory,
    setOpenSafetyDocumentHistory,
  ] = useState<boolean>(false);

  return (
    <>
      <Table.Row>
        <Table.Cell>{fileType}</Table.Cell>
        <Table.Cell>
          <div className="safety-page__table__button-group">
            <Tippy title="Preview Document">
              <Button
                as="a"
                target="_blank"
                rel="noopener noreferrer"
                color={disable ? 'grey' : 'blue'}
                disabled={disable}
                icon="file"
                href={fileUrl}
              />
            </Tippy>
            <Tippy title={disable ? 'Upload Document' : 'Replace Document'}>
              <UploadDocumentModal
                header={
                  disable
                    ? `Upload "${fileType}" document`
                    : `Replace "${fileType}" document`
                }
                instructions={`Drop your file or click here to browse. \n ('.pdf')`}
                submitButtonText={'Submit'}
                initialValues={{
                  fileId,
                  fileType,
                  disable,
                  fileUrl,
                }}
                modalTrigger={
                  <Button
                    color={disable ? 'green' : 'twitter'}
                    icon={disable ? 'upload' : 'exchange'}
                    type="button"
                    disabled={isDeletedDriver}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  />
                }
                onDocumentUpload={
                  !disable ? handleReplaceDocument : handleDocumentUpload
                }
              />
            </Tippy>
            {fileType === 'Lease agreement between company and driver' && (
              <Tippy title="Send for signing">
                <DriverRoleModal
                  driver={driver}
                  trigger={<Button color="blue" icon="send" />}
                />
              </Tippy>
            )}
            <Tippy title="Delete Document">
              <ConfirmationModal
                header="Delete document"
                message={`Are you sure you want to delete "${fileType}" document?`}
                messageHeader="Delete document"
                action={() => handleSoftDeleteDocument(fileId, fileType)}
                color="red"
                buttonContent="Delete"
                buttonIcon="trash"
                trigger={
                  <Button
                    disabled={isDeletedDriver || disable}
                    color="red"
                    icon="trash alternate"
                  />
                }
                isDeleteModal
              />
            </Tippy>
            <Tippy title="Safety Document History">
              <Button
                disabled={!isDeleted && disable}
                color={!isDeleted && disable ? 'grey' : 'twitter'}
                icon="archive"
                type="button"
                onClick={(e) => {
                  e.stopPropagation();
                  setOpenSafetyDocumentHistory(true);
                }}
              />
            </Tippy>
          </div>
        </Table.Cell>
      </Table.Row>
      {openSafetyDocumentHistory && (
        <SafetyDocumentsHistoryModal
          open={openSafetyDocumentHistory}
          setOpen={() => setOpenSafetyDocumentHistory(false)}
          driverId={driverId}
          fileType={fileType}
          fileId={fileId}
          groupId={groupId}
          setPermanentDeletedFile={setPermanentDeletedFile}
        />
      )}
    </>
  );
};
export default TableDocumentsRow;
