import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Button, Loader, Table, TableHeaderCell } from 'semantic-ui-react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { isEqual } from 'lodash';

import { apiAgents } from 'api';
import { generateSchemaTable } from 'pages/DriverDetails/utils';
import { Agent } from 'types/Users';

import AgentRatingTable from 'components/AgentRatingTable';
import ApplicantHeader from 'components/ApplicantHeader';
import ConfirmationModal from 'components/ConfirmationModal';

import { agentSchema } from './config';

import './AgentDetails.styles.scss';

const AgentsDetails: React.FC = () => {
  const { id: agentID } = useParams<{ id: string }>();

  const [agent, setAgent] = useState<Agent>(null);

  const agentRef = useRef(agent);

  const history = useHistory();

  const schemaErrors = {};

  const updateAgent = useCallback(
    async (agent: Agent) => {
      try {
        await apiAgents.updateAgent(agentID, {
          firstName: agent.firstName.trim(),
          lastName: agent.lastName.trim(),
          email: agent.email,
          departments: agent.departments,
        });
        history.push('/agents');

        toast.success('Successfully updated agent details');
      } catch (err: any) {
        if (err?.response.status === 409)
          toast.error(err.response.data.message);
      }
    },
    [agentID, history],
  );

  useEffect(() => {
    const loadAgent = async () => {
      try {
        const {
          data: { agent },
        } = await apiAgents.getAgent(agentID);

        setAgent(() => {
          agentRef.current = agent;
          return agent;
        });
      } catch (err) {
        console.error(err);
      }
    };
    agentID && loadAgent();
  }, [agentID]);

  if (!agent) {
    return <Loader active> Loading...</Loader>;
  }
  return (
    <div className="agent-details">
      <ApplicantHeader
        applicantName={agent?.fullName}
        status={agentRef.current?.departments?.join(', ')}
        showGoBack={false}
      />
      <AgentRatingTable agentId={agent.id} />
      <h3>Agent Details</h3>
      {agent && (
        <Table celled>
          <Table.Header>
            <Table.Row>
              <TableHeaderCell width={1}>Property name</TableHeaderCell>
              <TableHeaderCell width={3}>Property value</TableHeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {generateSchemaTable(
              agentSchema(agent),
              agent,
              setAgent as () => Agent,
              schemaErrors,
              undefined,
              false,
            )}
          </Table.Body>
        </Table>
      )}
      <div className="driver-details__button-container">
        <ConfirmationModal
          trigger={
            <Button
              disabled={
                isEqual(agent, agentRef.current) ||
                Object.keys(schemaErrors).length > 0
              }
              content="Save details"
            />
          }
          action={() => updateAgent(agent)}
          messageHeader="Update agent details"
          message={`Are you sure you want to make changes for agent ${agent.firstName} ${agent.lastName}?`}
        />
      </div>
    </div>
  );
};

export default AgentsDetails;
