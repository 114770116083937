import React, { useCallback, useState, useEffect } from 'react';
import classNames from 'classnames';
import {
  Modal,
  Header,
  Button,
  Message,
  Icon,
  Dimmer,
  Loader,
} from 'semantic-ui-react';
import './MagicLinkForm.styles.scss';
import { Form, Field } from 'react-final-form';
import { FormInput, FormSelect } from '../FinalFormComponents';
import { FormSelectOption } from '../FinalFormComponents/Select/FormSelect.component';
import { toast } from 'react-toastify';
import { Applicant, Department } from '../../types/Users';
import enums from '../../enums';
import {
  copyMagicLink,
  sendEmail,
  isEmailValid,
  mapCompaniesToSelectOptions,
  prepareAgentsSelectByDepartment,
} from './utils';
import { apiAgents, apiCompanies, apiDrivers } from 'api';
import { useFetchWithLimit } from 'hooks/useFetchWithLimit';

const { SUCCESS_MESSAGE, ERROR_MESSAGE } = enums.ACTION_MESSAGES;

type MagicLinkFormProps = {
  onApplicantCreate: (applicant: Applicant) => void;
  isAdmin: boolean;
  open: boolean;
  setOpen: (open: boolean) => void;
};

export type FormValues = {
  firstName: string;
  lastName: string;
  email: string;
  companyId: string;
  eldAgent: string;
  fleetAgent: string;
  safetyAgent: string;
  accountingAgent: string;
  recruitingAgent: string;
  'Driver Education': string;
};

const MagicLinkForm: React.FC<MagicLinkFormProps> = ({
  onApplicantCreate,
  isAdmin,
  open,
  setOpen,
}) => {
  const [copiedToClipboard, setCopiedToClipboard] = useState<boolean>(false);

  const [magicLink, setMagicLink] = useState<string>('');
  const [driverId, setDriverId] = useState<string>('');
  const [companiesSelectOptions, setCompaniesSelectOptions] = useState<
    FormSelectOption[]
  >();
  const [departmentSelectOptions, setDepartmentSelectOptions] = useState<
    Record<Department, FormSelectOption[]>
  >();

  const [loading, setLoading] = useState<boolean>(false);

  const { items: agents } = useFetchWithLimit({
    shouldExecuteCall: isAdmin,
    request: apiAgents.loadAgents,
  });

  const { items: companies } = useFetchWithLimit({
    limit: 20,
    shouldExecuteCall: true,
    request: apiCompanies.getCompanies,
  });

  useEffect(() => {
    setCompaniesSelectOptions(mapCompaniesToSelectOptions(companies));
  }, [companies]);

  useEffect(() => {
    setDepartmentSelectOptions(prepareAgentsSelectByDepartment(agents));
  }, [agents]);

  const onFormSubmit = useCallback(
    (values: FormValues) => {
      setLoading(true);

      const {
        accountingAgent,
        eldAgent,
        fleetAgent,
        safetyAgent,
        recruitingAgent,
        'Driver Education': driverEducation,
        ...rest
      } = values;
      const courses = [
        accountingAgent,
        eldAgent,
        fleetAgent,
        safetyAgent,
        recruitingAgent,
        driverEducation,
      ].reduce((acc, agent) => {
        if (agent) {
          const [id, department] = agent?.split(':');
          return [
            ...acc,
            {
              courseType: department,
              instructorId: id,
              status: 'On-going',
              hasFinishedReview: false,
            },
          ];
        } else {
          return acc;
        }
      }, []);

      apiDrivers
        .createDriver(isAdmin ? { ...rest, courses } : values)
        .then((res) => {
          const { magicLink, driver } = res.data;
          setMagicLink(magicLink);
          setDriverId(driver._id);
          onApplicantCreate(driver);
          setLoading(false);
          toast.success(SUCCESS_MESSAGE.MAGIC_LINK_FORM_SUBMIT);
        })
        .catch((err) => {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error(ERROR_MESSAGE.MAGIC_LINK_FORM_SUBMIT);
          }
          setLoading(false);
        });
    },
    [isAdmin, onApplicantCreate],
  );

  const formClassNames = classNames('ui', 'form', { loading: loading });
  return (
    <Modal
      closeIcon
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      className="modal"
    >
      {!magicLink ? (
        <>
          <Header>Create magic link form</Header>
          <Modal.Content>
            <Form
              onSubmit={onFormSubmit}
              validate={(values) => {
                const errors: any = {};
                if (!values.email) {
                  errors.email = 'Required';
                } else {
                  if (!isEmailValid(values.email)) {
                    errors.email = 'Invalid email';
                  }
                }
                if (!values.companyId) {
                  errors.companyId = 'Required';
                }
                return errors;
              }}
              render={({ handleSubmit, submitting, hasValidationErrors }) => (
                <form onSubmit={handleSubmit} className={formClassNames}>
                  <Field
                    name="firstName"
                    label="First Name"
                    placeholder="John"
                    component={FormInput}
                  />
                  <Field
                    name="lastName"
                    label="Last Name"
                    placeholder="Doe"
                    component={FormInput}
                  />
                  <Field
                    name="email"
                    label="Email"
                    placeholder="johndoe@example.com"
                    component={FormInput}
                    required
                  />
                  <Field
                    name="companyId"
                    label="Company"
                    placeholder="Company"
                    options={companiesSelectOptions}
                    component={FormSelect}
                    required
                  />
                  {isAdmin && (
                    <>
                      <Field
                        name="eldAgent"
                        label="Eld Agent"
                        placeholder="Eld Agent"
                        options={departmentSelectOptions?.Eld}
                        component={FormSelect}
                        clearable
                      />
                      <Field
                        name="fleetAgent"
                        label="Fleet Agent"
                        placeholder="Fleet Agent"
                        options={departmentSelectOptions?.Fleet}
                        component={FormSelect}
                        clearable
                      />
                      <Field
                        name="safetyAgent"
                        label="Safety Agent"
                        placeholder="Safety Agent"
                        options={departmentSelectOptions?.Safety}
                        component={FormSelect}
                        clearable
                      />
                      <Field
                        name="accountingAgent"
                        label="Accounting Agent"
                        placeholder="Accounting Agent"
                        options={departmentSelectOptions?.Accounting}
                        component={FormSelect}
                        clearable
                      />
                      <Field
                        name="recruitingAgent"
                        label="Recruiting Agent"
                        placeholder="Recruiting Agent"
                        options={departmentSelectOptions?.Recruiting}
                        component={FormSelect}
                        clearable
                      />
                      <Field
                        name="Driver Education"
                        label="Driver Education Agent"
                        placeholder="Driver Education Agent"
                        options={departmentSelectOptions?.['Driver Education']}
                        component={FormSelect}
                        clearable
                      />
                    </>
                  )}
                  <div className="modal-actions-like-container">
                    <Button
                      type="submit"
                      disabled={submitting || hasValidationErrors}
                      content="Create magic link"
                      primary
                    />
                  </div>
                </form>
              )}
            />
          </Modal.Content>
        </>
      ) : (
        <>
          <Header>Magic link created</Header>
          <Modal.Content className="modal__content">
            {loading && (
              <Dimmer inverted active>
                <Loader />
              </Dimmer>
            )}
            <Message info>
              <Message.Header>Magic link:</Message.Header>
              <div className="magic-link-paragraph">
                {magicLink}{' '}
                <div
                  onClick={() => copyMagicLink(magicLink, setCopiedToClipboard)}
                >
                  <Icon name={copiedToClipboard ? 'checkmark' : 'copy'} />{' '}
                  <p>{copiedToClipboard ? 'Copied' : 'Copy'}</p>
                </div>
              </div>
            </Message>
          </Modal.Content>

          <Modal.Actions>
            <Button
              content="Send email"
              labelPosition="right"
              disabled={!driverId}
              icon="send"
              onClick={() => sendEmail(driverId, setOpen, setLoading)}
              primary
            />
          </Modal.Actions>
        </>
      )}
    </Modal>
  );
};
export default MagicLinkForm;
