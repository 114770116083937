import React from 'react';
import { Icon, Table, Loader } from 'semantic-ui-react';
import classNames from 'classnames';
import { apiReviews } from 'api';
import TableNavigation from 'components/TableNavigation';
import useFilterParams from 'hooks/useFilterParams';
import { Agent, Driver } from 'types/Users';

import './AgentRatingTable.styles.scss';

type AgentRatingTableProps = {
  agentId: string;
};

export type Review = {
  aggregate: Record<string, any>;
  agent: Agent;
  agentId: string;
  createdAt: string;
  updatedAt: string;
  driver: Driver;
  driverId: string;
  id: string;
  mark: number;
  note?: string;
  averageScore?: number;
};

const AgentRatingTable: React.FC<AgentRatingTableProps> = ({ agentId }) => {
  const { pagination, aggregate, items: reviews, loading } = useFilterParams({
    fallbackRoute: '/agents',
    requestFunc: apiReviews.getReviews,
    agentId,
  });

  if (loading && !reviews?.length)
    return (
      <div className="agent-rating-table--empty">
        <Loader active>Loading...</Loader>
      </div>
    );

  return (
    <div className="agent-rating-table">
      <div className="agent-rating-table__header">
        <h3>Reviews</h3>
        <h5>{`Average score: ${
          aggregate.averageMark
            ? Math.round(aggregate.averageMark * 100) / 100
            : "There's no reviews for this agent yet"
        }`}</h5>
      </div>
      {reviews?.length ? (
        <>
          <Table
            className={classNames({ 'agent-rating-table--loading': loading })}
            celled
          >
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Drivers Name</Table.HeaderCell>
                <Table.HeaderCell>Rating</Table.HeaderCell>
                <Table.HeaderCell>Note</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {reviews.map((review, index) => {
                return (
                  <Table.Row key={index}>
                    <Table.Cell>
                      {review.driver?.applicantName ||
                        'Deleted driver(applicant)'}
                    </Table.Cell>
                    <Table.Cell>
                      {Array.from({ length: review.mark }).map(() => {
                        return (
                          <Icon
                            key={Math.random()}
                            name="star"
                            color="orange"
                          />
                        );
                      })}
                      {review.mark}
                    </Table.Cell>
                    <Table.Cell>{review.note}</Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
          <TableNavigation pagination={pagination} />
        </>
      ) : (
        <></>
      )}
    </div>
  );
};
export default AgentRatingTable;
