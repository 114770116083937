import { apiDrivers } from 'api';
import { useEffect, useRef, useState } from 'react';
import { DriverFiles } from 'types/Users';

const useFilesHistory = (
  driverId: string,
  fileType: string,
  fileId: string,
  groupId: string,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  const isMounted = useRef(true);
  const [files, setFiles] = useState<DriverFiles[]>([]);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);
  useEffect(() => {
    if (!isMounted || !groupId || !fileType || !driverId) {
      return null;
    }

    const fetchFileHistory = async () => {
      try {
        setLoading(true);
        const {
          data: { items },
        } = await apiDrivers.getFilesHistory(
          { $skip: 0, fileType: fileType },
          driverId,
        );

        setFiles(items);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchFileHistory();
  }, [driverId, fileId, fileType, groupId, setLoading]);

  return { files, setFiles };
};

export default useFilesHistory;
