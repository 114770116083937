import React, { useState, useEffect, useCallback } from 'react';
import './DriverDetails.styles.scss';
import { useHistory, useParams } from 'react-router-dom';
import {
  Company,
  Driver,
  SignedHireAndTerminateFiles,
} from '../../types/Users';
import { Loader, Table, Button } from 'semantic-ui-react';
import { generateSchemaTable } from './utils';
import { getDriverSchemaWithCompanies } from './utils/driver.schema';
import ConfirmationModal from '../../components/ConfirmationModal';
import ApplicantHeader from 'components/ApplicantHeader';
import { CONFIRMATION_MODAL_CONTENT_MESSAGE } from './utils/messages';
import { apiDrivers } from 'api';
import { toast } from 'react-toastify';
import { isEqual } from 'lodash';
import TableDocumentsHeader from 'components/TableDocumentsHeader';
import { formatedDate } from 'services/dateService';
import enums from 'enums';
import { prepareNotAvailableDocument } from './utils/prepareNotAvailableDocument';

const { MAPPED_DRIVER_STATUS } = enums;

type DriverDetailsProps = {};
const DriverDetails: React.FC<DriverDetailsProps> = () => {
  const { id } = useParams<{ id: string }>();
  const [driver, setDriver] = useState<Driver>();
  const [oldDriver, setOldDriver] = useState<Driver>();
  const [availableCompanies, setAvailableCompanies] = useState<Company[]>();
  const [isDeletedDriver, setIsDeletedDriver] = useState<boolean>(false);

  const [
    signedHireAndTerminateFiles,
    setSignedHireAndTerminateFiles,
  ] = useState<SignedHireAndTerminateFiles[]>();

  const history = useHistory();

  const pathname = history.location.pathname;
  const userType = pathname.search('applicants') > -1 ? 'applicant' : 'driver';

  const title =
    userType === 'applicant' ? 'Applicant Details' : 'Driver Details';

  const onUpdate = useCallback(async () => {
    try {
      const { newCompanyId, ...driverWithoutNewSubCompany } = driver;

      if (!isEqual(oldDriver, driverWithoutNewSubCompany)) {
        await apiDrivers.updateDriver(id, driverWithoutNewSubCompany, true);
        if (newCompanyId)
          await apiDrivers.transferDriverToAnotherSubcompany(id, newCompanyId);
      } else if (newCompanyId) {
        await apiDrivers.transferDriverToAnotherSubcompany(id, newCompanyId);
      }

      toast.success(`"${driver.applicantName}" is successfully updated.`);

      history.goBack();
    } catch (err: any) {
      if (err.response.status === 409 || err.response.status === 400) {
        toast.error(err?.response.data.error);
      } else {
        console.error(err);
      }
      console.error(err);
    }
  }, [driver, history, id, oldDriver]);

  useEffect(() => {
    const fetchDriver = async () => {
      try {
        const {
          data: { driver },
        } = await apiDrivers.getDriver(id);

        const {
          data: { companies },
        } = await apiDrivers.getAvailableCompanies(driver.email);

        if (!driver.signedHireAndTerminateFiles?.length) {
          if (driver.signedHireUrl && driver.signedTerminateUrl)
            setSignedHireAndTerminateFiles([
              ...driver.signedHireAndTerminateFiles,
              prepareNotAvailableDocument('Hire', driver.signedHireUrl),
              prepareNotAvailableDocument(
                'Terminate',
                driver.signedTerminateUrl,
              ),
            ]);
          else if (driver.signedHireUrl)
            setSignedHireAndTerminateFiles([
              ...driver.signedHireAndTerminateFiles,
              prepareNotAvailableDocument('Hire', driver.signedHireUrl),
            ]);
          else if (driver.signedTerminateUrl)
            setSignedHireAndTerminateFiles([
              ...driver.signedHireAndTerminateFiles,
              prepareNotAvailableDocument(
                'Terminate',
                driver.signedTerminateUrl,
              ),
            ]);
        } else
          setSignedHireAndTerminateFiles(
            driver.signedHireAndTerminateFiles.reverse(),
          );

        setDriver(driver);
        setOldDriver(driver);
        setAvailableCompanies(companies);
        setIsDeletedDriver(driver.isDeleted);
      } catch (err) {
        console.error(err);
      }
    };

    fetchDriver();
  }, [id]);

  if (!driver) {
    return <Loader active>Loading...</Loader>;
  }

  const driverSchemaWithComanies = getDriverSchemaWithCompanies(
    availableCompanies ?? [],
  );

  return (
    <div className="driver-details">
      <ApplicantHeader
        applicantName={driver.applicantName}
        status={driver.status}
        id={id}
      />
      {userType === 'driver' && (
        <>
          <h2>Company Documents</h2>
          {signedHireAndTerminateFiles?.length ? (
            <Table celled striped fixed>
              <TableDocumentsHeader
                headerCells={['Document', 'Agent', 'Date of Signing', 'Link']}
              />
              <Table.Body>
                {signedHireAndTerminateFiles?.map(
                  ({ agent, dateSigned, url, documentType }, index: number) => (
                    <Table.Row key={index}>
                      <Table.Cell>
                        {MAPPED_DRIVER_STATUS[documentType]}
                      </Table.Cell>
                      <Table.Cell>{agent?.fullName || 'agent'}</Table.Cell>
                      <Table.Cell>{formatedDate(dateSigned)}</Table.Cell>
                      <Table.Cell>
                        <a
                          className="preview"
                          href={url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Preview
                        </a>
                      </Table.Cell>
                    </Table.Row>
                  ),
                )}
              </Table.Body>
            </Table>
          ) : (
            <label>No documents to show.</label>
          )}
        </>
      )}
      <h2>{title}</h2>
      <Table celled striped fixed>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={1}>Property name</Table.HeaderCell>
            <Table.HeaderCell width={3}>Property value</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {generateSchemaTable(
            driverSchemaWithComanies,
            driver,
            setDriver as () => Driver,
            {},
            undefined,
            isDeletedDriver,
          )}
        </Table.Body>
      </Table>

      <div className="driver-details__button-container">
        <ConfirmationModal
          trigger={
            <Button disabled={isDeletedDriver || isEqual(oldDriver, driver)}>
              Save details
            </Button>
          }
          action={onUpdate}
          messageHeader="Please Note"
          message={
            userType === 'applicant'
              ? CONFIRMATION_MODAL_CONTENT_MESSAGE.APPLICANT
              : CONFIRMATION_MODAL_CONTENT_MESSAGE.DRIVER
          }
        />
      </div>
    </div>
  );
};

export default DriverDetails;
