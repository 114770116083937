import React from 'react';

type IconProps = React.SVGProps<SVGSVGElement>;

// eslint-disable-next-line import/no-anonymous-default-export
export const DoubleCheckIcon = (props: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="28"
    width="28"
    {...props}
    fill="#21ba45"
  >
    <path
      stroke="#21ba45"
      d="M14.7 35.9 3.5 24.7 5.65 22.55 14.7 31.6 16.85 33.75ZM23.2 35.9 12 24.7 14.15 22.55 23.2 31.6 42.4 12.4 44.55 14.55ZM23.2 27.4 21.05 25.25 33.9 12.4 36.05 14.55Z"
    />
  </svg>
);
