import React from 'react';
import MyDropZone from '../MyDropZone/index';

type MyDropZoneFieldProps = {
  input: any;
  meta: any;
};

const MyDropZoneField: React.FC<MyDropZoneFieldProps> = (
  props: MyDropZoneFieldProps,
) => {
  const { input, ...rest } = props;
  const { value, onChange, ...restInput } = input;

  return (
    <MyDropZone
      files={value}
      onFilesChange={onChange}
      {...restInput}
      {...rest}
    />
  );
};

export default MyDropZoneField;
