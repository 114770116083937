import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import useQueryParams from 'hooks/useQueryParams';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { NavLink } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';
import credentialsService from 'services/credentialsService';
import { State } from 'store/reducers';
import './Header.styles.scss';
import ProfileUpdateModal from 'components/ProfileUpdateModal';
import PasswordUpdateModal from 'components/PasswordUpdateModal';
import useSockets from 'hooks/useSockets';
import { preparedUrlQuery } from './utils/preparedUrlQuery';
import { Badge } from './svgs';
import Tippy from 'components/Tippy';

type HeaderProps = {
  header?: string;
  options?: string[];
  navigationMenuVisibility?: boolean;
  renderOnlyOrientation: boolean;
  setNavigationMenuVisibility?: React.Dispatch<React.SetStateAction<boolean>>;
};

type QParams = {
  companyId: string;
  limit: string;
  page: string;
  searchString: string;
};

const OPTIONS_WITH_COMPANIES = ['applicants', 'drivers', 'orientation'];

const ORIENTATION = 'Orientation';

const Header: React.FC<HeaderProps> = ({
  navigationMenuVisibility,
  setNavigationMenuVisibility,
  header,
  options,
  renderOnlyOrientation,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [isAccountModal, setIsAccountModal] = useState<boolean>(true);

  const {
    username,
    roles,
    isVerified: isVerifiedRedux,
    profileImageUrl,
  } = useSelector((state: State) => state.currentUser);

  const user = credentialsService.user;

  const isVerifiedStorage = user?.isVerified;

  const isVerified = isVerifiedRedux || isVerifiedStorage;

  const history = useHistory();

  const urlPathParams = history.location.pathname.split('/');

  const shouldRenderCompanies = urlPathParams.some((param) =>
    OPTIONS_WITH_COMPANIES.includes(param),
  );

  useSockets();

  const {
    removeQueryParam,
    params: { companyId, limit, page },
  } = useQueryParams<QParams>();

  useEffect(() => {
    if (!shouldRenderCompanies) {
      removeQueryParam('companyId', true);
    }
  }, [shouldRenderCompanies, removeQueryParam]);

  const activeItem = urlPathParams[1] ?? 'applicants';

  const linkWithQParamsClasses = (userRoute: string) =>
    classNames(
      'seh-menu__filter-menu__option',
      {
        'seh-menu__filter-menu__option--selected':
          userRoute === urlPathParams[1],
      },
      {
        'seh-menu__filter-menu__option--default-selected': activeItem
          ? userRoute === activeItem
          : userRoute === 'applicants',
      },
    );

  const onLogout = () => {
    credentialsService.removeAuthBody();
    history.push('/login');
  };

  const menuButtonClassNames = classNames(
    'header-container__left__menu-button',
    {
      'header-container__left__menu-button--close': navigationMenuVisibility,
    },
    { 'header-container__left__menu-button--disable': !shouldRenderCompanies },
  );

  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
    setIsAccountModal(true);
  };

  const handleToggleModal = () => {
    setIsAccountModal((prev) => !prev);
  };

  const routeParams = () => {
    const paramsArray = [
      { name: 'page', value: '1' },
      { name: 'searchString', value: '' },
    ];

    if (companyId) {
      paramsArray.push({ name: 'companyId', value: companyId });
    }
    if (limit) {
      paramsArray.push({ name: 'limit', value: limit });
    }
    return paramsArray;
  };

  const handleHeaderOptionClick = (option: string) => {
    if (!OPTIONS_WITH_COMPANIES.includes(option.toLowerCase()))
      setNavigationMenuVisibility?.(false);
  };

  useEffect(() => {
    if (renderOnlyOrientation) {
      history.push(`/orientation?page=${page || 1}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, renderOnlyOrientation]);

  return (
    <header className="header-container">
      <div className="header-container__left">
        <div
          className={menuButtonClassNames}
          onClick={() => setNavigationMenuVisibility?.((oldVal) => !oldVal)}
        >
          <div className="bar1 bar"></div>
          <div className="bar2 bar"></div>
          <div className="bar3 bar"></div>
        </div>
        <NavLink to="/applicants" className="logo">
          Super Ego Holding LLC
        </NavLink>
      </div>
      <div className="seh-menu">
        {renderOnlyOrientation ? (
          <NavLink
            className={linkWithQParamsClasses(ORIENTATION.toLowerCase())}
            to={
              `/${ORIENTATION.toLowerCase()}` + preparedUrlQuery(routeParams())
            }
            onClick={() => handleHeaderOptionClick(ORIENTATION)}
          >
            <span>{ORIENTATION}</span>
          </NavLink>
        ) : (
          options.map((option: string) =>
            !(option === 'Agents' && roles.includes('Agent')) ? (
              <NavLink
                key={header + option}
                className={linkWithQParamsClasses(option.toLowerCase())}
                to={
                  `/${option.toLowerCase()}` + preparedUrlQuery(routeParams())
                }
                onClick={() => handleHeaderOptionClick(option)}
              >
                <span>{option}</span>
              </NavLink>
            ) : null,
          )
        )}

        <div className="user-menu">
          {!renderOnlyOrientation && (
            <NavLink
              to="/activity-page"
              onClick={() => handleHeaderOptionClick('activity-page')}
            >
              Activity page
            </NavLink>
          )}
          <div className="user-menu__user" onClick={handleOpenModal}>
            {isVerified && (
              <Tippy title="Your mail is verified.">
                <Badge
                  className={classNames('user-menu__badge', {
                    'user-menu__badge--without-image': !profileImageUrl,
                  })}
                />
              </Tippy>
            )}
            {profileImageUrl ? (
              <img
                src={profileImageUrl}
                alt="Users's profile"
                className="user-menu__user__picture"
              />
            ) : (
              <Icon name="user" />
            )}
            <span className="user-menu__user__username">{username}</span>
          </div>
          <label className="user-menu__logout" onClick={onLogout}>
            Logout
          </label>
        </div>
      </div>
      {open && isAccountModal && (
        <ProfileUpdateModal
          open={open && isAccountModal}
          onClose={handleCloseModal}
          handleToggleModal={handleToggleModal}
        />
      )}
      {open && !isAccountModal && (
        <PasswordUpdateModal
          open={open && !isAccountModal}
          onClose={handleCloseModal}
          handleToggleModal={handleToggleModal}
        />
      )}
    </header>
  );
};

export default Header;
