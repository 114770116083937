import httpClient from '../httpClient';
import { ApiResponse, ApiResponseLink } from '../../types/ApiResponse';
import {
  Applicant,
  Driver,
  DriverFiles,
  DrivingHistory,
} from '../../types/Users';
import { RoleValuesType } from 'components/DriverRoleModal/DriverRoleModal.component';
import { User } from 'models/User';
import enums from '../../enums';
const { DRIVER_STATUS } = enums;

const path = (routePath: string) => `/dashboard/drivers/${routePath}`;

async function createDriver(userData: Record<string, any>) {
  return httpClient.post(path(''), userData);
}

async function sendEmail(id: string) {
  return httpClient.post(path(id + '/send-email'));
}

async function sendToSign(id: string, redirectUrl: string, values: any) {
  return httpClient.post(`dashboard/drivers/${id}/docusign`, {
    redirectUrl,
    ...values,
  });
}

const sendReviewEmails = (driverId: string, instructorIds: string[]) => {
  return httpClient.post('dashboard/drivers/send-review-emails', [
    {
      driverId,
      instructorIds,
    },
  ]);
};

const loadApplicants = (
  params: Record<
    string,
    string | number | string[] | Record<string, string[]> | User
  >,
  isDeleted?: boolean,
) =>
  httpClient.get<ApiResponse<Applicant>>('dashboard/drivers/', {
    params: {
      ...params,
      $relations: [
        'companyInfo',
        'employmentHistory',
        'applicationCreator',
        'declarationOfEmployment',
      ],
      isDeleted,
    },
  });

const loadDrivers = (
  params: Record<
    string,
    string | number | string[] | Record<string, string[]> | User
  >,
  isDeleted?: boolean,
) =>
  httpClient.get<ApiResponse<Driver>>('dashboard/drivers', {
    params: {
      ...params,
      $relations: [
        'companyInfo',
        'employmentHistory',
        'employedBy',
        'declarationOfEmployment',
        'applicationCreator',
      ],
      isDeleted,
    },
  });

const loadOrientations = (
  params: Record<string, string | number | string[] | Record<string, string[]>>,
  isDeleted?: boolean,
) =>
  httpClient.get<ApiResponse<Driver>>('dashboard/drivers/orientations', {
    params: {
      ...params,
      $relations: ['courses.instructor', 'companyInfo'],
      isDeleted,
    },
  });

async function getMagicLink(id: string) {
  return httpClient.post<ApiResponseLink>(`dashboard/drivers/${id}/magic-link`);
}

async function getDriver(id: string) {
  return httpClient.get<{ driver: Driver }>(path(id));
}

async function updateDriver(
  id: string,
  updateData: Partial<Driver>,
  shouldCreateNewPdf = false,
) {
  return httpClient.patch<{ driver: Driver }>(path(id), {
    ...updateData,
    shouldCreateNewPdf,
  });
}

const updateApplicant = (
  applicantId: string,
  companyId: string,
  email: string,
) =>
  httpClient.post(`dashboard/drivers/${applicantId}/email`, {
    email,
    companyId,
  });
const updateDriverInstructor = (
  driverId: string,
  agentId: string,
  courseType: string,
) =>
  httpClient.post(`dashboard/drivers/${driverId}/agents/${agentId}/course`, {
    courseType,
  });

function requestChanges(id: string, changesRequested: string) {
  return httpClient.post(`dashboard/drivers/${id}/request-changes`, {
    changesRequested,
  });
}

const requestChangesFromDriver = (id: string, changesRequested: string) =>
  httpClient.post(`dashboard/drivers/${id}/request-changes-from-driver`, {
    changesRequested,
  });

function hire(id: string, redirectUrl: string) {
  return httpClient.post<ApiResponseLink>(`dashboard/drivers/${id}/hire`, {
    redirectUrl,
  });
}

function terminate(id: string, redirectUrl: string) {
  return httpClient.post<ApiResponseLink>(`dashboard/drivers/${id}/terminate`, {
    redirectUrl,
  });
}

function sendEmploymentHistoryEmails(
  id: String,
  employmentHistoryIds: string[],
  redirectUrl: string,
) {
  return httpClient.post(`dashboard/drivers/${id}/employment-history`, {
    employmentHistoryIds,
    redirectUrl,
  });
}
function loadFiles(params: any, id: string) {
  return httpClient.get<ApiResponse<DriverFiles>>(
    `dashboard/drivers/${id}/files`,
    {
      params,
    },
  );
}

function getFilesHistory(params: any, id: string) {
  return httpClient.get<ApiResponse<DriverFiles>>(
    `dashboard/drivers/${id}/files`,
    {
      params: { ...params, $relations: ['agent'] },
    },
  );
}

function uploadFile(id: string, files: File[], fileType: string) {
  const formData = new FormData();
  files.forEach((file) => {
    formData.append('files', file);
    formData.append('fileType', fileType);
  });
  return httpClient.post(`dashboard/drivers/${id}/files`, formData);
}

const softDeleteFile = (fileId: string, driverId: string) =>
  httpClient.delete(`dashboard/drivers/${driverId}/files/${fileId}/delete`);

const permanentDeleteFile = (fileId: string, driverId: string) =>
  httpClient.delete(`dashboard/drivers/${driverId}/files/${fileId}`);

function replaceFile(
  fileId: string,
  driverId: string,
  newFile: File[],
  fileType: string,
) {
  const formData = new FormData();
  formData.append('files', newFile[0]);
  formData.append('fileType', fileType);

  return httpClient.put(
    `dashboard/drivers/${driverId}/files/${fileId}`,
    formData,
  );
}
function signDocument(driverId: string, redirectUrl: string) {
  return httpClient.post<ApiResponseLink>(
    `dashboard/drivers/${driverId}/driving`,
    { redirectUrl },
  );
}

const uploadEmploymentHistory = (
  employmentHistoryId: string,
  files: File[],
) => {
  const formData = new FormData();
  files.forEach((file) => {
    formData.append('employmentHistory', file);
  });
  return httpClient.post(
    `employment-history/${employmentHistoryId}/files`,
    formData,
  );
};

const deleteEmploymentHistory = (
  driverId: string,
  employmentHistoryId: string,
) =>
  httpClient.delete(
    `dashboard/drivers/${driverId}/employment-history/${employmentHistoryId}`,
  );

const deleteEvidenceFile = (employmentHistoryId: string, fileId: string) =>
  httpClient.delete(
    `employment-history/${employmentHistoryId}/files/${fileId}`,
  );

const transferDriverToAnotherSubcompany = (
  driverId: string,
  companyId: string,
) =>
  httpClient.post(
    `dashboard/drivers/${driverId}/companies/${companyId}/replicate`,
  );

const getAvailableCompanies = (email: string) =>
  httpClient.get(`dashboard/drivers/${email}/companies/available`);
const softDeleteDriver = (driverId: string) =>
  httpClient.delete<{ driver: Applicant }>(
    `dashboard/drivers/${driverId}/delete`,
  );

const permanentDeleteDriver = (driverId: string) =>
  httpClient.delete<{ driver: Applicant }>(`dashboard/drivers/${driverId}`);

const addDOTNumberAndEmploymentHistoryInformaton = (
  driverId: string,
  dotNumber: number,
  params: {
    contactPerson: string;
    from: Date;
    to: Date;
    positionHeld: string;
    reasonForLeaving: string;
    subjectOfFmcrs: boolean;
    drugAndAlchTest: boolean;
  },
) =>
  httpClient.post(
    `dashboard/drivers/${driverId}/employment-history/${dotNumber}`,
    params,
  );

const disableForDriving = (driverId: string) =>
  httpClient.post<ApiResponse<DrivingHistory[]>>(
    `dashboard/drivers/${driverId}/driving/disable`,
  );
const signLeaseAgreement = (
  driverId: string,
  redirectUrl: string,
  driverRole: string,
  params?: RoleValuesType,
) =>
  httpClient.post<ApiResponseLink>(
    `dashboard/drivers/${driverId}/driving-role/${driverRole}`,
    {
      ...params,
      redirectUrl,
    },
  );

const changeStatusToHiredChecked = (driverId: string) =>
  httpClient.post(`dashboard/drivers/${driverId}/update-status-to-checked`);

const moveToDrivers = (driverId: string) =>
  httpClient.post(`dashboard/drivers/${driverId}/change-status`, {
    status: DRIVER_STATUS.Verified,
  });

export default {
  loadOrientations,
  hire,
  createDriver,
  sendEmail,
  sendToSign,
  loadApplicants,
  getDriver,
  updateDriver,
  loadDrivers,
  getMagicLink,
  requestChanges,
  requestChangesFromDriver,
  terminate,
  sendEmploymentHistoryEmails,
  loadFiles,
  uploadFile,
  updateDriverInstructor,
  softDeleteFile,
  replaceFile,
  getFilesHistory,
  signDocument,
  uploadEmploymentHistory,
  updateApplicant,
  transferDriverToAnotherSubcompany,
  getAvailableCompanies,
  softDeleteDriver,
  permanentDeleteDriver,
  permanentDeleteFile,
  addDOTNumberAndEmploymentHistoryInformaton,
  disableForDriving,
  signLeaseAgreement,
  deleteEvidenceFile,
  deleteEmploymentHistory,
  sendReviewEmails,
  changeStatusToHiredChecked,
  moveToDrivers,
};
