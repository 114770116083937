import React from 'react';
import { Router } from 'react-router-dom';
import routes from 'router/routes';
import Routes from 'router/components/Routes/Routes';
import { Provider } from 'react-redux';
import store from 'store';
import history from './common/history';
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-tippy/dist/tippy.css';
import useToastConfigure from 'hooks/useToastConfigure';
import { humanizeDateConfig } from 'utils';

function App() {
  useToastConfigure();
  humanizeDateConfig();

  return (
    <Provider store={store}>
      <Router history={history}>
        <Routes routes={routes} />
      </Router>
    </Provider>
  );
}

export default App;
