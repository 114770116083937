import React, { useState } from 'react';
import classNames from 'classnames';
import { Modal, Header, Button } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import { Form, Field, FormSpy } from 'react-final-form';
import { isEqual } from 'lodash';

import { useDispatch, useSelector } from 'react-redux';
import { State } from 'store/reducers';
import { UPDATE_CURRENT_USER } from 'store/actions/actionTypes';

import credentialsService from 'services/credentialsService';
import { apiAgents } from 'api';
import { emailValidation, nameValidation, usernameValidation } from './utils';

import { FormInput, Label } from 'components/FinalFormComponents';
import MyDropZoneField from 'components/MyDropZoneField';
import ConfirmationModal from 'components/ConfirmationModal';

import './ProfileUpdateModal.styles.scss';

type UpdateAgentFormValues = {
  email: string;
  firstName: string;
  lastName: string;
  username: string;
  profileImage?: File[];
};

const ProfileUpdateModal = (props: any) => {
  const { open, onClose, handleToggleModal } = props;
  const [isEditImage, setIsEditImage] = useState(false);

  const user = useSelector((state: State) => state.currentUser);
  const {
    _id,
    username,
    firstName,
    lastName,
    email,
    isVerified: isVerifiedRedux,
    profileImageUrl,
  } = user;

  const isVerifiedStorage = user?.isVerified;

  const isVerified = isVerifiedRedux || isVerifiedStorage;

  const dispatch = useDispatch();

  const formClassNames = classNames('ui', 'form');

  const onFormSubmit = async (values: UpdateAgentFormValues) => {
    const userLocalStorage = credentialsService.user;

    const { profileImage, ...restUserDetails } = values;
    try {
      if (!isEqual(restUserDetails, { firstName, lastName, username, email }))
        await apiAgents.updateAgent(_id, {
          ...restUserDetails,
          firstName: restUserDetails.firstName.trim(),
          lastName: restUserDetails.lastName.trim(),
        });

      let updatetedProfileImageUrl = profileImageUrl;

      if (profileImage && profileImage[0]) {
        const {
          data: {
            agent: { profileImageUrl: newProfileImageUrl },
          },
        } = await apiAgents.updateAgentProfilePicture(_id, profileImage[0]);
        updatetedProfileImageUrl = newProfileImageUrl;
        setIsEditImage(false);
      }

      const updatedUserDetails = {
        ...restUserDetails,
        profileImageUrl: updatetedProfileImageUrl,
      };

      credentialsService.saveAuthBody((old) => ({
        ...old,
        user: { ...userLocalStorage, ...updatedUserDetails },
      }));

      dispatch({ type: UPDATE_CURRENT_USER, payload: updatedUserDetails });
      toast.success('You have successfully updated account details.');
      onClose();
    } catch (error) {
      toast.error('Something went wrong. Please try again.');
      console.error(error);
    }
  };

  return (
    <Modal closeIcon onClose={onClose} open={open} className="modal">
      <Header>Account Details</Header>
      <Modal.Content scrolling={isEditImage || !profileImageUrl}>
        <Form<UpdateAgentFormValues>
          onSubmit={onFormSubmit}
          initialValues={{ username, firstName, lastName, email }}
          render={({
            handleSubmit,
            submitting,
            hasValidationErrors,
            pristine,
            values,
            form,
          }) => (
            <form
              onSubmit={handleSubmit}
              className={classNames(formClassNames, 'profile-update')}
            >
              <Field
                component={FormInput}
                label="Username"
                name="username"
                required
                validate={usernameValidation}
              />
              <Field
                component={FormInput}
                label="First name"
                name="firstName"
                required
                validate={nameValidation}
              />
              <Field
                component={FormInput}
                label="Last name"
                name="lastName"
                required
                validate={nameValidation}
              />
              <Field
                component={FormInput}
                label="Email"
                name="email"
                required
                validate={emailValidation}
                autoComplete="off"
                readonly={isVerified}
              />
              <div className="profile-update__profile-image-heading">
                <Label label="Profile image" />
                {profileImageUrl && (
                  <Button
                    type="button"
                    icon="edit"
                    onClick={() => setIsEditImage((old) => !old)}
                  />
                )}
              </div>

              {isEditImage || !profileImageUrl ? (
                <Field
                  name="profileImage"
                  component={MyDropZoneField}
                  accept={['.jpg', '.png', '.jpeg']}
                  instructions={`Drop your image or click here to browse. \n ('.jpg','.png','.jpeg')`}
                  maxFiles={1}
                />
              ) : (
                <div className="profile-update__avatar">
                  <img src={profileImageUrl} alt="User's profile" />
                </div>
              )}
              <FormSpy
                onChange={() => {
                  if (!isEditImage && !pristine)
                    form.change('profileImage', undefined);
                }}
              />
              <div className="form-actions">
                <Button
                  type="button"
                  content="Update password"
                  onClick={handleToggleModal}
                  as="a"
                  basic
                  className="link-button"
                />
                <ConfirmationModal
                  message="Are you sure you want to update your account details?"
                  action={() => onFormSubmit(values)}
                  trigger={
                    <Button
                      type="button"
                      disabled={submitting || hasValidationErrors || pristine}
                      content="Update details"
                      primary
                    />
                  }
                />
              </div>
            </form>
          )}
        />
      </Modal.Content>
    </Modal>
  );
};

export default ProfileUpdateModal;
