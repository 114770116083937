import { Company, CompanyValue } from '../EmploymentHisoryModal.component';

export const TRIGGER_TEXT = 'Employment history';
export const HEADER = 'Employment History';
export const SUBMIT_BUTTON_TEXT = 'Send emails';
export const CHECKBOX_GROUP_LABEL =
  'Please select companies you want to send Employment History document to.';
export const ERROR_MESSAGE =
  'Error occured. Employment history emails were not sent. Please try again, or contact the administrator.';

export const getInitialValues = (companies: Company[]): CompanyValue[] => {
  return companies.map((company: Company) => {
    return {
      id: company.id,
      checked: false,
      value: company.value,
      name: company.label,
    };
  });
};
