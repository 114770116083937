import React, { useState, useEffect } from 'react';
import './ConfirmationLinkModal.styles.scss';
import {
  Modal,
  Header,
  Button,
  Message,
  Icon,
  Dimmer,
  Loader,
} from 'semantic-ui-react';
import { LINK_CREATED, LINK_CREATED_LINK, copyLink } from './utils';
import enums from '../../enums';
import { toast } from 'react-toastify';
import { ApiResponseLink } from 'types/ApiResponse';
import { AxiosResponse } from 'axios';
const { DRIVER_STATUS } = enums;

type MessageType = {
  message: string;
  editMessage: string;
};
type ActionMessage = {
  succes: MessageType;
  error: MessageType;
};
type ConfirmationLinkModalProps = {
  header: MessageType;
  messageHeader: MessageType;
  message: MessageType;
  actionMessage: ActionMessage;
  trigger?: React.ReactNode;
  driverId: string;
  driverName?: string;
  status: typeof DRIVER_STATUS[keyof typeof DRIVER_STATUS];
  actionStatus: typeof DRIVER_STATUS[keyof typeof DRIVER_STATUS];
  action: (driverId: string) => Promise<AxiosResponse<ApiResponseLink>>;
  isDocuSignModal?: boolean;
};

const ConfirmationLinkModal: React.FC<ConfirmationLinkModalProps> = ({
  trigger: triggerRef,
  driverId,
  driverName,
  status,
  header,
  message,
  messageHeader,
  actionMessage,
  actionStatus,
  action,
  isDocuSignModal = false,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [copiedToClipboard, setCopiedToClipboard] = useState<boolean>(false);

  const [link, setLink] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const trigger = triggerRef ?? <Button content={'Send request'} />;

  const resetStates = () => {
    setLink('');
    setLoading(false);
  };

  useEffect(() => {
    if (!open) {
      return;
    }
    return resetStates;
  }, [open]);

  const handleAction = async (id: string) => {
    setLoading(true);
    try {
      const { data } = await action(id);
      setLink(data.url);
      setLoading(false);
      toast.success(
        actionMessage.succes[
          status === actionStatus ? 'editMessage' : 'message'
        ],
      );
    } catch (err) {
      setLoading(false);

      if (isDocuSignModal) {
        toast.error(err?.response.data.error);
      } else {
        toast.error(
          actionMessage.error[
            status === actionStatus ? 'editMessage' : 'message'
          ],
        );
        console.error(err);
      }
    }
  };

  return (
    <Modal
      closeIcon
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={trigger}
      className="modal"
    >
      {!link ? (
        <>
          <Header>
            {header[status === actionStatus ? 'editMessage' : 'message']}
          </Header>
          <Modal.Content>
            {loading && (
              <Dimmer inverted active>
                <Loader>Sending request...</Loader>
              </Dimmer>
            )}
            <Message info>
              <Message.Header>
                {
                  messageHeader[
                    status === actionStatus ? 'editMessage' : 'message'
                  ]
                }
                {driverName || driverId}
              </Message.Header>
              <p className="confirmation-message">
                {message[status === actionStatus ? 'editMessage' : 'message']}
              </p>
            </Message>{' '}
          </Modal.Content>
          <Modal.Actions>
            <Button color="black" onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <Button
              content="Confirm"
              labelPosition="right"
              icon="checkmark"
              onClick={() => handleAction(driverId)}
              primary
              disabled={loading}
            />
          </Modal.Actions>
        </>
      ) : (
        <>
          <Header>{LINK_CREATED}</Header>
          <Modal.Content className="modal__content">
            <Message info>
              <Message.Header>{LINK_CREATED_LINK}</Message.Header>
              <div className="link-paragraph">
                <a target="_blank" rel="noopener noreferrer" href={link}>
                  Go to created link
                </a>
                <div onClick={() => copyLink(link, setCopiedToClipboard)}>
                  <Icon name={copiedToClipboard ? 'checkmark' : 'copy'} />{' '}
                  <p>{copiedToClipboard ? 'Copied' : 'Copy'}</p>
                </div>
              </div>
            </Message>
          </Modal.Content>
        </>
      )}
    </Modal>
  );
};
export default ConfirmationLinkModal;
