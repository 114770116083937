import dayjs from 'dayjs';

export default {
  compareDates(dateFrom: any, dateUntil: any) {
    const date1 = dayjs(dateFrom);
    const date2 = dayjs(dateUntil);
    if (date1.diff(date2) > 0) {
      return true;
    }
    return false;
  },
};

export const formatedDate = (
  date: Date | string,
  shouldCreateFromatedDate = true,
) => {
  if (!shouldCreateFromatedDate && !date) {
    return null;
  }
  return dayjs(date).format('MM/DD/YYYY');
};
