export const TRAILER_TYPE = {
  VAN: 'Van',
  TANK: 'Tank',
  FLAT: 'Flat',
  DUMP: 'Dump',
  REFER: 'Refer',
};

export const REASON_FOR_LEAVING = {
  VOLUNTARY: 'Voluntary',
  LAY_OFF: 'Lay-off',
  TERMINATED: 'Terminated',
  RETIRED: 'Retired',
};

export const ELIGIBLE_FOR_REHIRE = {
  YES: 'Yes',
  NO: 'No',
  UPON_REVIEW: 'Upon Review',
  NO_COMPANY_POLICY: 'No, Company Policy',
};

export const EDUCATION_GRADE = {
  GRADE_1: '1',
  GRADE_2: '2',
  GRADE_3: '3',
  GRADE_4: '4',
  GRADE_5: '5',
  GRADE_6: '6',
  GRADE_7: '7',
  GRADE_8: '8',
  GRADE_9: '9',
  GRADE_10: '10',
  GRADE_11: '11',
  GRADE_12: '12',
  COLLEGE_1: 'College - 1',
  COLLEGE_2: 'College - 2',
  COLLEGE_3: 'College - 3',
  COLLEGE_4: 'College - 4',
};

export const STATES = {
  Alabama: 'AL',
  Nebraska: 'NE',
  Alaska: 'AK',
  Nevada: 'NV',
  Arizona: 'AZ',
  'New Hampshire': 'NH',
  Arkansas: 'AR',
  'New Jersey': 'NJ',
  California: 'CA',
  'New Mexico': 'NM',
  Colorado: 'CO',
  'New York': 'NY',
  Connecticut: 'CT',
  'North Carolina': 'NC',
  Delaware: 'DE',
  'North Dakota': 'ND',
  'District of Columbia': 'DC',
  Ohio: 'OH',
  Florida: 'FL',
  Oklahoma: 'OK',
  Georgia: 'GA',
  Oregon: 'OR',
  Hawaii: 'HI',
  Pennsylvania: 'PA',
  Idaho: 'ID',
  'Puerto Rico': 'PR',
  Illinois: 'IL',
  'Rhode Island': 'RI',
  Indiana: 'IN',
  'South Carolina': 'SC',
  Iowa: 'IA',
  'South Dakota': 'SD',
  Kansas: 'KS',
  Tennessee: 'TN',
  Kentucky: 'KY',
  Texas: 'TX',
  Louisiana: 'LA',
  Utah: 'UT',
  Maine: 'ME',
  Vermont: 'VT',
  Maryland: 'MD',
  Virginia: 'VA',
  Massachusetts: 'MA',
  'Virgin Islands': 'VI',
  Michigan: 'MI',
  Washington: 'WA',
  Minnesota: 'MN',
  'West Virginia': 'WV',
  Mississippi: 'MS',
  Wisconsin: 'WI',
  Missouri: 'MO',
  Wyoming: 'WY',
  Montana: 'MT',
};
