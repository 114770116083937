import { apiDrivers } from 'api';
import { FormInput } from 'components/FinalFormComponents';
import React, { useState, useCallback } from 'react';
import { Field, Form } from 'react-final-form';
import { toast } from 'react-toastify';
import { Button, Dimmer, Loader, Modal } from 'semantic-ui-react';
import validateService from 'services/validateService';
import { EmploymentHistory } from 'types';
import { Driver } from 'types/Users';
import { restrictKeyBoardTasters } from './utils';

type FormValuesType = {
  dotNumber: number;
  contactPerson: string;
  dateFrom: Date;
  dateTo: Date;
  positionHeld: string;
  reasonForLeaving: string;
  subjectOfFmcrs: boolean;
  drugAndAlchTest: boolean;
};

type DOTNumberModalProps = {
  driver?: Driver;
  trigger?: React.ReactNode;
  employmentHistoryState?: EmploymentHistory[];
};

const DOTNumberModal: React.FC<DOTNumberModalProps> = ({
  driver,
  trigger,
  employmentHistoryState,
}) => {
  const [open, setOpen] = useState<boolean>(false);

  const onFormSubmit = useCallback(
    async (formValues: FormValuesType) => {
      try {
        const {
          dotNumber,
          contactPerson,
          dateFrom,
          dateTo,
          positionHeld,
          reasonForLeaving,
          subjectOfFmcrs,
          drugAndAlchTest,
        } = formValues;

        await apiDrivers.addDOTNumberAndEmploymentHistoryInformaton(
          driver.id,
          dotNumber,
          {
            contactPerson: contactPerson.trim(),
            from: dateFrom,
            to: dateTo,
            positionHeld: positionHeld.trim(),
            reasonForLeaving: reasonForLeaving.trim(),
            subjectOfFmcrs,
            drugAndAlchTest,
          },
        );
      } catch (error) {
        console.error(error);
        toast.error(error);
      } finally {
        setOpen(false);
      }
    },
    [driver.id],
  );

  return (
    <Form
      onSubmit={onFormSubmit}
      render={({ handleSubmit, submitting, form, hasValidationErrors }) => (
        <Modal
          closeIcon
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          open={open}
          trigger={trigger}
          className="modal"
        >
          <Modal.Header>
            DOT Number and Employment History information
          </Modal.Header>
          <Modal.Content>
            <form
              onSubmit={(event) => handleSubmit(event).then(form.reset)}
              className="ui form"
            >
              {submitting && (
                <Dimmer inverted active>
                  <Loader>Sending request... </Loader>
                </Dimmer>
              )}
              <Field
                name="dotNumber"
                type="number"
                label="DOT Number"
                required
                component={FormInput}
                onKeyDown={restrictKeyBoardTasters}
                validate={validateService.required}
              />
              <Field
                name="contactPerson"
                label="Contact person"
                required
                component={FormInput}
                validate={validateService.composeValidators(
                  validateService.required,
                  validateService.length64CharactersValidation,
                )}
              />
              <Field
                name="dateFrom"
                type="date"
                label="Date from"
                required
                component={FormInput}
                validate={validateService.composeValidators(
                  validateService.required,
                  validateService.onlyPast,
                  validateService.beforeFieldValidation,
                  validateService.employmentHistoryDateIntervalValidation(
                    employmentHistoryState,
                  ),
                )}
              />
              <Field
                name="dateTo"
                type="date"
                label="Date to"
                required
                component={FormInput}
                validate={validateService.composeValidators(
                  validateService.required,
                  validateService.onlyPast,
                  validateService.afterFieldValidation,
                  validateService.employmentHistoryDateIntervalValidation(
                    employmentHistoryState,
                  ),
                )}
              />
              <Field
                name="positionHeld"
                label="Position held"
                required
                component={FormInput}
                validate={validateService.composeValidators(
                  validateService.required,
                  validateService.length64CharactersValidation,
                )}
              />

              <Field
                name="reasonForLeaving"
                label="Reason for leaving"
                required
                component={FormInput}
                validate={validateService.composeValidators(
                  validateService.required,
                  validateService.length64CharactersValidation,
                )}
              />
              <div className="checkbox">
                <Field
                  name="subjectOfFmcrs"
                  id="subjectOfFmcrs"
                  type="checkbox"
                  initialValue={false}
                  component="input"
                />
                <label htmlFor="subjectOfFmcrs">
                  Were you subject to the FMCRS^ while employed?
                </label>
              </div>
              <div className="checkbox">
                <Field
                  name="drugAndAlchTest"
                  id="drugAndAlchTest"
                  type="checkbox"
                  initialValue={false}
                  component="input"
                />
                <label htmlFor="drugAndAlchTest">
                  Was your job designated as a safety-sensitive function in any
                  DOT-regulated mode subject to the drug and alcohol testing
                  requirements of 49 CFR Part 40?
                </label>
              </div>
              <div className="form-actions">
                <Modal.Actions>
                  <Button
                    color="black"
                    content="Cancel"
                    type="button"
                    onClick={() => setOpen(false)}
                  />
                  <Button
                    content="Submit"
                    type="submit"
                    primary
                    disabled={hasValidationErrors || submitting}
                  />
                </Modal.Actions>
              </div>
            </form>
          </Modal.Content>
        </Modal>
      )}
    />
  );
};

export default DOTNumberModal;
