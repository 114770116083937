import httpClient from 'api/httpClient';
import { Review } from 'components/AgentRatingTable/AgentRatingTable.component';
import { User } from 'models/User';
import { ApiResponse } from 'types/ApiResponse';

const getReviews = (
  params: Record<
    string,
    string | number | string[] | User | Record<string, string[]>
  >,
) => {
  return httpClient.get<ApiResponse<Review>>('/reviews', {
    params: {
      ...params,
      $relations: ['agent', 'driver'],
    },
  });
};

export default { getReviews };
