import { Department } from 'types/Users';

export const departments: Department[] = [
  'Safety',
  'Eld',
  'Accounting',
  'Fleet',
  'Recruiting',
  'Driver Education',
];
