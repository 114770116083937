import Axios from 'axios';
import env from 'env';
import { toast } from 'react-toastify';
import credentialsService from 'services/credentialsService';
import history from '../common/history';

const httpClient = Axios.create({
  baseURL: `${env.SERVER_ENDPOINT}/api/`,
});

httpClient.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    //If the header does not contain the token and the url not public, redirect to login

    const { token } = credentialsService;
    if (token && config.method !== 'OPTIONS') {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

httpClient.interceptors.response.use(
  (result) => result,
  (error) => {
    if (error.response && [401, 403].includes(error.response.status)) {
      if (error.response.data?.error === 'Wrong username or password') {
        console.error(error.response.data);
      } else {
        credentialsService.removeAuthBody();
        history.push('/login');
      }
    }
    if (error.response) {
      toast.error('Request failed. Please try again or contact administrator.');
    }
    return Promise.reject(error);
  },
);

export default httpClient;
