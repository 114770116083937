import React from 'react';
import { Company } from 'components/EmploymentHistoryModal/EmploymentHisoryModal.component';
import { Field, useField } from 'react-final-form';
import './EmploymentHistoryFieldArray.style.scss';

type EmploymentHistoryFieldArrayProps = {
  companies?: Company[];
  values?: any;
};

type CompanyValue = {
  checked: boolean;
  value: string;
};

const EmploymentHistoryFieldArray = (
  props: EmploymentHistoryFieldArrayProps,
) => {
  const { companies, values } = props;

  const companiesValue = useField('companies');

  const handleChange = (checked: boolean) => {
    handleSelectAll(checked);
  };

  const handleSelectAll = (checked: boolean) => {
    const oldValue = values.companies;
    const newValue = oldValue.map((value: CompanyValue) => {
      return { ...value, checked };
    });
    companiesValue.input.onChange(newValue);
  };

  return (
    <div className="eh-fa">
      <p className="eh-fa__info">
        Please select companies you want to send Employment History document to
        and enter their emails if necessary.
      </p>
      <div className="eh-fa__select-all">
        <input
          type="checkbox"
          id="select-all"
          onChange={(event) => handleChange(event.target.checked)}
        />
        <label htmlFor="select-all">Select all</label>
      </div>
      {companies.map((company: Company, index: number) => {
        return (
          <div key={index} className="eh-fa__company">
            <div className="eh-fa__company__field">
              <Field
                name={`companies[${index}].checked`}
                id={`checked${index}`}
                component="input"
                type="checkbox"
              />
              <label htmlFor={`checked${index}`}>{company.label}</label>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default EmploymentHistoryFieldArray;
