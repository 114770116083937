import { apiDrivers } from 'api';
import { FormSelectOption } from 'components/FinalFormComponents/Select/FormSelect.component';
import { toast } from 'react-toastify';
import { Agent, Company, Department } from 'types/Users';

const SUCCESS_MESSAGE_SEND_EMAIL = 'Successfully sent email';
const ERROR_MESSAGE_SEND_EMAIL = 'Error occured while sending email';

export const isEmailValid = (email: string): boolean => {
  return !!email.match(
    /^[\w.!#$%&'*+\-/=?^_`{|}~]+@\w+\.(\w+\.)*?[a-zA-Z0-9]+$/,
  );
};

export const copyMagicLink = (
  magicLink: string,
  setCopiedToClipboard: (value: boolean) => void,
) => {
  navigator.clipboard.writeText(magicLink);
  setCopiedToClipboard(true);
  setTimeout(() => {
    setCopiedToClipboard(false);
  }, 2500);
};

export const sendEmail = async (
  driverId: string,
  setOpen: (value: boolean) => void,
  setLoading: (value: boolean) => void,
) => {
  setLoading(true);
  try {
    await apiDrivers.sendEmail(driverId);
    toast.success(SUCCESS_MESSAGE_SEND_EMAIL);
    setOpen(false);
  } catch (err) {
    setLoading(false);
    console.error(err);
    toast.error(ERROR_MESSAGE_SEND_EMAIL);
  }
};

export const mapCompaniesToSelectOptions = (companies: Company[]) => {
  return companies.reverse().map((company: any) => {
    return {
      key: company._id,
      text: company.name,
      value: company._id,
    };
  });
};
//TODO CHECK THIs
export const filterAgentsBasedOnDepartment = (
  agents: Agent[],
  department: Department,
) =>
  agents.filter(
    (agent) => agent.departments?.includes(department) && agent.isActive,
  );

export const mapAgentsToSelectOptions = (
  agents: Agent[],
  department: Department,
) =>
  agents.map(
    (agent) =>
      ({
        key: agent._id,
        value: agent._id + ':' + department,
        text: `${agent.firstName} ${agent.lastName}`,
      } as FormSelectOption),
  );

export const prepareAgentsSelectByDepartment = (agents: Agent[]) => {
  const eldAgents = filterAgentsBasedOnDepartment(agents, 'Eld');
  const safetyAgents = filterAgentsBasedOnDepartment(agents, 'Safety');
  const accountingAgents = filterAgentsBasedOnDepartment(agents, 'Accounting');
  const fleetAgents = filterAgentsBasedOnDepartment(agents, 'Fleet');
  const recruitingAgents = filterAgentsBasedOnDepartment(agents, 'Recruiting');
  const driverEducation = filterAgentsBasedOnDepartment(
    agents,
    'Driver Education',
  );

  return {
    Eld: mapAgentsToSelectOptions(eldAgents, 'Eld'),
    Fleet: mapAgentsToSelectOptions(fleetAgents, 'Fleet'),
    Accounting: mapAgentsToSelectOptions(accountingAgents, 'Accounting'),
    Safety: mapAgentsToSelectOptions(safetyAgents, 'Safety'),
    Recruiting: mapAgentsToSelectOptions(recruitingAgents, 'Recruiting'),
    'Driver Education': mapAgentsToSelectOptions(
      driverEducation,
      'Driver Education',
    ),
  };
};
