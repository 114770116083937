import {
  Button,
  Checkbox,
  Input,
  Popup,
  Select,
  Table,
  TextArea,
  DropdownItemProps,
  Form,
} from 'semantic-ui-react';

import React from 'react';
import set from 'lodash.set';
import get from 'lodash.get';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { Agent, Driver } from '../../../types/Users';
import { URL_REGEX } from 'common/regex';

import '../DriverDetails.styles.scss';
import classNames from 'classnames';

dayjs.extend(utc);

export const SCHEMA_ITEM_TYPES = {
  TEXT: 'text',
  NUMBER: 'number',
  DATE: 'date',
  DATE_TIME: 'date_time',
  SELECT: 'select',
  CHECKBOX: 'checkbox',
  EMAIL: 'email',
  MULTI_TEXT: 'multi_text',
  ARRAY: 'array',
  OBJECT: 'object',
  SEPARATOR: 'separator',
  PREVIEW: 'preview',
  VIRTUAL: 'virtual',
} as const;

export type SchemaItemType = typeof SCHEMA_ITEM_TYPES[keyof typeof SCHEMA_ITEM_TYPES];

export type PropertyValue = string | boolean | number | Date | any[];
type DefaultSchemaItem<T extends SchemaItemType> = {
  type: T;
  label?: string;
  validate?: (propertyValue: PropertyValue) => boolean;
  errorMessage?: string;
  required?: boolean;
  disabled?: boolean;
  propertyName?: string;
  shouldRender?: (
    propertyValue: PropertyValue,
    details: Driver | Agent,
  ) => boolean;
  onChange?: (
    propertyValue: PropertyValue,
    changedDetails: Driver | Agent,
  ) => Driver | Agent;
};

type TextSchemaItem = DefaultSchemaItem<'text'> & {
  textArea?: boolean;
};

type NumberSchemaItem = DefaultSchemaItem<'number'> & {
  min?: number;
  max?: number;
};
type PreviewSchemaItem = DefaultSchemaItem<'preview'>;
type EmailSchemaItem = DefaultSchemaItem<'email'>;
type MultiTextSchemaItem = DefaultSchemaItem<'multi_text'>;
type DateSchemaItem = DefaultSchemaItem<'date'>;
type CheckboxSchemaItem = DefaultSchemaItem<'checkbox'>;
type DateTimeSchemaItem = DefaultSchemaItem<'date_time'>;
type SeparatorSchemaItem = DefaultSchemaItem<'separator'>;
type VirtualSchemaItem = DefaultSchemaItem<'virtual'> & {
  getter: (driver: Driver | Agent) => React.ReactNode;
};

type SelectSchemaItem = DefaultSchemaItem<'select'> & {
  options:
    | Array<DropdownItemProps>
    | ((
        propertyValue: PropertyValue,
        driver: Driver | Agent,
      ) => DropdownItemProps[]);
  multi?: boolean;
};

type ObjectSchemaItem = DefaultSchemaItem<'object'> & {
  children: Schema;
};

type ArraySchemaItem = DefaultSchemaItem<'array'> & {
  children: Schema;
  shouldRenderAddDeleteButtons?: boolean;
};
export type SchemaItem =
  | TextSchemaItem
  | PreviewSchemaItem
  | NumberSchemaItem
  | EmailSchemaItem
  | MultiTextSchemaItem
  | DateSchemaItem
  | DateTimeSchemaItem
  | CheckboxSchemaItem
  | SelectSchemaItem
  | ObjectSchemaItem
  | ArraySchemaItem
  | VirtualSchemaItem
  | SeparatorSchemaItem;

export type Schema = Record<string, SchemaItem>;

export function generateSchemaTable(
  schema: Schema,
  details: Driver | Agent,
  setDetails: (details: Driver | Agent) => void,
  errors: any,
  path?: string,
  isDeletedDriver?: boolean,
) {
  return Object.keys(schema).map((_pName, index) => {
    const schemaPropName = _pName;

    const schemaItem = schema[schemaPropName];
    const {
      type,
      label,
      validate = () => true,
      shouldRender = () => true,
      required = true,
      disabled = false,
      onChange: propsOnChange,
      propertyName: propsPropName,
      errorMessage = '',
    } = schemaItem;

    const propertyName = propsPropName || _pName;

    const fullPropertyPath = path ? `${path}.${propertyName}` : propertyName;
    const propertyValue = get(details, fullPropertyPath);

    if (!validate(propertyValue)) {
      set(errors, fullPropertyPath, errorMessage);
    } else {
      delete errors[fullPropertyPath];
    }

    const error = errors ? get(errors, fullPropertyPath) : null;

    if (shouldRender && !shouldRender(propertyValue, details)) {
      return null;
    }

    const onChange = (newValue: PropertyValue) => {
      const newDriver = JSON.parse(JSON.stringify(details));
      const changed = set<Driver>(newDriver, fullPropertyPath, newValue);
      setDetails(propsOnChange ? propsOnChange(newValue, changed) : changed);
    };

    let valueCell = propertyValue;
    let tableRow = null;

    switch (type) {
      case SCHEMA_ITEM_TYPES.SEPARATOR:
        tableRow = (
          <Table.Row key={propertyValue + label}>
            <Table.Cell colSpan="2">
              <h2>{label}</h2>
            </Table.Cell>
          </Table.Row>
        );
        break;
      case SCHEMA_ITEM_TYPES.TEXT:
        const { textArea = false } = schemaItem as TextSchemaItem;
        valueCell = textArea ? (
          <TextArea
            value={propertyValue ? propertyValue : ''}
            type="text"
            disabled={isDeletedDriver || disabled}
            onChange={(_, field) => onChange(field.value)}
          />
        ) : (
          <Input
            value={propertyValue ? propertyValue : ''}
            type="text"
            disabled={isDeletedDriver || disabled}
            onChange={(_, field) => onChange(field.value)}
          />
        );
        break;
      case SCHEMA_ITEM_TYPES.MULTI_TEXT:
        const propValue = propertyValue as string[];
        valueCell = (
          <React.Fragment>
            {propValue.map((value, index) => (
              <div key={index}>
                <Input
                  value={value ? value : ''}
                  type="text"
                  disabled={isDeletedDriver || disabled}
                  onChange={(_, field) =>
                    onChange(
                      propValue.map((val, ind) =>
                        index === ind ? field.value : val,
                      ),
                    )
                  }
                />
                <Button
                  style={{ float: 'right' }}
                  disabled={isDeletedDriver || disabled}
                  onClick={() =>
                    onChange(
                      propValue.filter((_, arrayIndex) => arrayIndex !== index),
                    )
                  }
                >
                  Delete
                </Button>
              </div>
            ))}
            <Button onClick={() => onChange([...propValue, ''])}>Add</Button>
          </React.Fragment>
        );
        break;
      case SCHEMA_ITEM_TYPES.EMAIL:
        valueCell = (
          <Input
            value={propertyValue ? propertyValue.trim() : ''}
            type="email"
            disabled={isDeletedDriver || disabled}
            onChange={(_, field) => {
              onChange(field.value);
            }}
          />
        );
        break;
      case SCHEMA_ITEM_TYPES.NUMBER:
        const { min, max } = schemaItem as NumberSchemaItem;
        valueCell = (
          <Input
            type="number"
            min={min}
            max={max}
            value={propertyValue ? propertyValue : 0}
            disabled={isDeletedDriver || disabled}
            onChange={(_, field) => {
              onChange(+field.value);
            }}
          />
        );
        break;
      case SCHEMA_ITEM_TYPES.CHECKBOX:
        valueCell = (
          <Checkbox
            checked={propertyValue ? true : false}
            disabled={isDeletedDriver || disabled}
            onClick={() => {
              onChange(!propertyValue);
            }}
          />
        );
        break;
      case SCHEMA_ITEM_TYPES.SELECT: {
        const {
          options: optionsGetter,
          multi = false,
        } = schemaItem as SelectSchemaItem;

        const options =
          typeof optionsGetter === 'function'
            ? optionsGetter(propertyValue, details)
            : optionsGetter;
        valueCell = (
          <Select
            lazyLoad
            value={propertyValue}
            search={true}
            options={options}
            disabled={isDeletedDriver || disabled}
            multiple={multi}
            onChange={(_, field) => {
              onChange(field.value);
            }}
          />
        );
        break;
      }

      case SCHEMA_ITEM_TYPES.DATE:
        const format = 'YYYY-MM-DD';
        valueCell = (
          <Form.Input
            type="date"
            value={
              propertyValue
                ? dayjs(propertyValue).utc().format(format)
                : undefined
            }
            disabled={isDeletedDriver || disabled}
            onChange={(_, field) => {
              const currentTz = dayjs().format('Z');
              const value = dayjs(`${field.value}${currentTz}`, `${format}Z`)
                .utc(true)
                .format('YYYY-MM-DDT00:00:00[Z]');
              onChange(value);
            }}
          />
        );
        break;
      case SCHEMA_ITEM_TYPES.DATE_TIME:
        valueCell = (
          <Form.Input
            type="datetime-local"
            value={propertyValue}
            disabled={isDeletedDriver || disabled}
            onChange={(_, field) => onChange(field.value)}
          />
        );
        break;
      case SCHEMA_ITEM_TYPES.OBJECT: {
        const { children } = schemaItem as ObjectSchemaItem;
        valueCell = (
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={1}>Name</Table.HeaderCell>
                <Table.HeaderCell width={2}>Value</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {generateSchemaTable(
                children,
                details,
                setDetails,
                errors,
                fullPropertyPath,
                isDeletedDriver,
              )}
            </Table.Body>
          </Table>
        );
        break;
      }
      case SCHEMA_ITEM_TYPES.ARRAY: {
        const {
          children,
          shouldRenderAddDeleteButtons = true,
        } = schemaItem as ArraySchemaItem;
        if (propertyValue) {
          const fields = propertyValue.map((arrayData: any, index: number) => {
            return (
              <React.Fragment key={index}>
                <Table>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell width={1}>Name</Table.HeaderCell>
                      <Table.HeaderCell width={2}>Value</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {generateSchemaTable(
                      children,
                      details,
                      setDetails,
                      errors,
                      `${fullPropertyPath}.${index}`,
                      isDeletedDriver,
                    )}
                  </Table.Body>
                </Table>
                {shouldRenderAddDeleteButtons && (
                  <Button
                    style={{ float: 'right' }}
                    disabled={isDeletedDriver || disabled}
                    onClick={() =>
                      onChange(
                        propertyValue.filter(
                          (_: any, arrayIndex: number) => arrayIndex !== index,
                        ),
                      )
                    }
                  >
                    Delete
                  </Button>
                )}
              </React.Fragment>
            );
          });
          valueCell = (
            <React.Fragment>
              <div>{fields}</div>
              {shouldRenderAddDeleteButtons && (
                <Button
                  disabled={isDeletedDriver || disabled}
                  onClick={() =>
                    onChange([...propertyValue, getEmptySchemaObject(children)])
                  }
                >
                  Add
                </Button>
              )}
            </React.Fragment>
          );
        } else {
          valueCell = null;
        }
        break;
      }
      case SCHEMA_ITEM_TYPES.VIRTUAL:
        const { getter } = schemaItem as VirtualSchemaItem;
        valueCell = <span>{getter(details)}</span>;
        break;
      default:
        switch (typeof propertyValue) {
          case 'boolean':
            valueCell = propertyValue ? 'Yes' : 'No';
            break;
          case 'object':
            valueCell = Array.isArray(propertyValue)
              ? propertyValue.join(', ')
              : JSON.stringify(propertyValue);
            break;
          default:
            if (propertyValue === null || propertyValue === undefined) {
              valueCell = '';
              break;
            }
            if (!Number.isNaN(+propertyValue)) {
              valueCell = propertyValue;
              break;
            }
            if (dayjs(propertyValue).isValid()) {
              valueCell = new Date(propertyValue).toLocaleString();
              break;
            }

            if (URL_REGEX.test(propertyValue)) {
              valueCell = (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={propertyValue}
                >
                  {propertyValue}
                </a>
              );
              break;
            }
            valueCell = propertyValue;
        }

        break;
    }

    if (tableRow) {
      return tableRow;
    }

    const nameCell = (
      <span>
        {label}
        {required === false ? ' (optional)' : null}
      </span>
    );

    return (
      <Table.Row key={index}>
        {error ? (
          <Popup
            content={error}
            trigger={<Table.Cell error>{nameCell}</Table.Cell>}
          />
        ) : (
          <Table.Cell>{nameCell}</Table.Cell>
        )}
        <Table.Cell
          className={classNames({
            'overflow-initial-cell':
              SCHEMA_ITEM_TYPES.SELECT === type ||
              SCHEMA_ITEM_TYPES.OBJECT === type,
          })}
        >
          {valueCell}
        </Table.Cell>
      </Table.Row>
    );
  });
}

export function getEmptySchemaObject(schema: Schema) {
  const driver = {};
  Object.keys(schema).forEach((schemaPropertyKey) => {
    processSchemaItem(driver, schema, schemaPropertyKey);
  });
  return driver;
}

function processSchemaItem(driver: any, schema: Schema, schemaKey: string) {
  switch (schema[schemaKey].type) {
    case SCHEMA_ITEM_TYPES.VIRTUAL: // if separator/virtual just skip
    case SCHEMA_ITEM_TYPES.SEPARATOR:
      return;
    case SCHEMA_ITEM_TYPES.OBJECT: {
      // if nested go recursively
      const schemaItem = schema[schemaKey] as ObjectSchemaItem;
      driver[schemaKey] = {};
      Object.keys(schemaItem.children).forEach((key) => {
        processSchemaItem(driver[schemaKey], schemaItem.children, key);
      });
      break;
    }
    case SCHEMA_ITEM_TYPES.ARRAY: // if any type of array just init to array
    case SCHEMA_ITEM_TYPES.SELECT:
    case SCHEMA_ITEM_TYPES.MULTI_TEXT:
      driver[schemaKey] = [];
      return;
    default:
      driver[schemaKey] = undefined;
      break;
  }
}
