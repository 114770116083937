import React, { useEffect, useState, useCallback } from 'react';
import { Button, Table } from 'semantic-ui-react';
import Tippy from 'components/Tippy';
import './EmploymentHistoryTable.style.scss';
import enums from 'enums';
import { EmploymentHistory, EvidenceFiles } from 'types';
import EmploymentHistoryModal from 'components/EmploymentHistoryModal';
import bus, { EventName } from 'modules/bus';
import { BUS_EVENTS } from 'modules/bus/busEvents';
import { toast } from 'react-toastify';
import { apiDrivers } from 'api';
import DOTNumberModal from 'components/DOTNumberModal';
import { formatedDate } from 'services/dateService';
import EmploymentHistoryDocuments from 'components/EmploymentHistoryDocuments';
import ConfirmationModal from 'components/ConfirmationModal';

const { EMPLOYMENT_HISTORY_DOCUMENT_STATUS_COLORS } = enums;

const EmploymentHistoryTable = (props: any) => {
  const {
    driver: { _id, employmentHistory, companyInfo, isDeleted },
  } = props;

  const [employmentHistoryState, setEmploymentHistoryState] = useState<
    EmploymentHistory[]
  >(employmentHistory);

  useEffect(() => {
    const employmentHistoryStatusChanged = (args: any) => {
      const { payload } = args;
      if (payload.driverId !== _id) {
        return;
      }

      setEmploymentHistoryState((oldVal) => {
        const mappedEmplHistory = oldVal.map((eh) =>
          eh._id === payload.employmentHistoryId
            ? {
                ...eh,
                status: payload.status,
                companySignedUrl: payload.companySignedUrl,
                driverSignedUrl: payload.driverSignedUrl,
              }
            : eh,
        );

        const newEmplHistory = mappedEmplHistory.some(
          (eh) => eh._id === payload.employmentHistoryId,
        );
        if (!newEmplHistory)
          return [...oldVal, { ...payload, _id: payload.employmentHistoryId }];
        return mappedEmplHistory;
      });
    };

    bus.addEventListener(
      BUS_EVENTS.EH_STATUS_CHANGED as EventName,
      employmentHistoryStatusChanged,
    );

    return () => {
      bus.removeEventListener(
        BUS_EVENTS.EH_STATUS_CHANGED as EventName,
        employmentHistoryStatusChanged,
      );
    };
  }, [_id]);

  const handleDocumentUpload = async (
    employmentHistoryId: string,
    values: any,
    setEvidenceFiles: React.Dispatch<React.SetStateAction<EvidenceFiles[]>>,
  ) => {
    const { files } = values;

    try {
      const {
        data: {
          employmentHistory: { evidenceFiles: evidenceFilesAPI },
        },
      } = await apiDrivers.uploadEmploymentHistory(employmentHistoryId, files);

      if (files.length === 1)
        toast.success(`"${files[0].name}" is successfully uploaded.`);
      else {
        let concatedFileNames = '';
        let filename = '';
        files.map((file: { name: string }, index: number) => {
          if (index === files.length - 1) {
            return (concatedFileNames += filename);
          } else {
            filename = file.name;
            return (concatedFileNames += filename += ', ');
          }
        });
        toast.success(`"${concatedFileNames}" are successfully uploaded.`);
      }

      setEvidenceFiles(evidenceFilesAPI);
    } catch (error) {
      toast.error(
        'Error occured. Employment history document was not uploaded. Please try again, or contact the administrator.',
      );
      console.error(error);
    }
  };

  const handleDeleteEmploymentHistory = useCallback(
    async (employmentHistoryId: string) => {
      try {
        const {
          data: {
            employmentHistory: { id: deletedEmploymentHistoryId, name },
          },
        } = await apiDrivers.deleteEmploymentHistory(_id, employmentHistoryId);

        setEmploymentHistoryState((oldEmploymenyHistoryArray) =>
          oldEmploymenyHistoryArray.filter(
            (eh) => eh._id !== deletedEmploymentHistoryId,
          ),
        );

        toast.success(`"${name}" is successfully deleted.`);
      } catch (err) {
        console.error(err);
      }
    },
    [_id],
  );

  return (
    <div className="eh-container">
      <div>
        <h3>{companyInfo?.displayName}</h3>
        <div className="eh-container__top">
          <DOTNumberModal
            driver={props.driver}
            trigger={
              <Button
                disabled={isDeleted}
                primary
                type="button"
                content="Add New Company"
              />
            }
            employmentHistoryState={employmentHistoryState}
          />

          <EmploymentHistoryModal
            driverId={_id}
            companies={employmentHistoryState?.map((company) => {
              return {
                id: company._id || company?.employmentHistoryId,
                label: company.name,
                value: company.email,
              };
            })}
            trigger={
              <Button
                disabled={
                  isDeleted || !employmentHistory || !employmentHistory.length
                }
                primary
                content="Verify Employment History"
              />
            }
          />
        </div>
      </div>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Company Name</Table.HeaderCell>
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.HeaderCell>Date From</Table.HeaderCell>
            <Table.HeaderCell>Date To</Table.HeaderCell>
            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {employmentHistoryState.map((eh: EmploymentHistory) => (
            <Table.Row key={eh._id}>
              <Table.Cell>{eh.name}</Table.Cell>
              <Table.Cell>
                {eh.status && (
                  <label
                    className={`${
                      EMPLOYMENT_HISTORY_DOCUMENT_STATUS_COLORS[eh.status]
                    } status-label`}
                  >
                    {eh.status}
                  </label>
                )}
              </Table.Cell>
              <Table.Cell>{formatedDate(eh.from)}</Table.Cell>
              <Table.Cell>{formatedDate(eh.to)}</Table.Cell>
              <Table.Cell>
                <div className="button-group">
                  <Tippy title="Employment history documents">
                    <EmploymentHistoryDocuments
                      driver={props.driver}
                      employmentHistory={eh}
                      handleDocumentUpload={handleDocumentUpload}
                      trigger={
                        <Button color="blue" icon="file archive outline" />
                      }
                    />
                  </Tippy>
                  <Tippy title="Delete employment history">
                    <ConfirmationModal
                      header="Deleting employment history"
                      message={`Are you sure you want to delete employment history for "${eh.name}" company?`}
                      action={() => handleDeleteEmploymentHistory(eh._id)}
                      trigger={<Button color="red" icon="trash alternate" />}
                      color="red"
                      buttonContent="Delete"
                      buttonIcon="trash"
                      isDeleteModal
                    />
                  </Tippy>
                </div>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  );
};

export default EmploymentHistoryTable;
