import React, { useState, useMemo, useCallback } from 'react';
import { apiDrivers } from 'api';
import ConfirmationModal from 'components/ConfirmationModal';
import TableDocumentsHeader from 'components/TableDocumentsHeader';
import Tippy from 'components/Tippy';
import UploadDocumentModal from 'components/UploadDocumentModal/UploadDocumentModal.component';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Button, Dimmer, Loader, Modal, Table } from 'semantic-ui-react';
import { formatedDate } from 'services/dateService';
import { State } from 'store/reducers';
import { EmploymentHistory, EvidenceFiles } from 'types';
import { Driver } from 'types/Users';
import './EmploymentHistoryDocuments.styles.scss';

type EmploymentHistoryDocumentsProps = {
  driver: Driver;
  employmentHistory: EmploymentHistory;
  handleDocumentUpload?: (
    employmentHistoryId: string,
    values: any,
    setEvidenceFiles?: React.Dispatch<React.SetStateAction<EvidenceFiles[]>>,
  ) => Promise<void>;
  trigger?: React.ReactNode;
};

const EmploymentHistoryDocuments: React.FC<EmploymentHistoryDocumentsProps> = ({
  driver,
  employmentHistory,
  handleDocumentUpload,
  trigger,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [evidenceFiles, setEvidenceFiles] = useState<EvidenceFiles[]>(
    employmentHistory?.evidenceFiles,
  );

  const signedFiles = useMemo(
    () => [
      { title: "Driver's signature", url: employmentHistory.driverSignedUrl },
      { title: 'Company signature', url: employmentHistory.companySignedUrl },
    ],
    [employmentHistory.companySignedUrl, employmentHistory.driverSignedUrl],
  );

  const { roles } = useSelector((state: State) => state.currentUser);

  const isAdmin = useMemo(() => roles.includes('Admin'), [roles]);

  const adminOpportunities = useMemo(() => {
    const headerArray = ['Filename', 'Content', 'Created At', 'Uploaded By'];
    if (isAdmin) headerArray.push('Actions');
    return headerArray;
  }, [isAdmin]);

  const handleDeleteEvidenceFile = useCallback(
    async (fileId: string, filename: string) => {
      try {
        setLoading(true);
        await apiDrivers.deleteEvidenceFile(employmentHistory._id, fileId);

        setEvidenceFiles((oldFiles) =>
          oldFiles.filter(({ id }) => id !== fileId),
        );
        toast.success(`Successfully deleted "${filename}".`);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    },
    [employmentHistory._id],
  );
  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={trigger}
      closeIcon
    >
      <Modal.Header>{employmentHistory.name}</Modal.Header>
      <Modal.Content>
        {loading && (
          <Dimmer inverted active>
            <Loader>Sending request...</Loader>
          </Dimmer>
        )}
        {signedFiles.map(({ title, url }) => (
          <div key={url} className="signatures">
            <p>{title}</p>
            <Tippy title="Preview">
              <Button
                icon="file pdf"
                as="a"
                target="_blank"
                rel="noopener noreferrer"
                disabled={!url}
                href={url}
              />
            </Tippy>
          </div>
        ))}
        <h2>Employment history documents</h2>
        <div className="eh-documents">
          <p>Upload employment history documents here</p>
          <Tippy title="Upload documents">
            <UploadDocumentModal
              header={`Upload "Employment History" document`}
              instructions={`Drop your file or click here to browse. ('.pdf')`}
              submitButtonText={'Upload file'}
              maxFiles={5}
              accept={['.png', '.jpeg', '.pdf']}
              multiple
              modalTrigger={
                <Button
                  color="green"
                  icon="upload"
                  type="button"
                  disabled={driver.isDeleted}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                />
              }
              onDocumentUpload={(values) =>
                handleDocumentUpload(
                  employmentHistory._id,
                  values,
                  setEvidenceFiles,
                )
              }
            />
          </Tippy>
        </div>
        {!evidenceFiles || !evidenceFiles.length ? (
          <p>No files uploaded for this employment history.</p>
        ) : (
          <Table celled>
            <TableDocumentsHeader headerCells={adminOpportunities} />
            <Table.Body>
              {evidenceFiles?.map(({ id, url, name, createdAt, agent }) => (
                <Table.Row key={id}>
                  <Table.Cell>
                    <p>{name}</p>
                  </Table.Cell>
                  <Table.Cell>
                    <a
                      className="preview"
                      href={url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Preview
                    </a>
                  </Table.Cell>

                  <Table.Cell>{formatedDate(createdAt)}</Table.Cell>
                  <Table.Cell>
                    <p>{agent?.fullName}</p>
                  </Table.Cell>
                  {isAdmin && (
                    <Table.Cell>
                      <Tippy title="Delete file">
                        <ConfirmationModal
                          header="Deleting document"
                          message="Are you sure you want to delete this file?"
                          action={() => handleDeleteEvidenceFile(id, name)}
                          trigger={<Button color="red" icon="delete" />}
                          color="red"
                          buttonContent="Delete"
                          buttonIcon="trash"
                          isDeleteModal
                        />
                      </Tippy>
                    </Table.Cell>
                  )}
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button color="black" onClick={() => setOpen(false)}>
          Cancel
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default EmploymentHistoryDocuments;
