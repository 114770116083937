import React, { useCallback, useState, useEffect } from 'react';
import './ConfirmationModal.styles.scss';
import {
  Modal,
  Header,
  Message,
  Button,
  Loader,
  Dimmer,
  SemanticCOLORS,
} from 'semantic-ui-react';
import classNames from 'classnames';

type ConfirmationModalProps = {
  trigger: JSX.Element;
  header?: string;
  message: string;
  messageHeader?: string;
  className?: string;
  action: () => void;
  isDeleteModal?: boolean;
  color?: SemanticCOLORS;
  buttonContent?: React.ReactNode;
  buttonIcon?: string;
};
const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  trigger,
  header,
  message,
  action,
  messageHeader = 'Please confirm',
  className,
  isDeleteModal,
  color = 'blue',
  buttonContent = 'Confirm',
  buttonIcon = 'checkmark',
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const resetStates = () => {
    setLoading(false);
  };

  useEffect(() => {
    if (!open) {
      return;
    }
    return resetStates;
  }, [open]);

  const handleConfirmation = useCallback(async () => {
    try {
      setLoading(true);
      await action();
    } catch (err) {
      console.error(err);
    } finally {
      setOpen(false);
    }
  }, [action]);

  const modalClassNames = classNames(className, 'modal');

  return (
    <Modal
      className={modalClassNames}
      closeIcon
      onClose={() => !loading && setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={trigger}
    >
      <Header>{header || 'Confirmation'}</Header>
      <Modal.Content className="modal__content">
        {loading && (
          <Dimmer inverted active>
            <Loader>Sending request...</Loader>
          </Dimmer>
        )}
        {isDeleteModal ? (
          <Message color={color}>
            <p className="confirmation-message">{message}</p>
          </Message>
        ) : (
          <Message info>
            <Message.Header>{messageHeader}</Message.Header>
            <p className="confirmation-message">{message}</p>
          </Message>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button color="black" disabled={loading} onClick={() => setOpen(false)}>
          Cancel
        </Button>
        <Button
          content={buttonContent}
          labelPosition="right"
          icon={buttonIcon}
          onClick={handleConfirmation}
          color={color}
          disabled={loading}
        />
      </Modal.Actions>
    </Modal>
  );
};
export default ConfirmationModal;
