import { apiCompanies } from 'api';
import enums from '../../../enums';

const { DRIVER_STATUS } = enums;
const { getCompanies } = apiCompanies;

export const defaultFilterValues = Object.values(DRIVER_STATUS);

export const statusFilterOptions = Object.values(DRIVER_STATUS).map((value) => {
  return { value: value, key: value, text: value };
});

export const getCompanySelectOptions = async () => {
  try {
    const { data } = await getCompanies();
    return data.items.map((item: any) => {
      return {
        key: item._id,
        text: item.name,
        value: item._id,
      };
    });
  } catch (err) {
    console.error(err);
  }
};
