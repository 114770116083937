import Home from 'pages/Home';
import Login from 'pages/Login';
import SubRouter from 'router/subrouters/Sub';
import { EnhancedRouteProps } from './routes/Enhanced/Enhanced.route';
import redirect from 'router/modules/redirect';
import ForgotPassword from 'pages/ForgotPassword';
import ResetPassword from 'pages/ResetPassoword/ResetPassword.page';
import VerifyEmail from 'pages/VerifyEmail';

export default [
  {
    path: '/login',
    restriction: 'public',
    component: Login,
  },
  {
    path: '/forgot-password',
    restriction: 'public',
    component: ForgotPassword,
  },
  {
    path: '/reset-password',
    restriction: 'public',
    component: ResetPassword,
  },
  {
    path: '/verify-email',
    restriction: 'none',
    component: VerifyEmail,
  },
  {
    path: '/',
    component: Home,
    restriction: 'private',
    exact: false,
  },
  {
    path: '/sub',
    component: SubRouter,
    exact: false,
  },
  {
    path: '*',
    component: redirect('/', true),
  },
] as Array<EnhancedRouteProps>;
