export const HIRE_CONFIRMATION_HEADER = {
  message: 'For Company Use',
  editMessage: 'For Company Use',
};
export const HIRE_CONFIRMATION_MESSAGE_HEADER = {
  message: 'Hiring ',
  editMessage: 'Edit For-Company-Use Document of ',
};
export const HIRE_CONFIRMATION_MESSAGE = {
  message:
    "Please note that filling in this document will update driver's status to Hired. Once filled in you'll still have the option to override the data entered by filling it in as many times as you like. Only the last version of the document will be saved.",
  editMessage:
    "Please note that filling in this document will update driver's For-Company-Use document. Once filled in you'll still have the option to override the data entered by filling it in as many times as you like. Only the last version of the document will be saved.",
};
export const HIRE_CONFIRMATION_ACTION_MESSAGES = {
  succes: {
    message: 'Succesfully sent hiring request.',
    editMessage:
      'Succesfully sent request for editing For-Company-Use Document.',
  },
  error: {
    message: "Error occured. Request for hiring wasn't sent.",
    editMessage:
      "Error occured. Request for editing For-Company-Use Document wasn't sent.",
  },
};
