import React from 'react';
import { Table } from 'semantic-ui-react';
export interface TableDocumentsHeaderProps {
  headerCells?: string[];
}

const TableDocumentsHeader: React.FC<TableDocumentsHeaderProps> = (
  props: TableDocumentsHeaderProps,
) => {
  const { headerCells = ['Name', 'Action'] } = props;
  return (
    <Table.Header>
      <Table.Row>
        {headerCells.map((cell, index) => (
          <Table.HeaderCell key={index}>{cell}</Table.HeaderCell>
        ))}
      </Table.Row>
    </Table.Header>
  );
};

export default TableDocumentsHeader;
