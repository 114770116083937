export const federalTaxes = [
  {
    label: 'Individual/sole proprietor or single-member LLC',
    radioValue: 'individual',
  },
  {
    label: 'C Corporation',
    radioValue: 'cCorporation',
  },
  {
    label: 'S Corporation',
    radioValue: 'sCorporation',
  },
  {
    label: 'Partnership',
    radioValue: 'partnership',
  },
  {
    label: 'Trust/estate',
    radioValue: 'trust',
  },
  {
    label: 'Limited liability company',
    radioValue: 'limitedLiability',
  },
  {
    label: 'Other',
    radioValue: 'other',
  },
];

export const contractTemplate = [
  { label: 'Old contract template', radioValue: 'old' },
  { label: '75%', radioValue: '75' },
  { label: '80%', radioValue: '80' },
];
