import React, { Fragment, useCallback, useState, useMemo } from 'react';
import './Home.styles.scss';
import NavigationMenu from '../../components/NavigationMenu';
import { navigationOptions, headerOptions } from './utils';
import Header from 'components/Header/Header.component';
import routes from './routes';
import Routes from 'router/components/Routes';
import classNames from 'classnames';
import history from 'common/history';
import { Button, Loader } from 'semantic-ui-react';
import { State } from 'store/reducers';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { apiAuth } from 'api';
import credentialsService from 'services/credentialsService';

const Home: React.FC = () => {
  const [isDarkTheme, setIsDarkTheme] = useState(true);
  const [navigationMenuVisibility, setNavigationMenuVisibility] = useState(
    false,
  );
  const [isDisabledVerify, setIsDisabledVerify] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const {
    email,
    isVerified: isVerifiedRedux,
    roles,
    departments,
  } = useSelector((state: State) => state.currentUser);

  const isAdmin = useMemo(() => roles.includes('Admin'), [roles]);

  const user = credentialsService.user;

  const isVerifiedStorage = user?.isVerified;

  const isVerified = isVerifiedRedux || isVerifiedStorage;

  const homeClassNames = classNames('home', { dark: isDarkTheme });

  const urlPathParams = history.location.pathname.split('/');

  const renderOnlyOrientation = useMemo(
    () => !isAdmin && !departments.includes('Safety'),
    [departments, isAdmin],
  );

  const shouldRenderNavigationMenu =
    urlPathParams.includes('applicants') ||
    urlPathParams.includes('drivers') ||
    urlPathParams.includes('orientation');

  const sendVerificationMail = useCallback(async () => {
    try {
      setLoading(true);
      await apiAuth.sendVerificationMail(email);
      toast.success(
        `A verification email has been sent to ${email}. Please check your email.`,
      );
    } catch (err) {
      toast.error(err);
    } finally {
      setLoading(false);
      setIsDisabledVerify(true);
      setTimeout(() => setIsDisabledVerify(false), 10000);
    }
  }, [email, setLoading]);

  return (
    <div className={homeClassNames}>
      <Header
        options={headerOptions['Users'].options}
        navigationMenuVisibility={navigationMenuVisibility}
        setNavigationMenuVisibility={setNavigationMenuVisibility}
        renderOnlyOrientation={renderOnlyOrientation}
      />

      <div className="home__content">
        {shouldRenderNavigationMenu && (
          <NavigationMenu
            options={navigationOptions}
            visible={navigationMenuVisibility}
            setVisible={setNavigationMenuVisibility}
            setIsDarkTheme={setIsDarkTheme}
            isDarkTheme={isDarkTheme}
          />
        )}
        <div className="home__content__banner">
          {!renderOnlyOrientation && roles.includes('Agent') && !isVerified && (
            <div className="home__content__banner__content">
              Your email is not verified. Please verify your email address. We
              will send you a link via email, please click the button to verify
              your email address.
              <Button
                size="tiny"
                color="blue"
                disabled={isDisabledVerify}
                onClick={sendVerificationMail}
              >
                {loading ? (
                  <Loader size="tiny" active />
                ) : (
                  <Fragment>Verify</Fragment>
                )}
              </Button>
            </div>
          )}

          <Routes routes={routes} />
        </div>
      </div>
    </div>
  );
};
export default Home;
