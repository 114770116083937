import React from 'react';
import './NotificationCard.styles.scss';
import { Icon } from 'semantic-ui-react';
import classNames from 'classnames';
import Tippy from 'components/Tippy';
import { humanizeDate } from 'utils';
import { apiNotifications } from 'api';

const NotificationCard = (props: any) => {
  const {
    notification: { seenBy, createdAt, agent, _id, text },
    handleSetNotifications,
  } = props;

  const notificationClassNames = classNames('notification__text', {
    'notification__text--unread': !seenBy,
  });

  const handleSeen = async (id: string) => {
    try {
      const { data } = await apiNotifications.markAsRead(id);
      handleSetNotifications(data.notification);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="notification">
      <div className={notificationClassNames}>
        <div>{text}</div>
        <div className="notification__text__additional">
          {humanizeDate(createdAt)}
        </div>
        {seenBy && (
          <div className="notification__text__additional">
            Read by {agent.username}
          </div>
        )}
      </div>
      <div className="notification__option">
        {!seenBy && (
          <Tippy title="Mark as read">
            <Icon name="circle thin" onClick={() => handleSeen(_id)} />
          </Tippy>
        )}
      </div>
    </div>
  );
};

export default NotificationCard;
