import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Icon, Input, Radio, Table } from 'semantic-ui-react';
import Tippy from 'components/Tippy';
import ConfirmationModal from 'components/ConfirmationModal';
import { Course, CourseStatus, Department, Driver } from 'types/Users';
import useFilterParams from 'hooks/useFilterParams';
import { apiAgents, apiDrivers } from 'api';
import { useSelector } from 'react-redux';
import { State } from 'store/reducers';
import {
  updateDriver,
  prepareCoursesForUpdate,
  filterDriverWithId,
} from './utilis';
import { User } from 'models/User';
import TableNavigation from 'components/TableNavigation';
import enums from '../../enums';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import StartCoursesAdminModal from 'components/StartCoursesAdminModal';
import { getCoursesWithGivenStatus } from 'components/FinishCoursesModal/utilits';
import FinishCoursesModal from 'components/FinishCoursesModal';
import SendReviewMailsModal from 'components/SendReviewMailsModal';
import { NavLink } from 'react-router-dom';
import bus, { EventName } from 'modules/bus';
import { BUS_EVENTS } from 'modules/bus/busEvents';
import { DoubleCheckIcon } from 'components/Icons/DoubleCheck.icon';

import './OrientationTable.styles.scss';
import ChangeInstructorModal from 'components/ChangeInstructorModal';
import { useFetchWithLimit } from '../../hooks/useFetchWithLimit';
import { departments as courseKeys } from 'constants/agents';

const { ACTION_MESSAGES } = enums;

const REVIEW_MODAL_HEADER = 'Select instructors to be reviewed';
const FINISH_COURSES_HEADER = 'Select courses to finish';
const START_COURSES_HEADER = 'Assign instructors';

const OrientationTable: React.FC = () => {
  const [selectedDriver, setSelectedDriver] = useState<Driver>(null);
  const [
    selectedDriverForStartCourses,
    setSelectedDriverForStartCourses,
  ] = useState<Driver>(null);
  const [
    selectedDriverForSendReviewMail,
    setSelectedDriverForSendReviewMail,
  ] = useState<Driver>(null);
  const [
    selectedDriverForChangeInstructor,
    setSelectedDriverForChangeInstructor,
  ] = useState<Driver>(null);

  const [modalHeader, setModalHeader] = useState('');
  const [coursesStatus, setCoursesStatus] = useState<CourseStatus>('Free');
  const [course, setCourse] = useState<Course>(null);
  const [hasFinishedAllReviews, setHasFinishedAllReviews] = useState<boolean>();

  const currentUser = useSelector((state: State) => state.currentUser);

  const isAdmin = useMemo(() => currentUser.roles.includes('Admin'), [
    currentUser,
  ]);

  const {
    loading,
    pagination,
    searchValue,
    items: drivers,
    setItems: setDrivers,
    setSearchValue,
  } = useFilterParams({
    fallbackRoute: '/drivers',
    requestFunc: apiDrivers.loadOrientations,
    hasFinishedReview: hasFinishedAllReviews,
  });

  const tableClasses = classNames(
    'orientation-table',
    loading && 'orientation-table--loading',
  );

  const filterCoursesBasedOnRole = useMemo(
    () =>
      courseKeys.filter(
        (courseKey) =>
          isAdmin || currentUser.departments.includes(courseKey as Department),
      ),
    [currentUser.departments, isAdmin],
  );

  //course-status-changed
  useEffect(() => {
    const courseStatusChangedListener = (args: any) => {
      const { driverId, driverCourses, companyInfo } = args.payload;

      setDrivers((oldDrivers) => {
        const changedDriver = filterDriverWithId(oldDrivers, driverId);

        if (changedDriver) {
          const isSomeOfCoursesAssignedToMe = (driverCourses as Course[]).some(
            (course) =>
              currentUser.departments.includes(course.courseType) &&
              course.instructorId === currentUser._id,
          );

          const anyAvailableCourseInMyDepartmants = (driverCourses as Course[]).some(
            (course) =>
              currentUser.departments.includes(course.courseType) &&
              course.status === 'Free',
          );

          const isAllOfMyCoursesStatusesEqualToFinished =
            !anyAvailableCourseInMyDepartmants &&
            (driverCourses as Course[])
              .filter((course) => course.instructorId === currentUser._id)
              .every((mycourse) => mycourse.status === 'Finished');

          if (
            !isAdmin &&
            isSomeOfCoursesAssignedToMe &&
            isAllOfMyCoursesStatusesEqualToFinished &&
            !anyAvailableCourseInMyDepartmants
          ) {
            return oldDrivers.filter((oldDriver) => oldDriver._id !== driverId);
          } else {
            return oldDrivers.map((oldDriver) =>
              oldDriver._id === driverId
                ? { ...oldDriver, courses: driverCourses, companyInfo }
                : oldDriver,
            );
          }
        } else {
          return oldDrivers;
        }
      });
    };
    bus.addEventListener(
      BUS_EVENTS.COURSE_STATUS_CHANGED as EventName,
      courseStatusChangedListener,
    );
    return () => {
      bus.removeEventListener(
        BUS_EVENTS.COURSE_STATUS_CHANGED as EventName,
        courseStatusChangedListener,
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //instructors-reviewed
  useEffect(() => {
    const instructorReviewedListener = (args: any) => {
      const { driver } = args.payload;

      setDrivers((oldDrivers) =>
        oldDrivers.map((oldDriver) =>
          oldDriver._id === driver._id ? driver : oldDriver,
        ),
      );
    };
    bus.addEventListener(
      BUS_EVENTS.INSTRUCTORS_REVIEWED as EventName,
      instructorReviewedListener,
    );
    return () => {
      bus.removeEventListener(
        BUS_EVENTS.INSTRUCTORS_REVIEWED as EventName,
        instructorReviewedListener,
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sendReviewMailsAction = useCallback(
    async (driver: Driver, courses: Course[]) => {
      const instructorIds = courses.map((course) => course.instructorId);

      try {
        await apiDrivers.sendReviewEmails(driver._id, instructorIds);
        isAdmin &&
          toast.success(ACTION_MESSAGES.SUCCESS_MESSAGE.REVIEW_REQUEST);
      } catch (err) {
        isAdmin && toast.error(ACTION_MESSAGES.ERROR_MESSAGE.REVIEW_REQUEST);
      }
    },
    [isAdmin],
  );

  const updateDriversCoursesAction = useCallback(
    async (driver: Driver, selectedCourses: Course[]) => {
      const isAlreadyReviewedInstructor = driver.courses.some(
        (course) =>
          course.hasFinishedReview && course.instructorId === currentUser._id,
      );
      const coursesForUpdate = prepareCoursesForUpdate(
        driver.courses,
        selectedCourses,
      );

      const coursesWithUpdatedHasFinishedReview = coursesForUpdate.map(
        (course) =>
          course.instructorId === currentUser._id && isAlreadyReviewedInstructor
            ? { ...course, hasFinishedReview: true }
            : course,
      );
      try {
        const {
          data: { driver: updatedDriver },
        } = await apiDrivers.updateDriver(driver.id, {
          courses: coursesWithUpdatedHasFinishedReview,
        });
        setDrivers(updateDriver(updatedDriver));

        !isAdmin &&
          !isAlreadyReviewedInstructor &&
          sendReviewMailsAction(driver, selectedCourses);

        toast.success(ACTION_MESSAGES.SUCCESS_MESSAGE.DRIVER_UPDATE);
      } catch (err) {
        toast.error(ACTION_MESSAGES.ERROR_MESSAGE.DRIVER_UPDATE);
      }
    },
    [currentUser._id, isAdmin, sendReviewMailsAction, setDrivers],
  );

  const updateDriverCourseInstructor = useCallback(
    async (driverId: string, selectedAgentId: string, courseType: string) => {
      try {
        const {
          data: { driver: updatedDriver },
        } = await apiDrivers.updateDriverInstructor(
          driverId,
          selectedAgentId,
          courseType,
        );

        setDrivers(updateDriver(updatedDriver));

        toast.success(
          `The course instructor from ${courseType} has been successfully updated for the driver ${updatedDriver.firstName} ${updatedDriver.lastName}  `,
        );
      } catch (err) {
        toast.error(ACTION_MESSAGES.ERROR_MESSAGE.INSTRUCTOR_UPDATE);
      }
    },
    [setDrivers],
  );

  const changeCourseStatus = useCallback(
    async (driver: Driver, agent: User, courseForUpdate: string) => {
      try {
        const { courses } = driver;

        const updatedCourses = courses.map((course) => {
          if (course.courseType === courseForUpdate) {
            course.instructorId = agent._id;
            if (course.status === 'Free') {
              course.status = 'On-going';
            } else if (course.status === 'On-going') {
              course.status = 'Finished';
              sendReviewMailsAction(driver, [course]);
            }
          }
          return course;
        });

        const {
          data: { driver: responseDriver },
        } = await apiDrivers.updateDriver(driver._id, {
          courses: updatedCourses,
        });
        setDrivers(updateDriver(responseDriver));
      } catch (err) {
        toast.error(ACTION_MESSAGES.ERROR_MESSAGE.DRIVER_UPDATE);
      }
    },
    [sendReviewMailsAction, setDrivers],
  );

  const { items: agents, setItems: setAgents } = useFetchWithLimit({
    shouldExecuteCall: isAdmin,
    additionalParams: { isActive: true },
    request: apiAgents.loadAgents,
  });

  const prepareCoursesData = useCallback(
    (driver: Driver, columns: string[], currentUser: User) => {
      return columns.map((columnName) => {
        const course = driver.courses.find(
          ({ courseType }) => courseType === columnName,
        );

        return course?.instructor ? (
          <Table.Cell key={`${driver._id} ${columnName}`}>
            <div className="orientation-table__course-cell">
              {isAdmin ? (
                <NavLink to={`/agents/${course?.instructor._id}`}>
                  <span>{course?.instructor.fullName}</span>
                </NavLink>
              ) : (
                <span>{course?.instructor.fullName}</span>
              )}
              {course?.status === 'Finished' ? (
                <Tippy
                  title={course?.hasFinishedReview ? 'Reviewed' : 'Finished'}
                >
                  {course?.hasFinishedReview ? (
                    <DoubleCheckIcon
                      className="orientation-table__double-check"
                      viewBox="-10 -15 60 60"
                    />
                  ) : (
                    <Icon className="check" color="green" />
                  )}
                </Tippy>
              ) : (
                //Course status is not 'Finished'
                <Tippy title="On-going">
                  <Icon className="hourglass half" color="blue" />
                </Tippy>
              )}
            </div>
          </Table.Cell>
        ) : (
          //Instructor is null
          <Table.Cell key={`${driver._id} ${columnName}`}>
            {course?.status === 'Free' &&
            (currentUser.departments.includes(course.courseType) || isAdmin) ? (
              <div className="orientation-table__actions">
                <Tippy title="Start Course">
                  {!isAdmin ? (
                    <ConfirmationModal
                      header="Start course"
                      messageHeader={`Start course for ${driver.firstName} ${driver.lastName}`}
                      trigger={
                        <Button
                          icon="add"
                          className="orientation-table__add-button basic"
                        />
                      }
                      message={`You will become a instructor of ${driver.firstName} ${driver.lastName} for ${course.courseType} department`}
                      action={() =>
                        changeCourseStatus(
                          driver,
                          currentUser,
                          course.courseType,
                        )
                      }
                    />
                  ) : (
                    <Button
                      icon="add"
                      className="orientation-table__add-button basic"
                      onClick={() => {
                        setSelectedDriverForStartCourses(driver);
                        setModalHeader(
                          `Assign instructor for ${course.courseType} department`,
                        );
                        setCourse(course);
                        setAgents(agents);
                      }}
                    />
                  )}
                </Tippy>
              </div>
            ) : (
              'Assigned to me'
            )}
          </Table.Cell>
        );
      });
    },
    [agents, changeCourseStatus, isAdmin, setAgents],
  );

  return (
    <div className="table-wrapper">
      <div className="orientation__top">
        <Input
          label="Search driver"
          icon="search"
          value={searchValue}
          onChange={({ target: { value } }) => setSearchValue(value)}
        />
        {isAdmin && (
          <Radio
            toggle
            label="Drivers who finished reviews for all departments"
            checked={hasFinishedAllReviews}
            onChange={() => {
              setHasFinishedAllReviews((oldVal) => (oldVal ? undefined : true));
            }}
          />
        )}
      </div>
      <Table sortable celled className={tableClasses}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Driver</Table.HeaderCell>
            <Table.HeaderCell>Email</Table.HeaderCell>
            <Table.HeaderCell>Company</Table.HeaderCell>
            {filterCoursesBasedOnRole.map((column, index) => {
              return (
                <Table.HeaderCell key={index}>
                  <div className="orientation-table__header-cell">
                    <span>{column}</span>
                    <Tippy title="Course Status">
                      <Icon className="question circle outline" />
                    </Tippy>
                  </div>
                </Table.HeaderCell>
              );
            })}
            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {drivers.map((driver) => {
            const anyOnGoingCourseAssignedToMe = driver.courses.some(
              (course) =>
                course.instructorId === currentUser._id &&
                course.status === 'On-going',
            );

            const hasDriverOnGoingCourse = driver.courses.some(
              (course) => course.instructorId && course.status === 'On-going',
            );

            return (
              <Table.Row key={driver._id}>
                <Table.Cell>{`${driver.firstName} ${driver.lastName}`}</Table.Cell>
                <Table.Cell>{driver.email}</Table.Cell>
                <Table.Cell>{driver.companyInfo?.name}</Table.Cell>
                {prepareCoursesData(
                  driver,
                  filterCoursesBasedOnRole,
                  currentUser,
                )}
                <Table.Cell>
                  <div className="button-group">
                    {isAdmin ? (
                      <>
                        <Tippy title="Start Courses">
                          <Button
                            icon="flag checkered"
                            onClick={() => {
                              setSelectedDriverForStartCourses(driver);
                              setModalHeader(
                                driver.courses.filter(
                                  (course) => course.status === 'Free',
                                ).length > 1
                                  ? START_COURSES_HEADER
                                  : 'Assign instructor',
                              );
                              setCoursesStatus('Free');
                              setAgents(agents);
                            }}
                            disabled={
                              getCoursesWithGivenStatus(driver.courses, 'Free')
                                .length === 0 && driver.courses.length !== 0
                            }
                          />
                        </Tippy>
                        <Tippy title="Finish Courses">
                          <Button
                            icon="check"
                            onClick={() => {
                              setSelectedDriver(driver);
                              setModalHeader(FINISH_COURSES_HEADER);
                              setCoursesStatus('On-going');
                            }}
                            disabled={
                              getCoursesWithGivenStatus(
                                driver.courses,
                                'On-going',
                              ).length === 0
                            }
                          />
                        </Tippy>
                        <Tippy title="Send Review Mails">
                          <Button
                            icon="paper plane"
                            onClick={() => {
                              setSelectedDriverForSendReviewMail(driver);
                              setModalHeader(REVIEW_MODAL_HEADER);
                              setCoursesStatus('Finished');
                            }}
                            disabled={
                              !driver.courses.some(
                                (course) =>
                                  !course.hasFinishedReview &&
                                  course.status === 'Finished',
                              ) || driver.hasFinishedAllReviews
                            }
                          />
                        </Tippy>
                        <Tippy title="Edit">
                          <Button
                            icon="edit"
                            onClick={() => {
                              setSelectedDriverForChangeInstructor(driver);
                              setModalHeader(
                                `Change of instructor for driver ${driver.firstName} ${driver.lastName}`,
                              );
                            }}
                            disabled={!hasDriverOnGoingCourse}
                          ></Button>
                        </Tippy>
                      </>
                    ) : currentUser.departments.length > 1 ? (
                      <Tippy title="Finish Courses">
                        <Button
                          icon="check"
                          onClick={() => {
                            setSelectedDriver(driver);
                            setModalHeader(FINISH_COURSES_HEADER);
                            setCoursesStatus('On-going');
                          }}
                          disabled={!anyOnGoingCourseAssignedToMe}
                        />
                      </Tippy>
                    ) : (
                      //agent with single department
                      <Tippy title="Finish Course">
                        <ConfirmationModal
                          header="Finish course"
                          messageHeader={`Finish course for ${driver.firstName} ${driver.lastName}`}
                          trigger={
                            <Button
                              icon="check"
                              disabled={!anyOnGoingCourseAssignedToMe}
                            />
                          }
                          message="By submiting you agree that training in your department is finished for this driver"
                          action={() => {
                            changeCourseStatus(
                              driver,
                              currentUser,
                              currentUser.departments[0],
                            );
                          }}
                        />
                      </Tippy>
                    )}
                  </div>
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
      {drivers?.length ? <TableNavigation pagination={pagination} /> : <></>}
      {!drivers?.length && !loading && (
        <div className="empty-table">No items to show</div>
      )}
      {selectedDriver && (
        <FinishCoursesModal
          setSelectedDriver={setSelectedDriver}
          selectedDriver={selectedDriver}
          header={modalHeader}
          currentUser={currentUser}
          action={updateDriversCoursesAction}
        />
      )}
      {selectedDriverForStartCourses && (
        <StartCoursesAdminModal
          selectedDriver={selectedDriverForStartCourses}
          setSelectedDriver={setSelectedDriverForStartCourses}
          coursesStatus={coursesStatus}
          header={modalHeader}
          action={updateDriversCoursesAction}
          agents={agents}
          course={course}
          setCourse={setCourse}
        />
      )}
      {selectedDriverForSendReviewMail && (
        <SendReviewMailsModal
          selectedDriver={selectedDriverForSendReviewMail}
          setSelectedDriver={setSelectedDriverForSendReviewMail}
          coursesStatus={coursesStatus}
          header={modalHeader}
          action={sendReviewMailsAction}
        />
      )}
      {selectedDriverForChangeInstructor && (
        <ChangeInstructorModal
          selectedDriver={selectedDriverForChangeInstructor}
          setSelectedDriver={setSelectedDriverForChangeInstructor}
          header={modalHeader}
          agents={agents}
          action={updateDriverCourseInstructor}
        />
      )}
    </div>
  );
};
export default OrientationTable;
