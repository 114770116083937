import httpClient from '../httpClient';
import { ApiResponse } from '../../types/ApiResponse';

function loadNotifications(params: any) {
  return httpClient.get<ApiResponse<any>>('/notifications', {
    params: { ...params, $relations: ['agent'] },
  });
}

function markAsRead(id: string) {
  return httpClient.patch(`/notifications/${id}`);
}

export default {
  loadNotifications,
  markAsRead,
};
