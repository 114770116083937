import httpClient from '../httpClient';
import { Agent } from '../../types/Users';
import { ApiResponse } from '../../types/ApiResponse';
import { User } from 'models/User';

const path = (path: string) => `/agents/${path}`;

const createAgent = (userData: Agent) => httpClient.post(path(``), userData);

const loadAgents = (params?: any) =>
  httpClient.get<ApiResponse<Agent>>(path(``), {
    params: { ...params },
  });

const setIsActive = (id: string, value: boolean) =>
  httpClient.patch(path(`${id}/active-status`), {
    isActive: value,
  });

const updateAgent = (id: string, params: any) =>
  httpClient.patch(path(`${id}`), params);

const updateAgentProfilePicture = (id: string, profileImage: File) => {
  const formData = new FormData();
  formData.append('profileImage', profileImage);
  return httpClient.post<{ agent: User }>(
    path(`${id}/profile-image`),
    formData,
  );
};

const getAgent = (id: string) => httpClient.get<{ agent: Agent }>(path(id));

export default {
  createAgent,
  loadAgents,
  setIsActive,
  updateAgent,
  getAgent,
  updateAgentProfilePicture,
};
