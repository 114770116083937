import dayjs from 'dayjs';

export const undefinedValidation = (value: string): boolean => !value;

export const negativeValueValidation = (value: string): boolean =>
  !value || Number(value) < 0;

export const dateValidation = (value: string): boolean => {
  if (!value) {
    return true;
  }
  const today = dayjs();
  const diff = today.diff(value);
  return diff < 0;
};
