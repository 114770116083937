export const undefinedValidation = (value: string): boolean => !value;

export const questions = [
  { name: 'preTripInspection', label: 'The pre-trip inspection' },
  {
    name: 'combinationUnits',
    label:
      'Coupling and uncoupling of combination units, if the equipment he or she may drive includes combination units',
  },
  { name: 'placingEquipment', label: 'Placing the equipment in operation' },
  {
    name: 'emergencyEquipment',
    label: "Use of vehicle's controls and emergency equipment",
  },
  {
    name: 'operatingVehicleInTraffic',
    label: 'Operating the vehicles in traffic and while passing other vehicles',
  },
  {
    name: 'turningVehicle',
    label: 'Turning the vehicle',
  },
  {
    name: 'breakingAndSlowing',
    label: 'Braking and slowing the vehicle by means other than braking',
  },
  { name: 'breakingAndParking', label: 'Backing and parking the vehicle' },
];
