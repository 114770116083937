import { DELETED_DRIVERS } from 'store/actions/actionTypes';

const deletedDriverReducer = (state = false, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case DELETED_DRIVERS:
      return payload;
    default:
      return state;
  }
};

export default deletedDriverReducer;
