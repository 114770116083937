import { AxiosResponse } from 'axios';
import { DEFAULT_LIMIT_VALUE } from 'components/TableNavigation/utils';
import useQueryParams from 'hooks/useQueryParams';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  DELETED_APPLICANTS,
  DELETED_DRIVERS,
  UNCHECKED_DRIVERS,
} from 'store/actions/actionTypes';
import { State } from 'store/reducers';
import { ApiResponse } from 'types/ApiResponse';
import utils from 'utils';
import useEffectSkipFirst from './useEffectSkipFirst';
import usePreviousValue from './usePrevious';

type FilterParamsProps<T extends Object, Status extends string> = {
  requestFunc: (
    params: Record<
      string,
      string | number | string[] | Record<string, string[]>
    >,
    isDeleted?: boolean,
  ) => Promise<AxiosResponse<ApiResponse<T>>>;
  fallbackRoute: string;
  defaultStatusFilter?: string[];
  statusMapper?: (status: Status) => Record<string, string | number | boolean>;
  rolesParams?: Record<string, string[]> | string[];
  agentId?: string;
  hasFinishedReview?: boolean;
  additionalFiltersInitialValues?: Record<string, any>;
};

const useFilterParams = <T, Status extends string>({
  fallbackRoute,
  requestFunc,
  defaultStatusFilter,
  statusMapper,
  rolesParams,
  agentId,
  hasFinishedReview,
  additionalFiltersInitialValues,
}: FilterParamsProps<T, Status>) => {
  const {
    setQueryParam,
    setMultipleQueryParams,
    params: { page, limit, companyId, searchString },
  } = useQueryParams<{
    limit: string;
    page: string;
    companyId: string;
    searchString: string;
    sort: string;
  }>();

  const isDeletedDrivers = useSelector(
    (state: State) => state.isDeletedDrivers,
  );

  const isDeletedApplicants = useSelector(
    (state: State) => state.isDeletedApplicants,
  );
  const currentUser = useSelector((state: State) => state.currentUser);
  const isAdmin = useMemo(() => currentUser.roles.includes('Admin'), [
    currentUser,
  ]);

  const statusMapperRef = useRef(statusMapper);

  const history = useHistory();

  const dispatch = useDispatch();

  const [sort, setSort] = useState('-updatedAt');
  const [status, setStatus] = useState<string[] | Status>(defaultStatusFilter);
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState<number>(null);
  const [items, setItems] = useState<T[]>([]);
  const [searchValue, setSearchValue] = useState(searchString);
  const [employedById, setEmployedById] = useState<string>('');
  const [
    isEnabledDeletedApplicants,
    setIsEnabledDeletedApplicants,
  ] = useState<boolean>(isDeletedApplicants);
  const [
    isEnabledDeletedDrivers,
    setIsEnabledDeletedDrivers,
  ] = useState<boolean>(isDeletedDrivers);
  const [aggregate, setAggregate] = useState<Record<string, any>>({});
  const [additionalFilters, setAdditionalFilters] = useState<
    Record<string, any>
  >(additionalFiltersInitialValues);

  const isFetchingRef = useRef(false);

  const currentPage = +page || 1;
  const currentLimit = +limit || DEFAULT_LIMIT_VALUE;
  const skip = (currentPage - 1) * currentLimit;

  const prevStatus = usePreviousValue(status);

  const isDeleted = useMemo(() => {
    if (isAdmin && fallbackRoute === '/applicants') {
      return isEnabledDeletedApplicants;
    }
    if (isAdmin && fallbackRoute === '/drivers') {
      return isEnabledDeletedDrivers;
    }
    return false;
  }, [
    fallbackRoute,
    isAdmin,
    isEnabledDeletedApplicants,
    isEnabledDeletedDrivers,
  ]);

  const requestParams = useMemo(() => {
    const allParams = {
      $skip: skip,
      $limit: currentLimit ?? DEFAULT_LIMIT_VALUE,
      $searchString: searchString,
      $sort: sort,
      ...(statusMapperRef.current
        ? statusMapperRef.current(status as Status)
        : additionalFilters?.statusHistory
        ? { status: (status as string[]).filter((s) => s !== 'Hired/Checked') }
        : { status }),
      companyId,
      employedById,
      roles: rolesParams,
      agentId,
      courses: hasFinishedReview
        ? JSON.stringify({ $not: { $elemMatch: { hasFinishedReview: false } } })
        : undefined,
      ...additionalFilters,
    } as const;
    return Object.entries(allParams).reduce(
      (acc, [key, val]) =>
        [undefined, null, ''].includes(val?.toString())
          ? acc
          : { ...acc, [key]: val },
      {} as Partial<typeof allParams> & { $skip: number; $limit: number },
    );
  }, [
    skip,
    currentLimit,
    searchString,
    sort,
    status,
    companyId,
    employedById,
    rolesParams,
    agentId,
    hasFinishedReview,
    additionalFilters,
  ]);

  const { current: onSearchInputChange } = useRef(
    utils.debounce(
      (value: string, rest: Object) =>
        setMultipleQueryParams({
          ...rest,
          searchString: value || null,
          page: 1,
        }),
      750,
    ),
  );

  useEffectSkipFirst(
    () => setSearchValue((old) => (old !== searchString ? searchString : old)),
    [searchString],
  );

  useEffectSkipFirst(() => {
    onSearchInputChange(searchValue, { companyId: companyId || null });
  }, [onSearchInputChange, searchValue]);

  const fetchItems = useCallback(async () => {
    setLoading(true);
    try {
      isFetchingRef.current = true;
      const {
        data: { items, aggregate, ...paginationData },
      } = await requestFunc(requestParams, isDeleted);

      setAggregate(aggregate);
      setItems(items);
      const { totalPages } = paginationData;
      setTotalPages(totalPages);

      const canResetPage =
        prevStatus !== undefined &&
        (currentPage < 1 ||
          currentPage > totalPages ||
          totalPages === 0 ||
          prevStatus !== status);

      if (canResetPage) setQueryParam('page', 1, true);
    } catch (err) {
      history.replace(fallbackRoute);

      console.error(err);
    } finally {
      setLoading(false);
      isFetchingRef.current = false;
    }
  }, [
    requestFunc,
    requestParams,
    isDeleted,
    prevStatus,
    currentPage,
    status,
    setQueryParam,
    history,
    fallbackRoute,
  ]);
  useEffect(() => {
    if (!isFetchingRef.current) fetchItems();
  }, [fetchItems]);

  useEffect(() => {
    if (isAdmin && fallbackRoute !== '/applicants')
      dispatch({ type: DELETED_APPLICANTS, payload: false });
    if (isAdmin && fallbackRoute !== '/drivers')
      dispatch({ type: DELETED_DRIVERS, payload: false });
    if (fallbackRoute !== '/drivers')
      dispatch({ type: UNCHECKED_DRIVERS, payload: false });
  }, [dispatch, fallbackRoute, isAdmin]);

  return {
    skip,
    items,
    loading,
    status,
    employedById,
    isEnabledDeletedApplicants,
    isEnabledDeletedDrivers,
    pagination: {
      totalPages,
      currentPage,
    },
    aggregate,
    totalPages,
    requestParams,
    currentPage,
    searchValue,
    additionalFilters,
    setStatus,
    setEmployedById,
    setIsEnabledDeletedApplicants,
    setIsEnabledDeletedDrivers,
    setItems,
    setSearchValue,
    setQueryParam,
    setLoading,
    setSort,
    fetchItems,
    setAdditionalFilters,
  };
};

export default useFilterParams;
