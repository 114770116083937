import { User } from 'models/User';
import { combineReducers } from 'redux';
import currentUserReducer from './auth';
import deletedApplicantReducer from './deletedApplicant';
import deletedDriverReducer from './deletedDriver';
import uncheckedDriverReducer from './uncheckedDriver';

export default combineReducers({
  currentUser: currentUserReducer,
  isDeletedDrivers: deletedDriverReducer,
  isDeletedApplicants: deletedApplicantReducer,
  isUncheckedDrivers: uncheckedDriverReducer,
});

export type State = {
  currentUser: User | null;
  isDeletedDrivers: boolean;
  isDeletedApplicants: boolean;
  isUncheckedDrivers: boolean;
};
