import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Icon, Button } from 'semantic-ui-react';
import Tippy from '../Tippy/index';
import './MyDropZone.styles.scss';
import {
  getIconColorByFileExtension,
  getIconNameByFileExtension,
} from './utils';
const IMAGE_TYPES = ['image/gif', 'image/jpeg', 'image/jpg', 'image/png'];

export type MyDropZoneProps = {
  instructions?: string;
  maxFiles?: number;
  accept?: string;
  files?: File[];
  onFilesChange?: (filesArray: File[]) => void;
  className?: string;
  multiple?: boolean;
};

const MyDropZone: React.FC<MyDropZoneProps> = (props: MyDropZoneProps) => {
  const {
    instructions = 'drag&drop',
    maxFiles = 5,
    accept = '.pdf',
    files = [],
    onFilesChange = () => {},
    className,
    multiple = false,
  } = props;
  const [filePreviewArray, setFilePreviewArray] = useState<
    {
      name: string;
      preview: string;
    }[]
  >([]);

  const { getRootProps, getInputProps } = useDropzone({
    accept,
    multiple,
    onDrop: (acceptedFiles: File[]) => {
      if (maxFiles !== 1) {
        const currentFreeSpace = maxFiles - files.length;

        const newFiles = acceptedFiles
          .filter((f) => !files.some(({ name }) => name === f.name))
          .slice(0, currentFreeSpace);

        const existingFiles = files.filter((f) =>
          acceptedFiles.some(({ name }) => name === f.name),
        );

        let existingFilesNames = '';
        existingFiles.forEach(
          (file) => (existingFilesNames = existingFilesNames + file.name + ','),
        );

        onFilesChange([...files, ...newFiles]);
      } else {
        onFilesChange(acceptedFiles);
      }
    },
  });

  const getFilePreview = (file: File) => {
    const fileWithUrl = filePreviewArray.find(
      (currFile) => currFile.name === file.name,
    );

    if (fileWithUrl) {
      return fileWithUrl.preview;
    } else {
      const resFile = { name: file.name, preview: URL.createObjectURL(file) };
      setFilePreviewArray((oldFiles) => [...oldFiles, resFile]);
      return resFile.preview;
    }
  };

  const removeFile = (fileName: string) => {
    onFilesChange(files.filter((file) => file.name !== fileName));
  };

  return (
    <div className="dropzone">
      <div {...getRootProps()} className="dropzone__container">
        <input {...getInputProps()} />
        <div className="dropzone__container__icon">
          <Icon name="cloud upload" size="massive" color="blue" />
        </div>
        <p>{instructions}</p>
      </div>
      {files.length > 0 && (
        <div
          className={className ? 'image-dropzone' : 'dropzone__files-container'}
        >
          {files.map((file) =>
            IMAGE_TYPES.includes(file.type) ? (
              <div key={file.name} className="image-dropzone__container">
                <img
                  alt={file.name}
                  src={getFilePreview(file)}
                  className="image-dropzone__container__image"
                />
                <Button
                  className="image-dropzone__container__close-button"
                  size="mini"
                  circular
                  icon="close"
                  onClick={(e) => {
                    e.stopPropagation();
                    removeFile(file.name);
                  }}
                />
              </div>
            ) : (
              <Tippy key={file.name} title={file.name}>
                <div
                  onClick={() =>
                    window.open(`${getFilePreview(file)}`, '_blank')
                  }
                >
                  <Icon
                    className="dropzone-modal-content__files-container__file-icon"
                    color={getIconColorByFileExtension(file.name)}
                    size="big"
                    name={getIconNameByFileExtension(file.name)}
                  />
                  <div className="dropzone__files-container__file-name">
                    {file.name}
                  </div>
                  <Button
                    className="dropzone__files-container__close-button"
                    size="mini"
                    circular
                    icon="close"
                    onClick={(e) => {
                      e.stopPropagation();
                      removeFile(file.name);
                    }}
                  />
                </div>
              </Tippy>
            ),
          )}
        </div>
      )}
    </div>
  );
};

export default MyDropZone;
