import React from 'react';
import { Icon } from 'semantic-ui-react';
import { useHistory } from 'react-router';
import './ApplicantHeader.styles.scss';
import { NavLink } from 'react-router-dom';

type ApplicantHeaderProps = {
  applicantName: string;
  status: string;
  className?: string;
  id?: string;
  showGoBack?: boolean;
};

const ApplicantHeader = (props: ApplicantHeaderProps) => {
  const { applicantName, status, className, id, showGoBack = true } = props;

  const history = useHistory();

  return (
    <header className={`applicant-header ${className}`}>
      {showGoBack && (
        <Icon
          name="arrow left"
          size="large"
          onClick={history.goBack}
          className="icon"
        />
      )}

      <div className="applicant-header__info">
        <h3>{applicantName}</h3>
        {id ? (
          <NavLink to={`/drivers/${id}/status-history`}>{status}</NavLink>
        ) : (
          <label>{status}</label>
        )}
      </div>
    </header>
  );
};

export default ApplicantHeader;
