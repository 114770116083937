import React, { useCallback, useEffect, useState } from 'react';
import { Modal, Header, Button } from 'semantic-ui-react';
import { Form, Field } from 'react-final-form';
import classNames from 'classnames';
import { Course, Driver } from 'types/Users';
import { User } from 'models/User';
import FormCheckbox from 'components/FinalFormComponents/FormCheckbox';
import { getCoursesWithGivenStatus } from './utilits';

type CheckboxFormValues = {
  Eld?: Course;
  Fleet?: Course;
  Accounting?: Course;
  Safety?: Course;
};

type CoursesModalProps = {
  selectedDriver: Partial<Driver>;
  setSelectedDriver: React.Dispatch<React.SetStateAction<Driver>>;
  header: string;
  action: (driver: Driver, selectedCourses: Course[]) => void;
  currentUser: User;
};

const FinishCoursesModal: React.FC<CoursesModalProps> = ({
  setSelectedDriver,
  selectedDriver,
  header,
  action,
  currentUser,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [courses, setCourses] = useState<Course[]>();

  const onFormSubmit = useCallback(
    async (values: CheckboxFormValues) => {
      setLoading(true);
      try {
        await action(selectedDriver as Driver, Object.values(values));
        setSelectedDriver(null);
      } finally {
        setLoading(false);
      }
    },
    [action, selectedDriver, setSelectedDriver],
  );

  const formClassNames = classNames('ui', 'form', { loading: loading });

  useEffect(() => {
    const courses = selectedDriver
      ? getCoursesWithGivenStatus(selectedDriver.courses, 'On-going')
      : [];

    setCourses(
      currentUser.roles.includes('Admin')
        ? courses
        : courses.filter((course) => course.instructorId === currentUser._id),
    );
  }, [currentUser._id, currentUser.roles, selectedDriver]);

  return (
    <Modal
      closeIcon
      onClose={() => setSelectedDriver(null)}
      open={selectedDriver !== null}
    >
      <Header>{header}</Header>
      <Modal.Content>
        <Form
          onSubmit={onFormSubmit}
          render={({ handleSubmit, pristine, submitting }) => (
            <form onSubmit={handleSubmit} className={formClassNames}>
              {courses?.map((course) => (
                <Field
                  key={course.courseType}
                  component={FormCheckbox}
                  option={{
                    label: currentUser.roles.includes('Admin')
                      ? `${course.instructor?.fullName} (${course.courseType})`
                      : course.courseType,
                    value: course,
                  }}
                  name={course.courseType}
                />
              ))}
              <div className="form-actions">
                <Button
                  type="submit"
                  disabled={submitting || pristine}
                  content="Submit"
                  primary
                />
              </div>
            </form>
          )}
        />
      </Modal.Content>
    </Modal>
  );
};
export default FinishCoursesModal;
