import { useEffect, useState } from 'react';
import { apiDrivers } from 'api';

export type FileFromDB = {
  name: string;
  id: string;
  fileUrl: string;
  fileType: string;
  isDeleted: boolean;
  groupId: string;
  _id?: string;
  isPermanentDeleted?: boolean;
};

const useFiles = (
  isDeletedProp: boolean,
  driverId: string,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  const [files, setFiles] = useState<FileFromDB[]>([]);
  useEffect(() => {
    setLoading(true);
    const fetchFiles = async () => {
      try {
        const {
          data: { items },
        } = await apiDrivers.loadFiles(
          { $limit: 100, isDeleted: isDeletedProp },
          driverId,
        );
        setFiles(
          items.map(
            ({ _id, name, fileUrl, fileType, isDeleted, groupId }: any) => {
              return { id: _id, name, fileUrl, fileType, isDeleted, groupId };
            },
          ),
        );
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    };
    fetchFiles();
  }, [driverId, isDeletedProp, setLoading]);

  return { files, setFiles };
};

export default useFiles;
