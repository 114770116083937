import React, { useState, useMemo } from 'react';
import useQueryParams from 'hooks/useQueryParams';
import './NavigationSubMenu.styles.scss';
import { Button } from 'semantic-ui-react';
import LinkWithQParams from '../../../../components/Header/components/LinkWithQParams';
import classNames from 'classnames';
import CreateCompanyModal from 'components/CreateCompanyModal';
import { useSelector } from 'react-redux';
import { State } from 'store/reducers';
import { Company } from 'types/Users';

type NavigationSubMenuProps = {
  header?: string;
  options?: string[];
  setVisibility?: React.Dispatch<React.SetStateAction<boolean>>;
  companies?: any;
};
const NavigationSubMenu: React.FC<NavigationSubMenuProps> = ({
  header,
  setVisibility,
  companies,
}) => {
  const {
    params: { companyId },
  } = useQueryParams<{ companyId: string; limit: string }>();

  const user = useSelector((state: State) => state.currentUser);

  const [companiesAndFiltersWithAll, setCompaniesAndFiltersWithAll] = useState([
    ...companies,
    ...[{ name: 'All companies', id: null }],
  ]);

  const [selectedCompany, setSelectedCompany] = useState<Company>(null);
  const [openCreateCompanyModal, setOpenCreateCompanyModal] = useState(false);

  const activeItem = companyId ?? 'All companies';

  const linkWithQParamsClasses = (id: string, name: string) =>
    classNames(
      'navigation-sub-menu__list__option',
      {
        'route-active': id === companyId,
      },
      {
        'route-active': activeItem
          ? name === activeItem
          : name === 'All companies',
      },
      {
        'navigation-sub-menu__list__option--is-admin': isAdmin,
      },
    );

  const onCreateCompany = (newCompany: any, isEditModal?: boolean) => {
    const {
      company: { id },
    } = newCompany;
    if (!isEditModal)
      setCompaniesAndFiltersWithAll((oldCompanies) => [
        { ...newCompany.company },
        ...oldCompanies,
      ]);
    else
      setCompaniesAndFiltersWithAll((oldCompanies) =>
        oldCompanies.map((company) =>
          company.id === id ? newCompany.company : company,
        ),
      );
  };

  const isAdmin = useMemo(() => user.roles.includes('Admin'), [user.roles]);

  return (
    <>
      <div className="navigation-sub-menu">
        <div className="navigation-sub-menu__header">
          <h2>{header}</h2>
          <div
            className="navigation-sub-menu__header__cross"
            onClick={() => setVisibility(false)}
          />
        </div>
        <div className="navigation-sub-menu__list">
          {companiesAndFiltersWithAll
            ?.slice()
            .reverse()
            .map((company, index) =>
              isAdmin ? (
                <div
                  key={company.id}
                  className="navigation-sub-menu__list__company-container"
                >
                  <LinkWithQParams
                    params={{
                      companyId: company.id,
                      page: '1',
                    }}
                    className={linkWithQParamsClasses(company.id, company.name)}
                  >
                    <span> {company.name} </span>
                  </LinkWithQParams>

                  {company.name !== 'All companies' && (
                    <Button
                      onClick={() =>
                        setSelectedCompany(
                          companiesAndFiltersWithAll?.slice().reverse()[index],
                        )
                      }
                      className="edit-company"
                      icon="pencil alternate"
                    />
                  )}
                </div>
              ) : (
                <LinkWithQParams
                  params={{
                    companyId: company.id,
                    page: '1',
                  }}
                  className={linkWithQParamsClasses(company.id, company.name)}
                >
                  <span> {company.name} </span>
                </LinkWithQParams>
              ),
            )}
          {isAdmin && (
            <div className="navigation-sub-menu__list__new-company">
              <Button
                icon="add circle"
                labelPosition="right"
                content="Create New Company"
                onClick={() => setOpenCreateCompanyModal(true)}
              />
            </div>
          )}
        </div>
      </div>
      {(openCreateCompanyModal || selectedCompany) && (
        <CreateCompanyModal
          isEditModal={Boolean(selectedCompany)}
          openModal={openCreateCompanyModal || Boolean(selectedCompany)}
          selectedCompany={selectedCompany}
          setSelectedCompany={setSelectedCompany}
          setOpenModal={setOpenCreateCompanyModal}
          onCreateCompany={onCreateCompany}
        />
      )}
    </>
  );
};
export default NavigationSubMenu;
