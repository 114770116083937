export const getIconNameByFileExtension = (fileName: string) => {
  const ext = /[.]/.exec(fileName) ? /[^.]+$/.exec(fileName) : undefined;

  switch (ext[0]) {
    case 'pdf':
      return 'file pdf outline';
    case 'xlsx':
      return 'file excel';
    default:
      return 'file text';
  }
};

export const getIconColorByFileExtension = (fileName: string) => {
  const ext = /[.]/.exec(fileName) ? /[^.]+$/.exec(fileName) : undefined;

  switch (ext[0]) {
    case 'pdf':
      return 'red';
    case 'xlsx':
      return 'green';
    default:
      return 'blue';
  }
};
