import React, { useEffect, useState } from 'react';
import './EmploymentHistoryModal.styles.scss';
import { Button, Header, Modal } from 'semantic-ui-react';
import classNames from 'classnames';
import { Form } from 'react-final-form';
import {
  ERROR_MESSAGE,
  HEADER,
  SUBMIT_BUTTON_TEXT,
  TRIGGER_TEXT,
  getInitialValues,
} from './utils';
import { toast } from 'react-toastify';
import EmploymentHistoryFieldArray from 'components/FinalFormComponents/EmploymentHistoryFieldArray';
import { apiDrivers } from 'api';

export type Company = {
  id: string;
  label: string;
  value: string;
};

export type CompanyValue = {
  id: string;
  checked: boolean;
  value: string;
  name: string;
};

type EmploymentHistoryModalProps = {
  triggerText?: string;
  header?: string;
  trigger?: React.ReactNode;
  companies: Company[];
  driverId: string;
};

const EmploymentHistoryModal: React.FC<EmploymentHistoryModalProps> = ({
  triggerText = TRIGGER_TEXT,
  header = HEADER,
  trigger: forwardedTrigger,
  companies = [],
  driverId,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const trigger = forwardedTrigger || <Button content={triggerText} />;

  const resetStates = () => {
    setLoading(false);
  };

  useEffect(() => {
    if (!open) {
      return;
    }
    return resetStates;
  }, [open]);

  const onFormSubmit = async (values: any) => {
    setLoading(true);

    const employmentHistory = values.companies
      .filter((value: CompanyValue) => value.checked)
      .map((val: CompanyValue) => {
        const { id } = val;
        return id;
      });

    try {
      await apiDrivers.sendEmploymentHistoryEmails(
        driverId,
        employmentHistory,
        window.location.href,
      );
      setLoading(false);
      setOpen(false);
    } catch (e) {
      setLoading(false);
      toast.error(ERROR_MESSAGE);
      console.error(e);
    }
  };

  const formClassNames = classNames('ui', 'form', { loading: loading });

  const initialValue = { companies: getInitialValues(companies) };

  const checkAnyChecked = (values: any) => {
    return !!values.find((value: any) => value.checked);
  };

  return (
    <Modal
      closeIcon
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={trigger}
      className="modal"
    >
      <Header>{header}</Header>
      <Modal.Content>
        <Form
          onSubmit={onFormSubmit}
          validate={(values) => {
            const errors: any = {};

            if (!values.companies) {
              errors.companies = 'Required';
            }

            if (!checkAnyChecked(values.companies)) {
              errors.checked = 'Required';
            }

            return errors;
          }}
          initialValues={initialValue}
          render={({
            handleSubmit,
            submitting,
            hasValidationErrors,
            values,
          }) => (
            <form onSubmit={handleSubmit} className={formClassNames}>
              <EmploymentHistoryFieldArray
                values={values}
                companies={companies}
              />
              <div className="modal-actions-like-container">
                <Button
                  type="submit"
                  disabled={submitting || hasValidationErrors}
                  content={SUBMIT_BUTTON_TEXT}
                  primary
                />
              </div>
            </form>
          )}
        />
      </Modal.Content>
    </Modal>
  );
};

export default EmploymentHistoryModal;
