import React from 'react';
import {
  HIRE_CONFIRMATION_HEADER,
  HIRE_CONFIRMATION_MESSAGE_HEADER,
  HIRE_CONFIRMATION_MESSAGE,
  HIRE_CONFIRMATION_ACTION_MESSAGES,
} from './utils';
import enums from '../../enums';
import ConfirmationLinkModal from 'components/ConfirmationLinkModal';
import { apiDrivers } from 'api';
const { DRIVER_STATUS } = enums;

type HireConfirmationModalProps = {
  trigger?: React.ReactNode;
  header?: string;
  driverId: string;
  driverName?: string;
  status: typeof DRIVER_STATUS[keyof typeof DRIVER_STATUS];
};

const HireConfirmationModal: React.FC<HireConfirmationModalProps> = (props) => {
  return (
    <ConfirmationLinkModal
      {...props}
      header={HIRE_CONFIRMATION_HEADER}
      messageHeader={HIRE_CONFIRMATION_MESSAGE_HEADER}
      message={HIRE_CONFIRMATION_MESSAGE}
      actionMessage={HIRE_CONFIRMATION_ACTION_MESSAGES}
      actionStatus={DRIVER_STATUS.Hired}
      action={() => apiDrivers.hire(props.driverId, window.location.href)}
    />
  );
};
export default HireConfirmationModal;
