import { Form } from 'semantic-ui-react';
import { FieldRenderProps } from 'react-final-form';
import React, { useMemo } from 'react';
import './FormInput.styles.scss';
import { Label } from '../';
import classNames from 'classnames';
import Tippy from 'components/Tippy';

type FormInputProps = {
  label?: string;
  required?: boolean;
  className?: string;
  tooltip?: string;
  icon?: React.ReactNode;
  iconPosition?: any;
  iconInInput?: React.ReactNode;
  readonly?: boolean;
} & FieldRenderProps<string, HTMLElement>;

const FormInput: React.FC<FormInputProps> = ({
  input,
  meta,
  label,
  required,
  className,
  tooltip,
  tippyIcon,
  readonly = false,
  ...rest
}) => {
  const error = useMemo(() => meta.touched && meta.error, [meta]);
  const { onChange } = input;
  const inputClassNames = classNames(className, 'input-container');

  return (
    <div className={inputClassNames}>
      <div className={`${inputClassNames}__label-with-icon`}>
        {label && <Label label={label} error={error} required={required} />}
        {tooltip && <Tippy title={tooltip}>{tippyIcon}</Tippy>}
      </div>
      <Form.Input
        disabled={readonly}
        onChange={onChange}
        {...rest}
        {...input}
        error={!!error}
      />
      {error && <small className={`${inputClassNames}__error`}>{error}</small>}
    </div>
  );
};

export default FormInput;
