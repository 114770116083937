import React, { useState } from 'react';
import { Button, Modal, ModalContent } from 'semantic-ui-react';
import { Form, Field } from 'react-final-form';
import { FormInput, FormSelect } from '../FinalFormComponents';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import enums from '../../enums';
import { apiAgents } from 'api';
import { FormSelectOption } from 'components/FinalFormComponents/Select/FormSelect.component';
import validateService from 'services/validateService';

const { ACTION_MESSAGES } = enums;
type CreateAgentFormProps = {
  trigger?: React.ReactNode;
  onAgentCreate?: Function;
};

const selectOptions = [
  { key: '1', text: 'Eld', value: 'Eld' },
  { key: '2', text: 'Fleet', value: 'Fleet' },
  { key: '3', text: 'Safety', value: 'Safety' },
  { key: '4', text: 'Accounting', value: 'Accounting' },
  { key: '5', text: 'Recruiting', value: 'Recruiting' },
  { key: '6', text: 'Driver Education', value: 'Driver Education' },
] as FormSelectOption[];

const CreateAgentForm: React.FC<CreateAgentFormProps> = ({
  trigger,
  onAgentCreate,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const formClassNames = classNames('ui', 'form', { loading });

  const triggerRef = trigger || <Button>Create agent</Button>;

  const onFormSubmit = async (values: any) => {
    setLoading(true);
    try {
      const { data } = await apiAgents.createAgent(values);
      setLoading(false);
      onAgentCreate(data.agent);
      toast.success(ACTION_MESSAGES.SUCCESS_MESSAGE.CREATE_AGENT);
      setOpen(false);
    } catch (err) {
      toast.error(ACTION_MESSAGES.ERROR_MESSAGE.CREATE_AGENT);
      console.error(err);
      setLoading(false);
    }
  };

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={triggerRef}
      className="modal"
      closeIcon
    >
      <Modal.Header>Create agent</Modal.Header>
      <ModalContent>
        <Form
          onSubmit={onFormSubmit}
          render={({ handleSubmit, submitting, hasValidationErrors }) => (
            <form onSubmit={handleSubmit} className={formClassNames}>
              <Field
                name="username"
                label="Username"
                placeholder="John"
                component={FormInput}
                required
                validate={validateService.required}
              />
              <Field
                name="firstName"
                label="First Name"
                placeholder="John"
                component={FormInput}
                required
                validate={validateService.required}
              />
              <Field
                name="lastName"
                label="Last Name"
                placeholder="Doe"
                component={FormInput}
                required
                validate={validateService.required}
              />
              <Field
                name="email"
                label="Email"
                placeholder="johndoe@example.com"
                component={FormInput}
                required
                validate={validateService.composeValidators(
                  validateService.required,
                  validateService.mailValidation,
                )}
              />
              <Field
                name="password"
                label="Password"
                component={FormInput}
                type="password"
                required
                validate={validateService.required}
              />
              <Field
                name="departments"
                label="Departments"
                placeholder="Departments"
                options={selectOptions}
                component={FormSelect}
                multipleSelect={true}
                required
                clearable
                validate={validateService.required}
              />

              <div className="modal-actions-like-container">
                <Button
                  type="submit"
                  disabled={submitting || hasValidationErrors}
                  content={'Create agent'}
                  primary
                />
              </div>
            </form>
          )}
        />
      </ModalContent>
    </Modal>
  );
};

export default CreateAgentForm;
