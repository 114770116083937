import { apiAuth } from 'api';
import classNames from 'classnames';
import history from 'common/history';
import useQueryParams from 'hooks/useQueryParams';
import React, { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { Dimmer, Header, Icon, Loader, Segment } from 'semantic-ui-react';
import credentialsService from 'services/credentialsService';

import './VerifyEmail.styles.scss';

const VerifyEmail: React.FC = () => {
  const {
    params: { code: token },
  } = useQueryParams<{
    code: string;
  }>();

  const [loading, setLoading] = useState<boolean>(true);

  const isLoggedIn = useMemo(
    () => (credentialsService.token ? true : false),
    [],
  );

  useEffect(() => {
    if (!token) history.push('/');
    const verifyEmail = async () => {
      try {
        setLoading(true);
        await apiAuth.verifyEmail(token);
        const auth = JSON.parse(localStorage.getItem('auth'));
        if (auth) {
          const newAuth = { ...auth, user: { ...auth.user, isVerified: true } };
          localStorage.setItem('auth', JSON.stringify(newAuth));
        }
      } catch (err) {
        toast.error(
          'Something went wrong. Please try again or contact administrator.',
        );
      } finally {
        setLoading(false);
        setTimeout(() => {
          isLoggedIn ? history.push('/') : history.push('/login');
        }, 3500);
      }
    };

    verifyEmail();
  }, [isLoggedIn, token]);

  return (
    <div className="super-ego-holding-container">
      {loading && (
        <Dimmer inverted active>
          <Loader>Verifying...</Loader>
        </Dimmer>
      )}
      <Segment
        padded="very"
        raised
        className={classNames('section-container', {
          'section-container--none': loading,
        })}
        icon="deaf"
      >
        <Header as="h1" textAlign="center">
          <div>
            <Icon name="check circle outline" size="big" color="green" />
          </div>
          Your email address has been successfully verified.
        </Header>
        <small>
          We will redirect you to Login page or Application page if you are
          currently logged in.
        </small>
      </Segment>
    </div>
  );
};

export default VerifyEmail;
