const PHONE_REGEX = /^\+?[0-9 ]{3,20}$/;
const EMAIL_REGEX = new RegExp(
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>[\].,;:\s@"]+\.)+[^<>()[\].,;:\s!@#$%^&*()_+=|"'{}/?]{2,})$/i,
);

const URL_REGEX = new RegExp(
  '^(https?:\\/\\/)' +
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
    '((\\d{1,3}\\.){3}\\d{1,3}))' +
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
    '(\\?[;&a-z\\d%_.~+=-]*)?' +
    '(\\#[-a-z\\d_]*)?',
  'i',
);

const SPACES_REGEX = new RegExp(/^\S*$/);

const PASSWORD_REGEX = new RegExp(
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*(\d|[@$!%*?&~`#^\-_=+/\\'";:.,><|{}()\\[\]]))[A-Za-z\d@$!%*?&~`#^\-_=+/\\'";:.,><|{}()\\[\]]{8,}$/,
);

const MAIL_VALIDATION_ON_RESET_PASSWORD_REGEX = new RegExp(
  /^[\w.!#$%&'*+\-/=?^_`{|}~]{1,64}@\w+\.(\w+\.)*?[a-zA-Z0-9]{1,255}$/,
);

const USERNAME_REGEX = new RegExp(/[~`!@#$%^"|&()_={}[\]:;,.<>+/?-]{1,255}/);

export {
  PHONE_REGEX,
  EMAIL_REGEX,
  URL_REGEX,
  SPACES_REGEX,
  PASSWORD_REGEX,
  MAIL_VALIDATION_ON_RESET_PASSWORD_REGEX,
  USERNAME_REGEX,
};
