import { useEffect } from 'react';

const useElementScrollTo = (
  ref: React.MutableRefObject<HTMLDivElement>,
  skip: number,
) => {
  useEffect(() => {
    ref.current.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [skip, ref]);
};

export default useElementScrollTo;
