import dayjs from 'dayjs';
import { EmploymentHistory } from 'types';

import zipcodes from 'zipcodes';

import {
  SPACES_REGEX,
  MAIL_VALIDATION_ON_RESET_PASSWORD_REGEX,
  USERNAME_REGEX,
  PASSWORD_REGEX,
} from '../common/regex';

export const ZIP_CODE_LENGTH = 5;

const required = (value: any) =>
  !value ||
  (Array.isArray(value) && !value.length) ||
  (typeof value === 'object' && JSON.stringify(value) === '{}')
    ? 'Required'
    : undefined;

const spacesValidation = (value: string) => {
  if (!SPACES_REGEX.test(value)) {
    return 'Spaces are not allowed.';
  }
};

const passwordValidation = (value: string) => {
  if (!PASSWORD_REGEX.test(value)) {
    return 'Your password is invalid, please try again.';
  }
};

const matchPasswordValidation = (
  fieldValue: string,
  { password }: object & Partial<{ password: string }>,
) => {
  if (!fieldValue) return 'This field is required';

  if (password && fieldValue !== password)
    return 'Your password and confirmation password do not match.';
};

const zipValidation = (value: string) => {
  if (value && (value.length !== ZIP_CODE_LENGTH || !zipcodes.lookup(value)))
    return 'Invalid zip code';
};

const mailValidation = (value: string) => {
  if (!MAIL_VALIDATION_ON_RESET_PASSWORD_REGEX.test(value))
    return 'Please enter a valid email address.';
};

const usernameValidation = (value: string) => {
  if (USERNAME_REGEX.test(value))
    return "Username can't contain special characters.";
  return;
};

const length64CharactersValidation = (value: string) => {
  if (!value) return;

  if (value.trim().length === 0) return 'This field cannot be empty.';

  if (value.length > 64) return 'Maximum length is 64 characters.';
};
const employerIdValidation = (value: string) => {
  if (!value) return;

  if (value.trim().length === 0) return 'This field cannot be empty.';

  if (value.length !== 9)
    return 'Invalid employer identification number (please enter exact 9 characters.)';
};

const ssnValidation = (value: string) => {
  if (!value) return;

  if (value.trim().length === 0) return 'This field cannot be empty.';

  const regex = /^\d{9}$/;
  const regexWithDashes = /^\d{3}-\d{2}-\d{4}$/;
  const found = !!value.match(regex) || !!value.match(regexWithDashes);
  if (found) {
    return;
  }
  return 'Invalid ssn number';
};

const length1AndExactLetter = (letters: string[]) => (value: string) => {
  if (!value) return;
  if (value.trim().length === 0) return 'This field cannot be empty.';
  if (value.length !== 1 || !letters.includes(value))
    return `Please enter exact 1 letter of ${letters}`;
};

const beforeFieldValidation = (
  fieldValue: string,
  { dateTo }: object & Partial<{ dateTo: Date }>,
) => {
  if (!fieldValue) return 'Required';

  if (dateTo && fieldValue > dateTo.toString())
    return 'This date should be before.';
};

const afterFieldValidation = (
  fieldValue: string,
  { dateFrom }: object & Partial<{ dateFrom: Date }>,
) => {
  if (!fieldValue) return 'Required';

  if (dateFrom && fieldValue < dateFrom.toString())
    return 'This date should be after.';
};

const employmentHistoryDateIntervalValidation = (
  employmentHistory: EmploymentHistory[],
) => (
  fieldValue: string,
  { dateTo, dateFrom }: object & Partial<{ dateTo: Date; dateFrom: Date }>,
) => {
  if (!fieldValue) return;

  const isInvalidValue = employmentHistory.some(
    (eh) =>
      eh?.from?.toString().split('T')[0] <= fieldValue &&
      eh?.to?.toString().split('T')[0] >= fieldValue,
  );

  const isInvalidEhRelatedOnFields = employmentHistory.some(
    (eh) =>
      eh?.from?.toString().split('T')[0] >=
        dateFrom?.toString().split('T')[0] &&
      eh?.from?.toString().split('T')[0] <= dateTo?.toString().split('T')[0] &&
      eh?.to?.toString().split('T')[0] >= dateFrom?.toString().split('T')[0] &&
      eh?.to?.toString().split('T')[0] <= dateTo?.toString().split('T')[0],
  );

  if (isInvalidValue || isInvalidEhRelatedOnFields)
    return 'Invalid date interval, you have entered overlapping date interval for two or more employment history.';
};

const onlyPast = (fieldValue: string) => {
  if (!fieldValue) {
    return;
  }

  if (fieldValue.split('-')[0].length > 4) return 'Year must contain 4 digits.';

  const today = new Date();

  const formatedToday = dayjs(today).format('YYYY-MM-DD');

  if (fieldValue > formatedToday) return 'This date must be in past.';
};

const composeValidators = (...validators: any) => (
  value: any,
  allValues: any,
) =>
  validators.reduce(
    (error: any, validator: any) => error || validator(value, allValues),
    undefined,
  );

export default {
  required,
  spacesValidation,
  composeValidators,
  zipValidation,
  mailValidation,
  passwordValidation,
  matchPasswordValidation,
  usernameValidation,
  onlyPast,
  afterFieldValidation,
  beforeFieldValidation,
  employmentHistoryDateIntervalValidation,
  length64CharactersValidation,
  employerIdValidation,
  length1AndExactLetter,
  ssnValidation,
};
