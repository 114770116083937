import { Form } from 'semantic-ui-react';
import { FieldRenderProps } from 'react-final-form';
import React, { useMemo } from 'react';
import './FormTextarea.styles.scss';
import { Label } from '../';
import classNames from 'classnames';

type FormTextareaProps = {
  label?: string;
  required?: boolean;
  className?: string;
} & FieldRenderProps<string, HTMLElement>;

const FormTextarea: React.FC<FormTextareaProps> = ({
  input,
  meta,
  label,
  required,
  className,
  ...rest
}) => {
  const error = useMemo(() => meta.touched && meta.error, [meta]);
  const { onChange } = input;

  const textareaClassNames = classNames(className, 'textarea-container');

  return (
    <div className={textareaClassNames}>
      {label && <Label label={label} error={error} required={required} />}
      <Form.TextArea onChange={onChange} {...rest} {...input} error={!!error} />
    </div>
  );
};

export default FormTextarea;
