import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Dimmer, Header, Loader, Segment } from 'semantic-ui-react';
import { Form, Field } from 'react-final-form';

import credentialsService from 'services/credentialsService';
import { FETCH_CURRENT_USER } from 'store/actions/actionTypes';
import './Login.styles.scss';
import { apiAuth } from 'api';
import { FormInput } from 'components/FinalFormComponents';
import validateService from 'services/validateService';

type LoginProps = {};

const Login: React.FC<LoginProps> = (props) => {
  const [loading, setLoading] = useState<boolean>(false);

  const history = useHistory();
  const dispatch = useDispatch();

  const onHandleSubmit = useCallback(
    async ({ username, password }: { username: string; password: string }) => {
      try {
        setLoading(true);
        const { data } = await apiAuth.login(username, password);
        credentialsService.saveAuthBody(data);
        dispatch({ type: FETCH_CURRENT_USER, payload: data.user });
        history.push('/');
      } catch (error) {
        toast.error(error?.response.data.error);
      } finally {
        setLoading(false);
      }
    },
    [dispatch, history],
  );

  return (
    <div className="super-ego-holding-container">
      <Segment padded="very" raised className="section-container">
        <Header as="h1" textAlign="center">
          Login to your account
        </Header>
        {loading && (
          <Dimmer inverted active>
            <Loader>Loading...</Loader>
          </Dimmer>
        )}
        <Form
          size="large"
          onSubmit={onHandleSubmit}
          render={({ handleSubmit, hasValidationErrors }) => (
            <form onSubmit={handleSubmit} className="form-container">
              <Field
                label="Username"
                name="username"
                component={FormInput}
                required
                validate={validateService.usernameValidation}
                placeholder="Username"
                fluid
                icon="user"
                iconPosition="left"
                autoFocus
              />
              <Field
                label="Password"
                name="password"
                type="password"
                component={FormInput}
                required
                placeholder="Password"
                fluid
                icon="lock"
                iconPosition="left"
              />
              <Button
                disabled={hasValidationErrors}
                color="blue"
                fluid
                size="large"
              >
                Login
              </Button>
              <NavLink
                className="form-container__forgot-password"
                to="/forgot-password"
              >
                Have you forgotten your password?
              </NavLink>
            </form>
          )}
        />
      </Segment>
    </div>
  );
};

export default Login;
