import React from 'react';
import { Field } from 'react-final-form';
import { FormInput } from 'components/FinalFormComponents';
import {
  dateValidation,
  negativeValueValidation,
  undefinedValidation,
} from './utils';

const CertificationOfRoadTest: React.FC<any> = (props) => {
  return (
    <>
      <Field
        name="typeOfPowerUnit"
        component={FormInput}
        type="text"
        label="Type of Power Unit"
        required
        validate={undefinedValidation}
        initialValue="Semi-truck"
      />
      <Field
        name="typeOfTrailer"
        component={FormInput}
        type="text"
        label="Type of Trailer"
        required
        validate={undefinedValidation}
        initialValue="Dry van"
      />
      <Field
        name="supervisionDate"
        component={FormInput}
        type="date"
        label="Date of Certification of Road Test (past only)"
        required
        validate={dateValidation}
      />
      <Field
        name="approximatelyMilesOfDriving"
        label="Approximate number of miles"
        component={FormInput}
        type="number"
        required
        validate={negativeValueValidation}
      />
    </>
  );
};

export default CertificationOfRoadTest;
