import { Company } from 'types/Users';
import httpClient from '../httpClient';

const path = (routePath: TemplateStringsArray) => `/companies/${routePath}`;

function getCompanies(params?: any) {
  return httpClient.get(path``, {
    params: { ...params },
  });
}

function createCompany(companyData: any) {
  return httpClient.post(path``, companyData);
}

function uploadCompanyPhoto(companyId: string, photo: File) {
  const formData = new FormData();
  formData.append('photo', photo);
  return httpClient.post(`/companies/${companyId}/photo`, formData);
}

const editCompany = (params: Company, companyId: string) =>
  httpClient.put(`/companies/${companyId}`, params);

const deleteCompanyPhoto = (id: string) =>
  httpClient.delete(`companies/${id}/photo`);

export default {
  getCompanies,
  createCompany,
  uploadCompanyPhoto,
  editCompany,
  deleteCompanyPhoto,
};
