export const preparedUrlQuery = (
  params: Array<{ name: string; value: string }>,
) => {
  const search = window.location.search;

  if (search && !params.length) return search;

  const urlParams = new URLSearchParams(search);

  if (!search && params.length) {
    params.forEach((param) => {
      urlParams.append(param.name, param.value);
    });
  } else {
    params.forEach((param) => {
      if (param.value) {
        if (urlParams.has(param.name)) {
          // replace param
          //
          urlParams.delete(param.name);
          urlParams.append(param.name, param.value);
        } else {
          // append when is empty
          //
          urlParams.append(param.name, param.value);
        }
      } else {
        // remove param
        //
        urlParams.delete(param.name);
      }
    });
  }

  return '?' + urlParams.toString();
};
