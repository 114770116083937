import { Schema, PropertyValue } from '.';
import { STATES, TRAILER_TYPE, EDUCATION_GRADE } from '../constants';
import { capitalize } from 'common/string';
import { Company, Driver } from '../../../types/Users';
import enums from 'enums';

const { DRIVER_STATUS } = enums;

const stateOptions = Object.entries(STATES).map(([state, key]) => ({
  text: capitalize(state.toLowerCase()),
  value: key,
}));

const trailerTypeOptions = Object.values(TRAILER_TYPE).map((type) => ({
  text: type,
  value: type,
}));

const gradeOptions = Object.values(EDUCATION_GRADE).map((type) => ({
  text: type,
  value: type,
}));

const onDutyHoursChange = (_: PropertyValue, driver: Driver) => {
  driver.totalHoursWorked = driver.onDutyHours.reduce(
    (acc, session) => acc + (session.hoursWorked || 0),
    0,
  );
  return driver;
};

const getStateByCode = (code: string) => {
  return Object.entries(STATES).find(
    ([stateName, stateCode]) => code === stateCode,
  )?.[0];
};

const addressSchema = {
  address: {
    type: 'text',
    label: 'Address',
  },
  city: {
    type: 'text',
    label: 'City',
  },
  state: {
    type: 'text',
    label: 'State',
  },
  zip: {
    type: 'text',
    label: 'Zip code',
  },
  howLong: {
    type: 'text',
    label: 'How long have you been living here?',
    required: false,
  },
} as Schema;

export const driverSchema = {
  firstName: {
    label: 'First name',
    type: 'text',
  },
  lastName: {
    label: 'Last name',
    type: 'text',
  },
  middleName: {
    label: 'Middle name',
    type: 'text',
  },
  birthDate: {
    type: 'date',
    label: 'Birth date',
  },
  status: {
    label: 'Status',
    type: 'preview',
  },
  applicationDate: {
    label: 'Application date',
    type: 'date',
  },
  positionAppliedFor: {
    type: 'text',
    label: 'Position applying for',
  },
  ssn: {
    label: 'Social security number',
    type: 'text',
    validate: (value: string) => !!value?.match(/^\d{9}$/),
    errorMessage: 'SSN must be exactly 9 digits',
  },
  fein: {
    label: 'FEIN',
    type: 'text',
    required: false,
  },
  contactInfoSeparator: {
    type: 'separator',
    label: 'Contact information',
  },
  email: {
    label: 'Email',
    type: 'text',
  },
  phone: {
    type: 'text',
    label: 'Phone number',
  },
  emergencyContacts: {
    label: 'Emergency contacts',
    type: 'array',
    shouldRenderAddDeleteButtons: false,
    children: {
      name: {
        label: 'Full name',
        type: 'text',
      },
      phoneNumber: {
        label: 'Phone number',
        type: 'text',
      },
      relationship: {
        label: 'Relationship',
        type: 'text',
      },
    },
  },
  currentAddress: {
    type: 'object',
    label: 'Address',
    children: addressSchema,
  },
  hadSameAddressFor3Years: {
    type: 'checkbox',
    label: 'Has same address for 3 years',
  },
  previousAddress: {
    type: 'array',
    label: 'Previous addresses',
    children: addressSchema,
    shouldRender: (_, driver: Driver) => {
      return !driver.hadSameAddressFor3Years;
    },
  },
  workSeparator: {
    type: 'separator',
    label: 'Eligibility To Work',
  },
  hasUsaLegalRight: {
    type: 'checkbox',
    label: 'Has legal right to work in USA',
  },
  hasWorkedForThisCompanyBefore: {
    type: 'checkbox',
    label: 'Has worked for this company before',
  },
  previousPosition: {
    type: 'object',
    label: 'Previous position details',
    shouldRender: (_, driver: Driver) => driver.hasWorkedForThisCompanyBefore,
    children: {
      where: {
        type: 'text',
        label: 'Where',
      },
      from: {
        type: 'date',
        label: 'From',
      },
      to: {
        type: 'date',
        label: 'To',
      },
      position: {
        type: 'text',
        label: 'Position',
      },
      reasonForLeaving: {
        type: 'text',
        label: 'Reason for leaving',
      },
    },
  },
  hasProofOfAge: {
    type: 'checkbox',
    label: 'Can provide proof of age',
  },
  isEmployed: {
    type: 'checkbox',
    label: 'Is employed now',
  },
  howLongSinceLastEmployment: {
    type: 'text',
    label: 'How long since last employment',
  },
  whoReferredYou: {
    type: 'text',
    label: 'Referrer',
  },
  hasBeenBonded: {
    type: 'checkbox',
    label: 'Has been bonded',
  },
  bondingCompanyName: {
    type: 'text',
    label: 'Name of bonding company',
    shouldRender: (_, driver: Driver) => driver.hasBeenBonded,
  },
  hasBeenConvictedOfFelony: {
    type: 'checkbox',
    label: 'Has ever been convicted of a felony',
  },
  explainConviction: {
    type: 'text',
    label: 'Explain conviction',
    shouldRender: (_, driver: Driver) => driver.hasBeenConvictedOfFelony,
  },
  isUnableToPerformJobFunctions: {
    type: 'checkbox',
    label: 'Might be unable to perform job functions',
  },
  explainIfYouWish: {
    type: 'text',
    label: 'Unable to work explanation',
    shouldRender: (_, driver: Driver) => driver.isUnableToPerformJobFunctions,
  },
  accidentSeparator: {
    type: 'separator',
    label: 'Accident Record',
  },
  hasAccidentRecord: {
    type: 'checkbox',
    label: 'Had accident records in last 3 years',
  },
  previousAccidents: {
    type: 'array',
    label: 'Previous accidents',
    shouldRender: (_, driver: Driver) => driver.hasAccidentRecord,
    children: {
      date: {
        type: 'date',
        label: 'Date',
      },
      natureOfAccident: {
        type: 'text',
        label: 'Nature of Accident',
      },
      city: {
        type: 'text',
        label: 'City',
      },
      state: {
        type: 'select',
        options: stateOptions,
        label: 'State',
      },
      hasFatalities: {
        type: 'checkbox',
        label: 'Had fatalities',
      },
      hasInjuries: {
        type: 'checkbox',
        label: 'Had injuries',
      },
      hasHazardousMaterial: {
        type: 'checkbox',
        label: 'Had hazardous material spill',
      },
    },
  },
  convictionsSeparator: {
    type: 'separator',
    label: 'Traffic Convictions',
  },
  hasTrafficConvictions: {
    type: 'checkbox',
    label: 'Has traffic convictions',
  },
  trafficConvictions: {
    type: 'array',
    label: 'Traffic Convictions',
    shouldRender: (_, driver: Driver) => driver.hasTrafficConvictions,
    children: {
      location: {
        type: 'text',
        label: 'Location',
      },
      date: {
        type: 'date',
        label: 'Date',
      },
      charge: {
        type: 'text',
        label: 'Charge',
      },
      hasPenalty: {
        type: 'checkbox',
        label: 'Has penalty',
      },
    },
  },
  expAndQualificationSeparator: {
    type: 'separator',
    label: 'Experience And Qualifications',
  },
  primaryDriverLicence: {
    type: 'object',
    label: 'Current License',
    children: {
      licenceNumber: {
        type: 'text',
        label: 'License number',
      },
      state: {
        type: 'select',
        options: stateOptions,
        label: 'State',
      },
      type: {
        type: 'text',
        label: 'License type',
      },
      expirationDate: {
        type: 'date',
        label: 'Expiration date',
      },
    },
  },
  hasOtherDriverLicenses: {
    type: 'checkbox',
    label: 'Has other driver licenses',
  },
  otherDriverLicences: {
    type: 'array',
    label: 'Other driver licenses',
    shouldRender: (_, driver: Driver) => driver.hasOtherDriverLicenses,
    children: {
      licenceNumber: {
        type: 'text',
        label: 'License number',
      },
      state: {
        type: 'select',
        options: stateOptions,
        label: 'State',
      },
      type: {
        type: 'text',
        label: 'License type',
      },
    },
  },
  hasBeenDeniedALicence: {
    type: 'checkbox',
    label: 'Has been denied a license',
  },
  licenceDeniedExplanation: {
    type: 'text',
    label: 'Denied license explanation',
    shouldRender: (_, driver: Driver) => driver.hasBeenDeniedALicence,
  },
  hasBeenSuspended: {
    type: 'checkbox',
    label: 'Has been suspended',
  },
  licenceSuspendedExplanation: {
    type: 'text',
    label: 'Suspension explanation',
    shouldRender: (_, driver: Driver) => driver.hasBeenSuspended,
  },

  drivingExperienceSeparator: {
    type: 'separator',
    label: 'Driving Experience',
  },
  straightTruck: {
    type: 'checkbox',
    label: 'Straight Truck',
  },
  straightTruckInfo: {
    type: 'object',
    label: 'Truck Info',
    shouldRender: (_, driver: Driver) => driver.straightTruck,
    children: {
      equipmentType: {
        type: 'select',
        label: 'Equipment Type',
        options: trailerTypeOptions,
      },
    },
  },
  semiTrailer: {
    type: 'checkbox',
    label: 'Tractor and Semi-Trailer',
  },
  semiTrailerInfo: {
    type: 'object',
    label: 'Truck Info',
    shouldRender: (_, driver: Driver) => driver.semiTrailer,
    children: {
      equipmentType: {
        type: 'select',
        label: 'Equipment Type',
        options: trailerTypeOptions,
      },
    },
  },
  twoTrailers: {
    type: 'checkbox',
    label: 'Tractor - Two Trailers',
  },
  twoTrailersInfo: {
    type: 'object',
    label: 'Truck Info',
    shouldRender: (_, driver: Driver) => driver.twoTrailers,
    children: {
      equipmentType: {
        type: 'select',
        label: 'Equipment Type',
        options: trailerTypeOptions,
      },
    },
  },
  threeTrailers: {
    type: 'checkbox',
    label: 'Tractor - Three Trailers',
  },
  threeTrailersInfo: {
    type: 'object',
    label: 'Truck Info',
    shouldRender: (_, driver: Driver) => driver.threeTrailers,
    children: {
      equipmentType: {
        type: 'select',
        label: 'Equipment Type',
        options: trailerTypeOptions,
      },
    },
  },
  schoolBus8: {
    type: 'checkbox',
    label: 'Motorcoach - School Bus (No more than 8 passangers)',
  },
  schoolBus15: {
    type: 'checkbox',
    label: 'Motorcoach - School Bus (More than 15 passangers)',
  },
  hasOperatedInAllStates: {
    type: 'checkbox',
    label: 'Has operated in all states in last 5 years',
  },
  statesOperatedIn: {
    type: 'select',
    label: 'States operated in last 5 years',
    shouldRender: (_, driver: Driver) => !driver.hasOperatedInAllStates,
    multi: true,
    options: stateOptions,
  },
  safeDrivingAwards: {
    type: 'text',
    label: 'Which safe driving awards do you hold and from whom?',
    required: false,
  },
  expAndQualificationOtherSeparator: {
    type: 'separator',
    label: 'Experience And Qualifications - Other',
  },
  experienceThatMayHelp: {
    type: 'text',
    label: 'Experience that may help in work for company',
  },
  otherCourses: {
    type: 'text',
    label: 'Passed courses and trainings',
  },
  specialEquipment: {
    type: 'text',
    label: 'Special equipment or technical materials worked with',
  },
  highestGrade: {
    type: 'select',
    label: 'Highest Grade Completed',
    options: gradeOptions,
  },
  education: {
    type: 'object',
    label: 'Education',
    children: {
      lastSchool: {
        type: 'text',
        label: 'Last School Attended',
      },
      location: {
        type: 'text',
        label: 'School location',
      },
    },
  },
  dutyHoursSeparator: {
    type: 'separator',
    label: 'Duty Hours',
  },
  'onDutyHours.0.hoursWorked': {
    type: 'number',
    label: 'Day 7',
    onChange: onDutyHoursChange,
  },
  'onDutyHours.1.hoursWorked': {
    type: 'number',
    label: 'Day 6',
    onChange: onDutyHoursChange,
  },
  'onDutyHours.2.hoursWorked': {
    type: 'number',
    label: 'Day 5',
    onChange: onDutyHoursChange,
  },
  'onDutyHours.3.hoursWorked': {
    type: 'number',
    label: 'Day 4',
    onChange: onDutyHoursChange,
  },

  'onDutyHours.4.hoursWorked': {
    type: 'number',
    label: 'Day 3',
    onChange: onDutyHoursChange,
  },

  'onDutyHours.5.hoursWorked': {
    type: 'number',
    label: 'Day 2',
    onChange: onDutyHoursChange,
  },
  'onDutyHours.6.hoursWorked': {
    type: 'number',
    label: 'Day 1',
    onChange: onDutyHoursChange,
  },
  totalHoursWorked: {
    type: 'number',
    label: 'Total hours worked',
    disabled: true,
  },
  carrierInfoSeparator: {
    type: 'separator',
    label: 'Carrier Information',
  },
  'companyInfo.ownersName': {
    type: 'preview',
    label: 'Carrier Official',
  },
  $v_carrierOfficialTitle: {
    type: 'virtual',
    label: 'Carrier Title',
    getter: () => 'President',
  },
  driversLicenceSeparator: {
    type: 'separator',
    label: "Driver's Licence Requirements",
  },
  $v_driverName: {
    type: 'virtual',
    label: 'Drivers name',
    getter: (driver: Driver) => `${driver.firstName} ${driver.lastName}`,
  },
  $v_driverLicence: {
    type: 'virtual',
    label: "Driver's License No.",
    getter: (driver: Driver) => driver.primaryDriverLicence?.licenceNumber,
  },
  $v_licenceExpiration: {
    type: 'virtual',
    label: 'Expiration Date',
    getter: (driver: Driver) =>
      new Date(
        driver.primaryDriverLicence?.expirationDate,
      ).toLocaleDateString(),
  },
  $v_licenceType: {
    type: 'virtual',
    label: 'License Type',
    getter: (driver: Driver) => driver.primaryDriverLicence?.type,
  },
  $v_licenceState: {
    type: 'virtual',
    label: 'State',
    getter: (driver: Driver) =>
      getStateByCode(driver.primaryDriverLicence?.state),
  },
  driversComment: {
    type: 'text',
    label: 'Drivers comment',
  },
  textExaminationSeparator: {
    type: 'separator',
    label: 'Driver Road Test Examination',
  },
  hasLessThan2YearsOfExperience: {
    type: 'checkbox',
    label: 'Has less then 2 years of experience',
  },
  preTripInspection: {
    type: 'checkbox',
    label: 'Pre-trip inspection',
    shouldRender: (_, driver: Driver) => driver.hasLessThan2YearsOfExperience,
  },
  combinationUnits: {
    type: 'checkbox',
    label: 'If the equipment includes combination units',
    shouldRender: (_, driver: Driver) => driver.hasLessThan2YearsOfExperience,
  },
  placingEquipment: {
    type: 'checkbox',
    label: 'Placing the equipment in operation',
    shouldRender: (_, driver: Driver) => driver.hasLessThan2YearsOfExperience,
  },
  emergencyEquipment: {
    type: 'checkbox',
    label: "Use of vehicle's controls and emergency equipment",
    shouldRender: (_, driver: Driver) => driver.hasLessThan2YearsOfExperience,
  },
  operatingVehicleInTraffic: {
    type: 'checkbox',
    label: 'Operating the vehicle in traffic and while passing other vehicles',
    shouldRender: (_, driver: Driver) => driver.hasLessThan2YearsOfExperience,
  },
  turningVehicle: {
    type: 'checkbox',
    label: 'Turning the vehicle',
    shouldRender: (_, driver: Driver) => driver.hasLessThan2YearsOfExperience,
  },
  breakingAndSlowing: {
    type: 'checkbox',
    label: 'Braking and slowing the vehicle by means other than braking',
    shouldRender: (_, driver: Driver) => driver.hasLessThan2YearsOfExperience,
  },
  breakingAndParking: {
    type: 'checkbox',
    label: 'Backing and parking the vehicle',
    shouldRender: (_, driver: Driver) => driver.hasLessThan2YearsOfExperience,
  },
  otherTest: {
    type: 'checkbox',
    label: 'Had other tests',
    shouldRender: (_, driver: Driver) => driver.hasLessThan2YearsOfExperience,
  },
  otherTestExplain: {
    type: 'text',
    label: 'Explain other tests',
    shouldRender: (_, driver: Driver) =>
      driver.otherTest && driver.hasLessThan2YearsOfExperience,
  },
  roadTestCertificationSeparator: {
    type: 'separator',
    label: 'Certification Of Road Test',
    shouldRender: (_, driver: Driver) => driver.hasLessThan2YearsOfExperience,
  },
  typeOfPowerUnit: {
    type: 'text',
    label: 'Type of power unit',
    shouldRender: (_, driver: Driver) => driver.hasLessThan2YearsOfExperience,
  },
  typeOfTrailer: {
    type: 'text',
    label: 'Type of trailer',
    shouldRender: (_, driver: Driver) => driver.hasLessThan2YearsOfExperience,
  },
  annualReviewSeparator: {
    type: 'separator',
    label: 'Annual Review',
  },
  hasViolationsInLast12Months: {
    type: 'checkbox',
    label: 'Made any violations during last 12 months',
  },
  violations: {
    type: 'array',
    label: 'Violations',
    shouldRender: (_, driver: Driver) => driver.hasViolationsInLast12Months,
    children: {
      date: {
        type: 'date',
        label: 'Date',
      },
      offense: {
        type: 'text',
        label: 'Offense',
      },
      location: {
        type: 'text',
        label: 'Location',
      },
      vehicleType: {
        type: 'text',
        label: 'Type of Vehicle Operated',
      },
    },
  },
  receiptSeparator: {
    type: 'separator',
    label: 'Receipt',
  },
  designatedPerson: {
    type: 'checkbox',
    label: 'The designated person to answer questions about the materials.',
  },
  subjectToPart382: {
    type: 'checkbox',
    label: 'The categories of drivers subject to Part 382',
  },
  sufficientSafetyInfo: {
    type: 'checkbox',
    label:
      'Sufficient information about the safety - sensitive functions and periods of the workday that compliance is required',
  },
  prohibitedDriverConduct: {
    type: 'checkbox',
    label: 'Specific information concerning prohibited driver conduct.',
  },
  driverTestingCircumstances: {
    type: 'checkbox',
    label: 'Circumstances under which a driver will be tested.',
  },
  testProcedures: {
    type: 'checkbox',
    label:
      'Test procedures, driver protection and integrity of the testing processes, and Safeguarding validity of the test',
  },
  administeredTests: {
    type: 'checkbox',
    label:
      'The requirement that tests are administered in accordance with Part 382',
  },
  testRefusal: {
    type: 'checkbox',
    label:
      'An explanation of what will be considered a refusal to submit to a test and theconsequences.',
  },
  subpartB: {
    type: 'checkbox',
    label:
      'The consequences for Part 382 Subpart B violations including removal fromsafety - sensitive function and 332,605 procedures.',
  },
  alcoholConcentration: {
    type: 'checkbox',
    label:
      'The consequences for drivers found to have an alcohol concentration of 0,02 or greater but less than 0,04.',
  },
  controlledSubstances: {
    type: 'checkbox',
    label:
      'Does alcohol and controlled substances affect "an individual\'s health-signs and symptoms of a problem", "work", "available methods of intervening when a problem is suspected", "personal life"',
  },
  companyInformation: {
    type: 'separator',
    label: 'Company Information',
  },
  $v_currentCompany: {
    type: 'virtual',
    label: 'Current company',
    getter: (driver: Driver) => driver.companyInfo?.name,
  },

  //   // Certificate of complience, all properties exist
  //   // Driver safety training
  //   // Driver responsibilites
  //   // Hours of service policy
  //   // Accidental isurance

  //   // Past Employment safety history
  //   companyName: {
  //     type: 'text',
  //   },
  //   // app signature, date signed, name of applicant, ssn
  //   employmentDateFrom: {
  //     type: 'date',
  //   },
  //   employmentDateTo: {
  //     type: 'date',
  //   },
  //   fullTime: {
  //     type: 'checkbox',
  //     default: true, // if false it means it is part time
  //   },
  //   positionHeld: {
  //     type: 'text',
  //   },
  //   local: {
  //     type: 'checkbox',
  //     default: false,
  //   },
  //   regional: {
  //     type: 'checkbox',
  //     default: false,
  //   },
  //   overTheRoad: {
  //     type: 'checkbox',
  //     default: false,
  //   },
  //   operatedVehiclesGt26k: {
  //     type: 'checkbox',
  //     default: false,
  //   },
  //   typeOfEquipmentOperated: {
  //     type: new Schema({
  //       dryVan: {
  //         type: 'checkbox',
  //         default: false,
  //       },
  //       flatbed: {
  //         type: 'checkbox',
  //         default: false,
  //       },
  //       reefer: {
  //         type: 'checkbox',
  //         default: false,
  //       },
  //       other: {
  //         type: 'checkbox',
  //         default: false,
  //       },
  //       otherDescription: {
  //         type: 'text',
  //         required() {
  //           return this.other;
  //         },
  //       },
  //     }),
  //   },
  //   reasonForLeaving: {
  //     type: 'text',
  //     enum: Object.values(REASON_FOR_LEAVING),
  //   },
  //   ifTerminatedWhy: {
  //     type: 'text',
  //     required() {
  //       return this.reasonForLeaving === REASON_FOR_LEAVING.TERMINATED;
  //     },
  //   },
  //   isEligibleForRehire: {
  //     type: 'text',
  //     enum: Object.values(ELIGIBLE_FOR_REHIRE),
  //   },
  //   companyPolicy: {
  //     type: 'text',
  //   },

  //   // Mothor vehicle accident
  //   motorVehicleAccidents: {
  //     type: [
  //       new Schema({
  //         accidentDate: {
  //           type: 'date',
  //         },
  //         city: {
  //           type: 'text',
  //         },
  //         tow: {
  //           type: 'checkbox',
  //           default: false,
  //         },
  //         injury: {
  //           type: 'checkbox',
  //           default: false,
  //         },
  //         fatality: {
  //           type: 'checkbox',
  //           default: false,
  //         },
  //         hmRelease: {
  //           type: 'checkbox',
  //           default: false,
  //         },
  //         briefDescription: {
  //           type: 'text',
  //         },
  //       }),
  //     ],
  //   },

  //   // Alcohol & Controlled Substance Testing Inquiry
  //   hadBreathAlcoholTest: {
  //     type: 'checkbox',
  //     default: false,
  //   },
  //   hadPositiveDrugTest: {
  //     type: 'checkbox',
  //     default: false,
  //   },
  //   hasRefusedControlledSubstanceTest: {
  //     type: 'checkbox',
  //     default: false,
  //   },
  //   hasViolatedDOT: {
  //     type: 'checkbox',
  //     default: false,
  //   },
  //   previousEmployerDrugAndAlcohol: {
  //     type: 'checkbox',
  //     default: false,
  //   },
  //   reasonForTest: {
  //     type: 'text',
  //     required() {
  //       return (
  //         this.hadBreathAlchocolTest ||
  //         this.hadPositiveDrugTest ||
  //         this.hasRefusedControlledSubstanceTest ||
  //         this.hasViolatedDOT ||
  //         this.previousEmployerDrugAndAlcohol
  //       );
  //     },
  //   },
  //   resultOfTest: {
  //     type: 'text',
  //   },
  //   dateOfTest: {
  //     type: 'date',
  //   },
  //   ifTestPositive: {
  //     type: 'checkbox',
  //     default: false,
  //   },
  //   anyOtherRemarks: {
  //     type: 'text',
  //   },
  //   verificationCompletedBy: {
  //     type: 'text',
  //   },

  // typeOfEquipment: {
  //   type: 'text',
  // },
  // remarks: {
  //   type: 'text',
  // },
} as Schema;

export const getDriverSchemaWithCompanies = (companies: Company[]) => {
  const companyOptions = Object.values(companies).map((type) => ({
    text: type.name,
    value: type.id,
  }));

  return {
    ...driverSchema,
    newCompanyId: {
      type: 'select',
      options: companyOptions,
      label: 'Company where driver will be replicated',
      shouldRender: (_, driver: Driver) =>
        Object.keys(DRIVER_STATUS).some((key) => driver?.status === key),
    },
  } as Schema;
};
