import React, { useMemo } from 'react';
import { NavLink, NavLinkProps, useLocation } from 'react-router-dom';
import utils from 'utils';
import './LinkWithQParams.styles.scss';

type LinkWithQParamsProps = {
  className?: string;
  params: Record<string, string>;
} & Pick<NavLinkProps, 'activeClassName'>;

const LinkWithQParams: React.FC<LinkWithQParamsProps> = (props) => {
  const { children, params, ...rest } = props;

  const { search, pathname } = useLocation();
  const urlPathParams = pathname.split('/');

  const editedPathName = '/' + urlPathParams[1];

  const urlSearchParams = useMemo(() => new URLSearchParams(search), [search]);

  const finalSearch = useMemo(() => {
    utils.updateURLSearchParamsWithObject(urlSearchParams, params);
    return urlSearchParams.toString();
  }, [params, urlSearchParams]);

  const isEditPage = urlPathParams.length > 2;

  const finalPath = isEditPage ? editedPathName : pathname;

  return (
    <NavLink to={`${finalPath}?${finalSearch}`} {...rest}>
      {children}
    </NavLink>
  );
};

export default LinkWithQParams;
