export const emailValidation = (value: string) => {
  if (!value) {
    return 'Required';
  }

  const emailRegex = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>[\].,;:\s@"]+\.)+[^<>()[\].,;:\s!@#$%^&*()_+=|"'{}/?]{2,})$/i;
  const isFound = !!value.match(emailRegex);

  if (!isFound) {
    return 'Please insert valid email.';
  }
};

export const usernameValidation = (value: string) => {
  if (!value) {
    return 'Required';
  }

  const usernameRegex = /^[a-zA-Z0-9]+([._]?[a-zA-Z0-9]+)*$/i;
  const isFound = !!value.match(usernameRegex);

  if (!isFound) {
    return 'Please insert valid username.';
  }
};

export const nameValidation = (value: string) => {
  if (!value) {
    return 'Required';
  }

  const nameRegex = /^[a-zA-Z ]+$/i;
  const isFound = !!value.match(nameRegex);

  if (!isFound || !value.trim().length) {
    return 'Please insert valid name.';
  }
};

type Agent = {
  email: string;
  firstName: string;
  lastName: string;
  username: string;
};

export const trimEnds = (user: Agent): Agent => {
  return {
    ...user,
    firstName: user.firstName.trim(),
    lastName: user.lastName.trim(),
  };
};
