import { AxiosResponse } from 'axios';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ApiResponse } from 'types/ApiResponse';
import { Agent } from 'types/Users';

type UseFetchWithLimitProps = {
  limit?: number;
  shouldExecuteCall: boolean;
  additionalParams?: Record<string, any>;
  request: (
    params: Record<string, boolean | number | string>,
  ) => Promise<AxiosResponse<ApiResponse<Agent>>>;
};
export function useFetchWithLimit(props: UseFetchWithLimitProps) {
  const { limit = 200, request, shouldExecuteCall, additionalParams } = props;

  const [items, setItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const params = useMemo(() => additionalParams, [additionalParams]);

  const getItems = useCallback(async () => {
    try {
      const {
        data: { items: fetchedItems, totalPages },
      } = await request({
        $limit: limit,
        $page: currentPage,
        ...params,
      });
      setItems((oldValues) => [...oldValues, ...fetchedItems]);

      if (totalPages > currentPage) setCurrentPage((oldValue) => oldValue + 1);
    } catch (err) {
      console.error(err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, limit, request]);

  useEffect(() => {
    shouldExecuteCall && getItems();
  }, [getItems, shouldExecuteCall]);

  return {
    items,
    setItems,
  };
}
