type FileFromDB = {
  name: string;
  id: string;
  fileUrl: string;
  fileType: string;
};

export const TABLE_DOCUMENTS = {
  LEFT: 'left',
  RIGHT: 'right',
};

export const DOCUMENT_TYPES = [
  'Truck registration',
  'Annual DOT Inspection for truck',
  'Trailer registration',
  'Annual DOT Inspection for trailer',
  'NY permit paper',
  'NM permit paper',
  'KY permit paper',
  'Lease agreement between company and driver',
  'Lease agreement for registration',
  'Bill of sale between company and driver',
  'MVR',
  'Clearing house document',
  'Drug test results',
  'Copy of CDL',
  'Medical card',
  'Copy of SSN (Social Security Number)',
];

export const prepareTableItems = (
  files: FileFromDB[],
  setItems: (
    value: React.SetStateAction<
      (
        | {
            id: string;
            name: string;
            fileType: string;
            disable: boolean;
            fileUrl: string;
          }
        | {
            fileType: string;
            disable: boolean;
            fileUrl: string;
            id?: undefined;
            name?: undefined;
          }
      )[]
    >,
  ) => void,
) => {
  setItems((oldFiles) =>
    oldFiles.map((oldFile) => {
      const currentFile = files.find(
        ({ fileType }) => fileType === oldFile.fileType,
      );
      return currentFile
        ? {
            ...oldFile,
            disable: false,
            fileUrl: currentFile.fileUrl,
            id: currentFile.id,
            name: currentFile.name,
          }
        : {
            ...oldFile,
            fileUrl: '',
            disable: true,
          };
    }),
  );
};

export const COMPANY_DRIVER_FILES = [
  'Truck registration',
  'Annual DOT Inspection for truck',
  'Trailer registration',
  'Annual DOT Inspection for trailer',
  'NY permit paper',
  'NM permit paper',
  'KY permit paper',
  'Lease agreement between company and driver',
  'Lease agreement for registration',
  'MVR',
  'Clearing house document',
  'Drug test results',
  'Copy of CDL',
  'Medical card',
  'Copy of SSN (Social Security Number)',
];

export const RECOVERY_DRIVER_FILES = [
  'Lease agreement between company and driver',
  'MVR',
  'Clearing house document',
  'Drug test results',
  'Copy of CDL',
  'Medical card',
  'Copy of SSN (Social Security Number)',
];

export const filesBasedOnDriverRoleFunction = (driverRole: string | number) => {
  if (driverRole !== '' && driverRole !== 'ownerOperator')
    return driverRole === 'companyDriver'
      ? COMPANY_DRIVER_FILES
      : RECOVERY_DRIVER_FILES;
  else return DOCUMENT_TYPES;
};

export const checkAllUpoladedFiles = (
  driverFiles: string[],
  allFiles: FileFromDB[],
) => {
  return driverFiles.every((docType: string) => {
    const file = allFiles.find(({ fileType }) => fileType === docType);
    return !!file?.fileUrl;
  });
};
