import { AuthBody } from '../../models/AuthBody';
import httpClient from '../httpClient';

const login = (username: string, password: string) =>
  httpClient.post<AuthBody>('/auth/login', { username, password });

function updatePassword(params: any) {
  return httpClient.patch('/auth/password-reset', params);
}

function sendForgotPasswordEmail(email: string) {
  return httpClient.post('auth/forgot-password', { email });
}

function resetPassword(password: string, token: string) {
  return httpClient.patch('auth/forgot-password/reset', { password, token });
}

const sendVerificationMail = (email: string) =>
  httpClient.post('auth/verify-email/send', { email });

const verifyEmail = (token: string) =>
  httpClient.post('auth/verify-email', { token });

export default {
  login,
  updatePassword,
  sendForgotPasswordEmail,
  resetPassword,
  sendVerificationMail,
  verifyEmail,
};
