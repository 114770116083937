import React, { useCallback, useState } from 'react';
import {
  Button,
  Dimmer,
  Header,
  Icon,
  Loader,
  Segment,
} from 'semantic-ui-react';
import { Form, Field } from 'react-final-form';
import { FormInput } from '../../components/FinalFormComponents';

import validateService from 'services/validateService';
import { toast } from 'react-toastify';
import history from 'common/history';
import { apiAuth } from 'api';

const ForgotPassword: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);

  const onFormSubmit = useCallback(async ({ email }: { email: string }) => {
    try {
      setLoading(true);
      await apiAuth.sendForgotPasswordEmail(email);
      toast.success(
        'If the agent with a sent email exists, the password reset link will be sent.',
      );
    } catch (err) {
      console.error(err);
    } finally {
      setTimeout(() => {
        setLoading(false);
        history.push('/login');
      }, 2000);
    }
  }, []);

  return (
    <div className="super-ego-holding-container">
      <Segment padded="very" raised className="section-container">
        <Header as="h1" textAlign="center">
          Forgot Password?
        </Header>
        {loading && (
          <Dimmer inverted active>
            <Loader>
              The password reset link will be sent. Redirecting to login page...
            </Loader>
          </Dimmer>
        )}
        <Form
          onSubmit={onFormSubmit}
          render={({ handleSubmit, hasValidationErrors, submitSucceeded }) => (
            <form onSubmit={handleSubmit} className="form-container">
              <Field
                name="email"
                label="Email"
                placeholder="johndoe@example.com"
                tooltip="Enter the email address associated with your account, and we’ll send you a link to reset your password."
                tippyIcon={
                  <Icon className="field-info-icon " name="question circle" />
                }
                validate={validateService.mailValidation}
                required
                component={FormInput}
                autoFocus
              />
              <Button
                className="reset-button"
                color="blue"
                disabled={hasValidationErrors || submitSucceeded}
                fluid
                size="large"
              >
                Reset password
              </Button>
            </form>
          )}
        />
      </Segment>
    </div>
  );
};

export default ForgotPassword;
