import { toast } from 'react-toastify';
import { useCallback, useEffect } from 'react';

const useToastConfigure = (
  position = 'bottom-right',
  duration = 5000,
  hideProgressBar = true,
) => {
  const positioning = useCallback(() => {
    switch (position) {
      case 'bottom-right':
        return toast.POSITION.BOTTOM_RIGHT;
      case 'bottom-left':
        return toast.POSITION.BOTTOM_LEFT;
      case 'top-right':
        return toast.POSITION.TOP_RIGHT;
      case 'top-left':
        return toast.POSITION.TOP_LEFT;
      default:
        break;
    }
  }, [position]);
  useEffect(() => {
    toast.configure({
      position: positioning(),
      autoClose: duration,
      hideProgressBar: hideProgressBar,
    });
  }, [hideProgressBar, duration, positioning]);
};
export default useToastConfigure;
