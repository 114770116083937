import React, { useCallback, useState } from 'react';
import { Modal, Header, Button } from 'semantic-ui-react';
import { Form, Field } from 'react-final-form';
import { FormTextarea } from '../FinalFormComponents';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { AxiosResponse } from 'axios';
import ConfirmationModal from 'components/ConfirmationModal';
import validateService from 'services/validateService';

import './RequestEditModal.styles.scss';

const ERROR_MESSAGE_CHANGES_REQUEST =
  'Error occured, changes were not requested';
const TEXTAREA_LABEL = 'Describe changes you are requesting';
const CHANGE_STATUS_MESSAGE =
  "By confirming, driver will go back to applicant with status 'Changes Requested'";

type RequestEditModalProps = {
  trigger: JSX.Element;
  header?: string;
  applicantsId: string;
  shouldOpenConfirmationModal?: boolean;
  apiRequest?: (
    id: string,
    changesRequested: string,
  ) => Promise<AxiosResponse<any>>;
};

type RequestEdit = { changesRequested: string };

const RequestEditModal: React.FC<RequestEditModalProps> = ({
  trigger,
  header,
  applicantsId,
  shouldOpenConfirmationModal = false,
  apiRequest,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const onFormSubmit = useCallback(
    async (values: RequestEdit) => {
      const { changesRequested } = values;
      try {
        await apiRequest(applicantsId, changesRequested);
        setOpen(false);
      } catch (err) {
        setLoading(false);
        toast.error(ERROR_MESSAGE_CHANGES_REQUEST);
        console.error(err);
      }
    },
    [applicantsId, apiRequest],
  );

  const formClassNames = classNames('ui', 'form', { loading: loading });

  return (
    <Modal
      closeIcon
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={trigger}
    >
      <Header>{header}</Header>
      <Modal.Content>
        <Form
          onSubmit={onFormSubmit}
          render={({
            handleSubmit,
            submitting,
            hasValidationErrors,
            values,
          }) => (
            <form onSubmit={handleSubmit} className={formClassNames}>
              <Field
                component={FormTextarea}
                label={TEXTAREA_LABEL}
                name="changesRequested"
                validate={validateService.required}
              />
              <div className="form-actions">
                {shouldOpenConfirmationModal ? (
                  <ConfirmationModal
                    className="centered-modal"
                    message={CHANGE_STATUS_MESSAGE}
                    action={() => onFormSubmit(values)}
                    trigger={
                      <Button
                        type="button"
                        primary
                        content="Request changes"
                        disabled={submitting || hasValidationErrors}
                      />
                    }
                  />
                ) : (
                  <Button
                    disabled={submitting || hasValidationErrors}
                    content="Request changes"
                    primary
                  />
                )}
              </div>
            </form>
          )}
        />
      </Modal.Content>
    </Modal>
  );
};
export default RequestEditModal;
