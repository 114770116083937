import React, { useCallback } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { Label } from '..';
import RadioGroup from 'components/RadioGroup';
import { RadioGroupProps } from 'components/RadioGroup/RadioGroup.component';

type FormRadioGroupProps = {
  label?: string;
  required?: boolean;
  className?: string;
} & FieldRenderProps<string, HTMLElement> &
  RadioGroupProps;

const FormRadioGroup: React.FC<FormRadioGroupProps> = ({
  input,
  meta,
  label,
  required,
  className,
  ...rest
}) => {
  const error = meta.touched ? meta.error : null;

  const handleOnChange = useCallback(
    (e: React.FormEvent<HTMLInputElement>, value: string | number) => {
      input.onChange(value);
    },
    [input],
  );

  return (
    <>
      {label && (
        <Label
          className="checkbox__info"
          label={label}
          error={error}
          required={required}
        />
      )}
      <div className="checkbox__options-container">
        <RadioGroup
          className={className}
          handleOnChange={handleOnChange}
          {...rest}
        />
      </div>
    </>
  );
};

export default FormRadioGroup;
