import useFiles, { FileFromDB } from 'hooks/useFiles';
import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Tippy from 'components/Tippy';
import { Button, Label, Table, Loader } from 'semantic-ui-react';
import './SafetyDocuments.styles.scss';
import { Driver, DrivingHistory } from 'types/Users';
import { Form as FinalForm, Field } from 'react-final-form';
import { FormInput } from 'components/FinalFormComponents';
import validateService from 'services/validateService';
import { DOCUMENT_TYPES } from './utils/index';
import TableDocumentsRow from '../../components/TableDocumentsRow/TableDocumentsRow.component';
import TableDocumentsHeader from 'components/TableDocumentsHeader';
import SafetyDocumentsHistoryModal from 'components/SafetyDocumentsHistoryModal/SafetyDocumentsHistoryModal.component';
import DocuSignModal from 'components/DocuSignModal/DocuSignModal.component';
import bus, { EventName } from 'modules/bus';
import { BUS_EVENTS } from 'modules/bus/busEvents';
import ApplicantHeader from 'components/ApplicantHeader';
import { apiDrivers } from 'api';
import { formatedDate } from 'services/dateService';
import ConfirmationModal from 'components/ConfirmationModal';

const SafetyDocuments: React.FC = () => {
  const [, setLoading] = useState<boolean>(true);
  const [driver, setDriver] = useState<Driver>();
  const [isDisabledInputField, setIsDisabledInputField] = useState<boolean>(
    true,
  );
  const [fuelCardNumberHistory, setFuelCardNumberHistory] = useState([]);

  const [fuelCardNumber, setFuelCardNumber] = useState<string>();

  const [signedFilesHistory, setSignedFilesHistory] = useState([]);
  const [
    permanentDeletedFile,
    setPermanentDeletedFile,
  ] = useState<FileFromDB>();

  const [isDeletedDriver, setIsDeletedDriver] = useState<boolean>(false);
  const [isDriving, setIsDriving] = useState<boolean>();

  const [drivingHistory, setDrivingHistory] = useState<DrivingHistory[]>([]);
  const [
    openSafetyDocumentModal,
    setOpenSafetyDocumentModal,
  ] = useState<boolean>(false);

  const { id: driverID } = useParams<{ id: string }>();

  const { files, setFiles } = useFiles(false, driverID, setLoading);
  const { files: deletedFiles, setFiles: setDeletedFiles } = useFiles(
    true,
    driverID,
    setLoading,
  );

  const mergedFiles = useMemo(() => {
    if (permanentDeletedFile) {
      const permaDeletedId = permanentDeletedFile._id;

      return [
        ...files.filter(({ id }) => permaDeletedId !== id),
        ...deletedFiles.filter(({ id }) => permaDeletedId !== id),
      ];
    }

    return [...files, ...deletedFiles];
  }, [deletedFiles, files, permanentDeletedFile]);

  useEffect(() => {
    if (permanentDeletedFile) {
      setFiles((oldFiles) =>
        oldFiles.filter(({ id }) => permanentDeletedFile._id !== id),
      );

      setDeletedFiles((oldDeletedFiles) =>
        oldDeletedFiles.filter(({ id }) => permanentDeletedFile._id !== id),
      );
      setPermanentDeletedFile(null);
    }
  }, [permanentDeletedFile, setDeletedFiles, setFiles]);

  const mappedDocumentTypes = useMemo(() => {
    return DOCUMENT_TYPES.map((docType: string) => ({
      file: mergedFiles.find(({ fileType }) => fileType === docType),
      docType,
    })).map((doc) => {
      const { file, docType } = doc;
      if (file) {
        const { id, fileType, fileUrl, isDeleted, groupId } = file;
        return {
          fileId: id,
          fileType,
          disable: isDeleted,
          fileUrl,
          isDeleted,
          groupId,
        };
      } else {
        return {
          fileId: undefined,
          fileType: docType,
          disable: true,
          fileUrl: '',
          isDeleted: false,
          groupId: '',
        };
      }
    });
  }, [mergedFiles]);

  const isSignDisabled = useMemo(() => {
    return DOCUMENT_TYPES.every((docType: string) => {
      const file = files.find(({ fileType }) => fileType === docType);
      return !!file?.fileUrl;
    });
  }, [files]);

  const handleSoftDeleteDocument = useCallback(
    async (fileId: string, fileType: string) => {
      try {
        const { data } = await apiDrivers.softDeleteFile(fileId, driverID);
        setFiles((oldFiles) => {
          return oldFiles.filter((file) => file.id !== fileId);
        });

        const deletedFile = {
          id: data.file._id,
          name: data.file.name,
          fileUrl: data.file.fileUrl,
          fileType: data.file.fileType,
          isDeleted: true,
          groupId: data.file.groupId,
        };

        setDeletedFiles((oldDeletedFiles) => [...oldDeletedFiles, deletedFile]);
        toast.success(`Successfully deleted "${fileType}".`);
      } catch (err) {
        console.error(err);
      }
    },
    [driverID, setDeletedFiles, setFiles],
  );

  const handleUpdateFuelCardNumber = async (values: any) => {
    try {
      const updatedDriver = {
        ...driver,
        fuelCardNumber: values.fuelNumber,
        shouldCreateNewPdf: false,
      };

      setLoading(true);
      const { data } = await apiDrivers.updateDriver(driverID, updatedDriver);

      setFuelCardNumber(values.fuelNumber);
      const fuelCardNumberArray = data.driver.fuelCardNumber as any;
      fuelCardNumberArray.reverse();
      setFuelCardNumberHistory((oldVal) => [fuelCardNumberArray[0], ...oldVal]);

      setIsDisabledInputField((oldVal) => !oldVal);
      toast.success('Fuel card number is successfully uploaded.');
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleReplaceDocument = async (values: any) => {
    try {
      const { files: documents, fileType, fileId } = values;
      const { data } = await apiDrivers.replaceFile(
        fileId,
        driverID,
        documents,
        fileType,
      );

      const updatedFile = {
        id: data.file._id,
        name: data.file.name,
        fileUrl: data.file.fileUrl,
        fileType: data.file.fileType,
        isDeleted: data.file.isDeleted,
        groupId: data.file.groupId,
      };

      const filteredFiles = files.filter((file) => file.id !== fileId);

      const replacedFile = files.find((file) => file.id === fileId);

      setFiles([...filteredFiles, updatedFile]);
      setDeletedFiles((oldDeletedFiles) => [
        ...oldDeletedFiles,
        { ...replacedFile, isDeleted: true },
      ]);
      toast.success(`${fileType} is successfully replaced.`);
    } catch (err) {
      console.error(err);
    }
  };

  const handleDocumentUpload = (values: any) => {
    const { files: documents, fileType } = values;
    return apiDrivers
      .uploadFile(driverID, documents, fileType)
      .then(({ data }) => {
        const firstFile = data.files[0];
        setFiles((oldValue) => [
          ...oldValue,
          {
            id: firstFile._id,
            name: firstFile.name,
            fileUrl: firstFile.fileUrl,
            fileType: firstFile.fileType,
            isDeleted: firstFile.isDeleted,
            groupId: firstFile.groupId,
          },
        ]);
        toast.success(`${fileType} is successfully uploaded.`);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleDisableForDriving = useCallback(async () => {
    try {
      setLoading(true);
      await apiDrivers.disableForDriving(driverID);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, [driverID]);

  const handleCheckedDriver = useCallback(async () => {
    try {
      const {
        data: { status: newStatus, statusHistory: newStatusHistory },
      } = await apiDrivers.changeStatusToHiredChecked(driverID);
      setDriver((old) => ({
        ...old,
        status: newStatus,
        statusHistory: newStatusHistory,
      }));
    } catch (e) {
      console.error(e);
    }
  }, [driverID]);

  useEffect(() => {
    const getDriver = async () => {
      const {
        data: { driver },
      } = await apiDrivers.getDriver(driverID);
      setDriver(driver);
      setIsDeletedDriver(driver.isDeleted);

      const fuelCardNumberArray: any = driver.fuelCardNumber;

      fuelCardNumberArray.reverse();

      setFuelCardNumberHistory((oldVal) => [...fuelCardNumberArray, ...oldVal]);

      setFuelCardNumber(fuelCardNumberArray[0]?.value);

      const signedFilesArray: Object[] = driver.signedFiles;
      signedFilesArray.reverse();

      setSignedFilesHistory(signedFilesArray);
      setIsDriving(driver?.isDriving);

      setDrivingHistory(driver?.drivingDisabledHistory.reverse());
    };

    getDriver();
  }, [driverID]);

  useEffect(() => {
    const driverIsDrivingChangedListener = (args: any) => {
      const {
        payload: {
          dateSigned,
          url,
          agent,
          isDriving: isDrivingPayload,
          driverId: driverIdSocket,
        },
      } = args;

      const signedFile = { agent, dateSigned, url };
      const disabledForDriving = { agent, dateSigned };
      if (url !== '' && isDrivingPayload && driverID === driverIdSocket) {
        setSignedFilesHistory((oldSignedFiles) => [
          signedFile,
          ...oldSignedFiles,
        ]);
        setIsDriving(true);
      }
      if (url === '' && !isDrivingPayload && driverID === driverIdSocket) {
        setDrivingHistory((oldVal) => [disabledForDriving, ...oldVal]);
        setIsDriving(false);
      }
    };
    bus.addEventListener(
      BUS_EVENTS.DRIVER_DRIVING_CHANGED as EventName,
      driverIsDrivingChangedListener,
    );
    return () => {
      bus.removeEventListener(
        BUS_EVENTS.DRIVER_DRIVING_CHANGED as EventName,
        driverIsDrivingChangedListener,
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!driver) {
    return <Loader active>Loading...</Loader>;
  }
  return (
    <>
      <div className="safety-page">
        <h2 className="safety-page__header">Safety Documents</h2>

        <ApplicantHeader
          applicantName={driver.applicantName}
          status={driver.status}
          className="safety-page__driver-header"
          id={driverID}
        />

        {driver.signedApplicationUrl && (
          <a
            className="safety-page__signed-app-url"
            target="_blank"
            rel="noopener noreferrer"
            href={driver.signedApplicationUrl}
          >
            {'Signed contract url'}
          </a>
        )}

        <div className="safety-page__table">
          <Table celled>
            <TableDocumentsHeader />
            <Table.Body>
              {mappedDocumentTypes
                .slice(0, 8)
                .map(
                  ({
                    fileId,
                    fileType,
                    disable,
                    fileUrl,
                    isDeleted,
                    groupId,
                  }) => {
                    return (
                      <TableDocumentsRow
                        key={fileType}
                        isDeleted={isDeleted}
                        groupId={groupId}
                        fileId={fileId}
                        fileType={fileType}
                        disable={disable}
                        fileUrl={fileUrl}
                        driverId={driverID}
                        driver={driver}
                        isDeletedDriver={isDeletedDriver}
                        setPermanentDeletedFile={setPermanentDeletedFile}
                        handleSoftDeleteDocument={handleSoftDeleteDocument}
                        handleReplaceDocument={handleReplaceDocument}
                        handleDocumentUpload={handleDocumentUpload}
                      />
                    );
                  },
                )}
            </Table.Body>
          </Table>
          <Table celled>
            <TableDocumentsHeader />
            <Table.Body>
              {mappedDocumentTypes
                .slice(8, 16)
                .map(
                  ({
                    fileId,
                    fileType,
                    disable,
                    fileUrl,
                    isDeleted,
                    groupId,
                  }) => {
                    return (
                      <TableDocumentsRow
                        key={fileType}
                        isDeleted={isDeleted}
                        groupId={groupId}
                        fileId={fileId}
                        fileType={fileType}
                        disable={disable}
                        fileUrl={fileUrl}
                        driverId={driverID}
                        isDeletedDriver={isDeletedDriver}
                        setPermanentDeletedFile={setPermanentDeletedFile}
                        handleSoftDeleteDocument={handleSoftDeleteDocument}
                        handleReplaceDocument={handleReplaceDocument}
                        handleDocumentUpload={handleDocumentUpload}
                      />
                    );
                  },
                )}
            </Table.Body>
          </Table>
        </div>

        <div className="safety-page__fuel-number">
          <FinalForm
            onSubmit={handleUpdateFuelCardNumber}
            render={({
              submitting,
              handleSubmit,
              hasValidationErrors,
              pristine,
            }) => (
              <form
                className="safety-page__fuel-number__left"
                onSubmit={handleSubmit}
              >
                <Label color="blue" size="medium" pointing="right">
                  Fuel card number
                </Label>
                <div>
                  <Field
                    disabled={isDisabledInputField}
                    initialValue={fuelCardNumber}
                    name="fuelNumber"
                    component={FormInput}
                    validate={validateService.composeValidators(
                      validateService.required,
                      validateService.spacesValidation,
                    )}
                  />
                </div>

                <Button
                  size="small"
                  type="button"
                  disabled={isDeletedDriver}
                  onClick={() =>
                    setIsDisabledInputField((oldValue) => !oldValue)
                  }
                  icon={isDisabledInputField ? 'pencil alternate' : 'close'}
                  color={isDisabledInputField ? 'black' : 'black'}
                />
                <Button
                  size="small"
                  color="blue"
                  type="submit"
                  icon="checkmark"
                  disabled={
                    isDisabledInputField ||
                    submitting ||
                    hasValidationErrors ||
                    pristine
                  }
                />
                <Tippy title="Fuel Card Number History">
                  <Button
                    disabled={!fuelCardNumberHistory.length}
                    size="small"
                    color="twitter"
                    icon="archive"
                    type="button"
                    onClick={(e) => {
                      e.stopPropagation();
                      setOpenSafetyDocumentModal(true);
                    }}
                  />
                </Tippy>
              </form>
            )}
          />
          <div className="safety-page__fuel-number__right">
            <ConfirmationModal
              message={`Are you sure you want to disable "${driver.applicantName}" for driving?`}
              action={handleDisableForDriving}
              header="Driving status"
              isDeleteModal
              color="red"
              buttonContent="Disable"
              buttonIcon="dont"
              trigger={
                <Button
                  content="Disable for driving"
                  color="red"
                  disabled={!isDriving}
                />
              }
            />
            <ConfirmationModal
              message={`As an agent, I confirm that all documents of driver ${driver.applicantName} have been checked`}
              action={handleCheckedDriver}
              header="Driving status"
              messageHeader="Change driver status to Hired/Checked"
              buttonContent="Confirm"
              trigger={
                <Button
                  content="Checked"
                  color="blue"
                  disabled={driver.statusHistory.some(
                    ({ status }) => status === 'Hired/Checked',
                  )}
                />
              }
            />
            <DocuSignModal
              driverId={driverID}
              driverName={driver.applicantName}
              status={driver.status}
              trigger={
                <Button
                  size="small"
                  disabled={
                    isDeletedDriver || !isSignDisabled || !fuelCardNumber
                  }
                  color="blue"
                >
                  Sign
                </Button>
              }
            />
          </div>
        </div>

        <div className="safety-page__signing-history">
          <h2 className="safety-page__signing-history__header">
            Signing History
          </h2>
          {!signedFilesHistory.length ? (
            <p>No documents signed for this driver.</p>
          ) : (
            <Table celled className="safety-page__signing-history__table">
              <TableDocumentsHeader
                headerCells={['Agent', 'Date of Signing', 'Link']}
              />
              <Table.Body>
                {signedFilesHistory.map(({ agent, dateSigned, url }, index) => (
                  <Table.Row key={index}>
                    <Table.Cell>{agent?.fullName ?? 'agent'}</Table.Cell>
                    <Table.Cell>{formatedDate(dateSigned)}</Table.Cell>
                    <Table.Cell>
                      <a
                        className="preview"
                        href={url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Preview
                      </a>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          )}
        </div>
        <div className="safety-page__signing-history">
          <h2>Disabled for driving - History</h2>
          {!drivingHistory?.length ? (
            <p>No items to show.</p>
          ) : (
            <Table celled>
              <TableDocumentsHeader headerCells={['Agent', 'Date']} />
              <Table.Body>
                {drivingHistory?.map(({ agent, date }) => (
                  <Table.Row key={date?.toString()}>
                    <Table.Cell>
                      {`${agent?.firstName} ${agent?.lastName}` ?? 'agent'}
                    </Table.Cell>
                    <Table.Cell>{formatedDate(date)}</Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          )}
        </div>
      </div>
      {openSafetyDocumentModal && (
        <SafetyDocumentsHistoryModal
          open={openSafetyDocumentModal}
          setOpen={() => setOpenSafetyDocumentModal(false)}
          driverId={driverID}
          fuelCardNumberHistory={fuelCardNumberHistory}
        />
      )}
    </>
  );
};

export default SafetyDocuments;
